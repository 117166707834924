import { css, Theme } from '@emotion/react';

const eventStyles = css`
  grid-column: 1/-1;
  display: grid;
  grid-template-rows: 1fr auto;
  height: calc(100vh - 3rem);
`;

const headerBodyContent = css`
  display: grid;
  margin: 0;
  grid-column: 1/-1;
  grid-template-areas: 'header' 'eventBody';
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
`;

const headerContainerStyles = css`
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  grid-template-areas: 'close-btn' 'eventTitleHeader';
`;

const btnClose = css`
  grid-area: close-btn;
  display-grid;
  justify-self: end;
  border: none;
  cursor: pointer;
  background: transparent;
  outline: none;
  height: 2.0625rem;
`;

const containerStyles = css`
  display: grid;
  height: 100%;
  grid-template-rows: 3rem auto;
`;

const notFoundStyles = css`
  text-align: center;
`;

const notFoundHeaderStyles = css`
  font-size: 4rem;
`;

const closeButtonContainerStyles = css`
  grid-column: 1/-1;
  grid-row: 1 / span 1;
  align-self: end;
  justify-self: end;
`;

export {
  eventStyles,
  btnClose,
  containerStyles,
  headerBodyContent,
  notFoundStyles,
  notFoundHeaderStyles,
  headerContainerStyles,
  closeButtonContainerStyles,
};
