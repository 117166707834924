import { SerializedStyles } from '@emotion/react';

// eslint-disable-next-line import/prefer-default-export
export enum EventTypeSeverity {
  NONE = 1,
  WARNING,
  ALARM,
}

export interface BlockingEventsSeverityLevelProps {
  children: (props: { severityLevel: number | undefined; color: string }) => any;
  styles?: SerializedStyles;
}
