import { toGlobalId } from 'graphql-relay';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'relay-hooks';
import device from '../../../../../assets/images/dda.png';
import { InfoItem, NoDataAvailableMessage } from '../../../../../components';
import {
  imageContainerStyles,
  infoContainserStyles,
  mainContentContainerStyles,
  sectionStyles,
  installationNoteStyles,
} from './styles';
import { InstallationInfoQuery } from './__generated__/InstallationInfoQuery.graphql';
import { LocaleContext } from '../../../../../providers/LocaleProvider/LocaleProvider';
import { formatDate } from './utils';
import { paragraphBoldStyles, textLinkStyles } from '../../../../../styles';

const InfoSection = ({ children, title }: { children: JSX.Element[] | JSX.Element; title: string }) => (
  <div>
    <p css={[paragraphBoldStyles, sectionStyles]}>{title}</p>
    {children}
  </div>
);

const InstallationInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { locale, shortDateTimeFormat } = useContext(LocaleContext);
  const { t } = useTranslation();

  const data = useLazyLoadQuery<InstallationInfoQuery>(
    graphql`
      query InstallationInfoQuery($id: ID!) {
        node(id: $id) {
          ... on Device {
            gatewayIMEI
            name
            customerName
            qrCode
            location {
              name
              street
              country
              postalCode
              city
              ... on Installation {
                geoLocation
                commissioningData {
                  commissioningDate
                  commissioningEngineer
                  commissioningNote
                }
              }
            }
            staticdata {
              id
              value
              code
            }
          }
        }
      }
    `,
    {
      id: toGlobalId('Device', id || ''),
    },
  );

  if (!data.data?.node) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;

  const { name: installationName, location, staticdata, gatewayIMEI, customerName } = data.data.node;
  const { commissioningDate, commissioningEngineer, commissioningNote } = location?.commissioningData ?? {};

  const typeKey = staticdata?.find((item) => item?.code === 'gfdm:TYPE_KEY')?.value;
  const productNumber = staticdata?.find((item) => item?.code === 'gfdm:PRODUCT_NUMBER')?.value;
  const serialNumber = staticdata?.find((item) => item?.code === 'gfdm:SERIAL_NUMBER')?.value;
  const firmware = staticdata?.find((item) => item?.code === 'gfdm:SOFTWARE_NAME1')?.value;
  const contactPersonName = staticdata?.find((item) => item?.code === 'sdcs:CONTACT_PERSON_NAME')?.value || '';
  const contactPersonPhone = staticdata?.find((item) => item?.code === 'sdcs:CONTACT_PERSON_PHONE')?.value || '';
  return (
    <div css={mainContentContainerStyles}>
      <div css={imageContainerStyles}>
        <img src={device} alt="customer device" />
      </div>
      <div css={infoContainserStyles}>
        <InfoSection title={t('WP.OVERVIEW.MODAL.GENERAL_INFORMATION')}>
          <InfoItem label={t('WP.OVERVIEW.MODAL.CUSTOMER_NAME')} value={customerName} />
          <InfoItem label={t('WP.OVERVIEW.MODAL.APPLICATION')} value={installationName} />
          <InfoItem
            label={t('WP.OVERVIEW.MODAL.ADDRESS')}
            value={`${location?.street ? `${location.street}, ` : ''}${location?.postalCode} ${location?.city}${
              location?.country ? `, ${location.country}` : ''
            }`}
          />
          <InfoItem
            label={t('WP.OVERVIEW.MODAL.GEO_LOCATION')}
            value={
              location?.geoLocation && (
                <a css={textLinkStyles} href={location?.geoLocation} target="_blank" rel="noreferrer">
                  See map
                </a>
              )
            }
          />
          <InfoItem label={t('WP.OVERVIEW.CONTACT_PERSON_ON_SITE')} value={contactPersonName} />
          <InfoItem label={t('WP.OVERVIEW.MODAL.CONTACT_PERSON_PHONE')} value={contactPersonPhone} />
        </InfoSection>
        <InfoSection title={t('WP.OVERVIEW.MODAL.DOSING_PUMP_INFO')}>
          <InfoItem label={t('WP.OVERVIEW.MODAL.TYPE_KEY')} value={typeKey} />
          <InfoItem label={t('WP.OVERVIEW.MODAL.DDA_PRODUCT_NUMBER')} value={productNumber} />
          <InfoItem label={t('WP.OVERVIEW.MODAL.DDA_SERIAL_NUMBER')} value={serialNumber} />
          <InfoItem label={t('WP.OVERVIEW.MODAL.DDA_FIRMWARE_NUMBER')} value={firmware} />
        </InfoSection>
        <InfoSection title={t('WP.OVERVIEW.MODAL.COMMISSIONING_INFORMATION')}>
          <InfoItem
            label={t('WP.OVERVIEW.MODAL.COMMISSIONING_DATE')}
            value={formatDate(commissioningDate, shortDateTimeFormat.dateFnsDateTimeFormat, locale)}
          />
          <InfoItem label={t('WP.OVERVIEW.MODAL.COMMISSIONING_ENGINEER')} value={commissioningEngineer} />
          <InfoItem
            label={t('WP.OVERVIEW.MODAL.COMMISSIONING_NOTE')}
            value={commissioningNote}
            css={installationNoteStyles}
          />
          <InfoItem label={t('WP.OVERVIEW.MODAL.GATEWAY_ID')} value={gatewayIMEI} />
          <InfoItem
            label={t('WP.OVERVIEW.MODAL.GATEWAY_QR')}
            value={data.data.node.qrCode && <img width="75" height="75" src={data.data.node.qrCode} alt="QR code" />}
          />
        </InfoSection>
      </div>
    </div>
  );
};

export default InstallationInfo;
