import { graphql, useMutation } from 'react-relay';
import React, { useEffect, useState, useCallback, startTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Loader, Snackbar } from '../../../components';
import { getProgressContainerStyles, spinnerModalStyles } from './styles';
import { GetProgressModalMutation } from './__generated__/GetProgressModalMutation.graphql';
import { FormValues, StatusMessages } from './types';

const GetProgressModal = ({
  runningInstanceId,
  update,
  disableFormSubmit,
}: {
  runningInstanceId?: string | null;
  update: () => void;
  disableFormSubmit: (val: boolean) => void;
}) => {
  const [feedback, setFeedback] = useState<StatusMessages>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const { t } = useTranslation();
  const [fetchProgress, setFetchProgress] = useState<boolean | undefined>(true);
  const COMMIT_INTERVAL = 2000;
  const closeSnackbarAfterDelay = () => setTimeout(() => setShowSnackbar(false), 5000);
  const { resetForm, values, initialValues } = useFormikContext<FormValues>();

  const [commit, isInFlight] = useMutation<GetProgressModalMutation>(graphql`
    mutation GetProgressModalMutation($input: GetProgressInput) {
      getProgess(input: $input) {
        status {
          progressmessage
          messagetype
          result {
            message
            hasFailed
            dataPoint
          }
        }
      }
    }
  `);

  const updateParentState = useCallback(() => {
    startTransition(() => {
      if (update) update();
    });
  }, [update]);

  useEffect(() => {
    const shouldDisable = () => !!(isInFlight || (fetchProgress && runningInstanceId));
    disableFormSubmit(shouldDisable());
    if (!fetchProgress) return;

    const id = setInterval(() => {
      if (runningInstanceId && fetchProgress) {
        commit({
          variables: {
            input: {
              runningInstanceId,
            },
          },
          onCompleted: (res) => {
            if (!res.getProgess?.status?.length) return;
            const newestFeedBack = res.getProgess.status[res.getProgess.status.length - 1];
            if (newestFeedBack?.messagetype === 'Failed') {
              setMessage(t('WP.REMOTE.UPDATE_ERROR'));
              setShowSnackbar(true);
              // Some values might have succeded
              // We will use time-out to wait for the event values from the pump;
              // to propogate to our latest device data service
              setTimeout(() => {
                // Update parent state will trigger the form to reset
                // - With the enable reinitialize prop on form.
                updateParentState();
                setFetchProgress(false);
                disableFormSubmit(false);
                // The timeout is a hack. We have no really good short term solutions
                // We cannot be sure that 2 sec is the right timeframe
                // But we only use this approach in failure scenario, so the likelyhood of error is
                // less than if we had to do this on every success.
              }, 2000);
            }
            if (newestFeedBack?.messagetype === 'Success') {
              // On success we keep the current form values
              // Because we trust that the geniwrite service has successfully applied
              // all changes to the pump
              // Therefore we have no updateParentState() or resetForm
              setMessage(t('WP.REMOTE.UPDATE_SUCCESS'));
              setShowSnackbar(true);
              closeSnackbarAfterDelay();
              setFetchProgress(false);
              disableFormSubmit(false);
            }
            setFeedback(res.getProgess?.status);
          },
          onError: (err) => {
            setFetchProgress(false);
            // eslint-disable-next-line no-console
            console.error(err);
            setMessage(t('WP.REMOTE.UPDATE_ERROR'));
            // We expect no changes to have happened on the pump from our geniwrite request
            // Therefore no timeout is needed here (e.g. don't wait for event values to propogate)
            updateParentState();
          },
        });
      }
    }, COMMIT_INTERVAL);

    return () => {
      clearInterval(id);
    };
  }, [
    runningInstanceId,
    fetchProgress,
    commit,
    feedback,
    updateParentState,
    resetForm,
    t,
    disableFormSubmit,
    isInFlight,
    values,
    initialValues,
  ]);

  return (
    <div css={getProgressContainerStyles}>
      {showSnackbar && <Snackbar message={message || t('WP.ERROR.UNKNOWN')} onClose={() => setShowSnackbar(false)} />}
      {runningInstanceId && fetchProgress && (
        <>
          <Snackbar message={t('WP.REMOTE.UPDATE_IN_PROGRESS')} />
          <div css={spinnerModalStyles}>
            <Loader size="large" />
          </div>
        </>
      )}
    </div>
  );
};

export default GetProgressModal;
