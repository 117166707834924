import { useState, useEffect } from 'react';

const getWidth = (): number => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const useWindowCurrentWidth = (): number => {
  const [width, setWidth] = useState<number>(getWidth());

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = 0;
    const resizeListener = (): void => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = window.setTimeout(() => setWidth(getWidth()), 100);
    };

    window.addEventListener('resize', resizeListener);

    return (): void => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return width;
};

export default useWindowCurrentWidth;
