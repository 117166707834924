import { css, SerializedStyles, Theme } from '@emotion/react';
import { paragraphBaseStyles } from '../../styles';

const labelStyles = (theme: Theme): SerializedStyles => css`
  grid-area: label;
  padding-bottom: 0.5rem;
  ${paragraphBaseStyles}
  color: ${theme.colors.neutral.neutral400.hex};
`;

const errorStyles = (theme: Theme) => css`
  grid-area: error;
  ${paragraphBaseStyles}
  color:${theme.colors.errorText};
  padding: 0.5rem 0rem;
`;

const textInputStyles = (theme: Theme): SerializedStyles => css`
  grid-area: input;
  color: ${theme.colors.neutral.neutral400.hex};
  background: ${theme.colors.tertiaryBackground};
  border: ${theme.colors.tertiaryBackground};
  padding: 0.5rem;
`;

const containerStyles = (isVertical: boolean): SerializedStyles => css`
  display: grid;
  align-items: center;
  ${isVertical ? '' : 'grid-template-columns: repeat(2, 1fr);'}
  grid-template-areas: 'label' 'input' 'error';
  column-gap: 0.5rem;
`;

const hideDefaultArrowsStyles = css`
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export { labelStyles, errorStyles, textInputStyles, containerStyles, hideDefaultArrowsStyles };
