import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  closeButtonStyles,
  containerStyles,
  displayStyles,
  headerContainerStyles,
  footerStyles,
  modalStyles,
  navItemStyles,
  navStyles,
  footerButtons,
} from './styles';
import { headingSmallBrightStyles } from '../../../../../styles';
import { Button } from '../../../../../components';
import close from '../../../../../assets/images/close.svg';
import { FooterProps, InstallationInfoProps, MainContentProps, TabOptions } from './types';
import InstallationInfo from './InstallationInfo';
import EditInformation from './EditInformation';

const Header = ({ title, onClick }: { title: string; onClick: () => void }) => (
  <div css={headerContainerStyles}>
    <p css={headingSmallBrightStyles}>{title}</p>
    <Button type="button" variant="text link" onClick={onClick} css={closeButtonStyles}>
      <img src={close} alt="" height="16" width="16" />
    </Button>
  </div>
);

const MainContent: React.FC<MainContentProps> = ({ tab, formId, loadingCallback }) => {
  switch (tab) {
    case 'edit':
      return <EditInformation formId={formId} loadingCallback={loadingCallback} />;
    default:
      return <InstallationInfo />;
  }
};

const Footer: React.FC<FooterProps> = ({ onClose, saveText, closeText, formId, isLoading }) => (
  <div css={footerButtons}>
    <Button type="button" variant="primary" onClick={onClose}>
      {closeText}
    </Button>
    {saveText && formId && (
      <Button disabled={isLoading} type="submit" variant="primary" formId={formId}>
        {saveText}
      </Button>
    )}
  </div>
);

const formId = 'edit-installation-info';

const InfoContainer: React.FC<InstallationInfoProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [tab, setTab] = React.useState<TabOptions>('info');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div css={[modalStyles, displayStyles]}>
      <div css={containerStyles}>
        <Header title={t('WP.OVERVIEW.MODAL.INSTALLATION_INFO')} onClick={onClose} />
        <nav css={navStyles}>
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className={tab === 'info' ? 'active' : undefined}
            css={navItemStyles}
            onClick={(e): void => {
              e.preventDefault();
              setTab('info');
            }}
          >
            {t('WP.OVERVIEW.MODAL.INSTALLATION_INFO_NAV')}
          </a>
          <a
            href="#"
            className={tab === 'edit' ? 'active' : undefined}
            css={navItemStyles}
            onClick={(e): void => {
              e.preventDefault();
              setTab('edit');
            }}
          >
            {t('WP.OVERVIEW.MODAL.INSTALLATION_EDIT_NAV')}
          </a>
        </nav>

        <MainContent tab={tab} formId={formId} loadingCallback={(loading) => setIsLoading(loading)} />

        <Footer
          isLoading={isLoading}
          css={footerStyles}
          closeText={t('WP.OVERVIEW.MODAL.INSTALLATION_CLOSE_BUTTON')}
          saveText={tab === 'edit' ? t('WP.OVERVIEW.MODAL.INSTALLATION_SAVE_BUTTON') : undefined}
          onClose={onClose}
          formId={tab === 'edit' ? formId : undefined}
        />
      </div>
    </div>
  );
};

export default InfoContainer;
