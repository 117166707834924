export interface Datapoint {
  createdAt: number;
  valueMin: number | null;
  valueMax: number | null;
  valueAvg: number | null;
  valueRaw: number | null;
  valueState?:
    | 'VALUE_STATE_UNSPECIFIED'
    | 'VALUE_STATE_OK'
    | 'VALUE_STATE_NOT_CONNECTED'
    | 'VALUE_STATE_NOT_AVAILABLE'
    | null;
}

export interface TimeSeriesDatapoint {
  name: string;
  unit: string | null;
  latestDatapoint: Datapoint;
  thresholds: Thresholds | null;
  datapoints: readonly Datapoint[];
}

export enum DurationUnit {
  H = 'hours',
  D = 'days',
  W = 'weeks',
  M = 'months',
  Y = 'years',
}

// eslint-disable-next-line import/prefer-default-export
export const isValidDurationUnit = (value: string): value is keyof typeof DurationUnit => value in DurationUnit;

export interface Thresholds {
  rangeMax: number | null;
  rangeMin: number | null;
  alarmUpper: number | null;
  alarmLower: number | null;
  warningUpper: number | null;
  warningLower: number | null;
}

export interface Legend {
  value: string;
  displayName: string;
}

export enum ValueState {
  VALUE_STATE_UNSPECIFIED = 0,
  VALUE_STATE_OK = 1,
  VALUE_STATE_NOT_CONNECTED = 2,
  VALUE_STATE_NOT_AVAILABLE = 3,
}
