import { graphql, useLazyLoadQuery } from 'relay-hooks';
import { useMutation } from 'react-relay';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toGlobalId } from 'graphql-relay';
import { useTranslation } from 'react-i18next';
import { RemoteControlWriteGeniValuesMutation } from './__generated__/RemoteControlWriteGeniValuesMutation.graphql';
import { RemoteControlQuery } from './__generated__/RemoteControlQuery.graphql';
import { getControlModeDefault, remoteControlEnabled } from './utils';
import { RemoteControlProps } from './types';
import RemoteControlForm from './RemoteControlForm';
import { Snackbar } from '../../../components';
import ReadOnlyRemote from './ReadOnlyRemote/ReadOnlyRemote';
import RemoteControlMesage from './RemoteControlMesage';
import { paragraphBrightStyles, textLinkStyles } from '../../../styles';

const RemoteControl: React.FC<RemoteControlProps> = ({ deviceTwinId }) => {
  const [runningInstanceId, setRunningInstanceId] = useState<string | null | undefined>();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const closeSnackbarAfterDelay = () => setTimeout(() => setShowSnackbar(false), 5000);
  const nodeId = toGlobalId('Device', id || '');
  const data = useLazyLoadQuery<RemoteControlQuery>(
    graphql`
      query RemoteControlQuery($id: ID!) {
        node(id: $id) {
          ... on Device {
            pumpInformation {
              maxFlow
              pumpVariant
            }
            remoteControlLatestDeviceData: latestDeviceData {
              dataPoints {
                name
                value
                unit
              }
            }
          }
        }
      }
    `,
    {
      id: nodeId,
    },
  );

  const [commit, isInFlight] = useMutation<RemoteControlWriteGeniValuesMutation>(graphql`
    mutation RemoteControlWriteGeniValuesMutation($input: WriteGeniValuesInput) {
      writeGeniValues(input: $input) {
        id
        runningInstanceId
      }
    }
  `);

  if (!data.data?.node) return null;
  const { node } = data.data;

  const currentPumpState = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:PUMPING_STATE',
  )?.value;

  const currentControlMode = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:CONTROL_MODE',
  );

  const currentMlPerPulse = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:PULSE_DOSING_VOLUME',
  );

  const currentManualDosingFlowValue = node?.remoteControlLatestDeviceData?.dataPoints
    ?.find((item) => item?.name === 'gfdm:MANUAL_DOSING_CAPACITY')
    ?.value?.toFixed(4);

  const currentManualDosingFlowUnit = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (item) => item?.name === 'gfdm:MANUAL_DOSING_CAPACITY',
  )?.unit;

  const currentPulseMemory = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (item) => item?.name === 'gfdm:PULSE_MEMORY_ED',
  )?.value;

  const currentAutoDeaeration = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (item) => item?.name === 'gfdm:AUTO_DEAERATING_ED',
  )?.value;

  const currentSlowModeSetting = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:ANTI_CAVITATION_SETUP',
  )?.value;

  const currentFlowControl = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:FLOW_MONITORING_ED',
  )?.value;

  const currentMaxPressureAlarm = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:FLOW_MONITORING_PRESSURE_MAX',
  );

  const currentFlowControlDelay = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'sdcs:FLOW_CONTROL_DELAY',
  )?.value;

  const currentFlowControlSensitivity = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'sdcs:FLOW_CONTROL_SENSITIVITY',
  )?.value;

  const currentFlowControlAutoFlowAdapt = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'sdcs:FLOW_CONTROL_AUTO_FLOW_ADAPT',
  )?.value;

  const currentFlowControlMinPressure = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'sdcs:FLOW_CONTROL_MIN_PRESSURE',
  )?.value;

  const commSetup = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (item) => item?.name === 'gfdm:COMM_SETUP',
  )?.value;
  // 0 = running, 1 hmi / display, 2 ext / cable, 3 both hmi and ext, 4 bus, 5 hmi and bus, 6 ext and bus, 7 hmi, ext and bus
  const stoppedBy = node?.remoteControlLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:STOP_CONTROL_STATE',
  )?.value;

  // 4 is bus, 0 is pump running
  const pumpIsStopped = stoppedBy !== 0;
  // This mean the pump could be stopped by either
  // HMI (directly from the pump)
  // and/or EXT (an external cabled signal)
  const isStoppedByOtherThanRemote = stoppedBy !== 4 && pumpIsStopped;

  const anyIsNullOrUndefined = (...values: (string | number | null | undefined)[]): boolean =>
    values.some((value) => value === undefined || value === null);

  const oneOrMoreValuesAreMissing = anyIsNullOrUndefined(
    currentAutoDeaeration,
    currentControlMode?.value,
    currentPumpState,
    currentPulseMemory,
    currentMlPerPulse?.value,
    currentManualDosingFlowValue,
  );

  const busControlIsOff = !remoteControlEnabled(commSetup);
  const isLimitedVariant = node?.pumpInformation?.pumpVariant !== 'FCM';

  const busOffMessage = t('WP.REMOTE.READ_MODE_BUS_OFFLINE');
  const valuesNotReceived = t('WP.REMOTE.READ_MODE_VALUES_NOT_RECEIVED');

  const displaySelect = () => {
    if (oneOrMoreValuesAreMissing) {
      return { isReadonlyMode: true, MessagComponent: <RemoteControlMesage message={valuesNotReceived} /> };
    }
    if (busControlIsOff) {
      return { isReadonlyMode: true, MessagComponent: <RemoteControlMesage message={busOffMessage} /> };
    }
    if (isLimitedVariant) {
      return {
        isReadonlyMode: false,
        MessagComponent: (
          <RemoteControlMesage
            message={
              <div css={paragraphBrightStyles}>
                {`${t('WP.REMOTE.READ_MODE_LIMITED_DDA_VARIANT')} `}
                <a
                  css={textLinkStyles}
                  target="_blank"
                  href="https://product-selection.grundfos.com/products/dosing-pumps-digital/dda?tab=explore"
                  rel="noreferrer"
                >
                  {t('WP.REMOTE.READ_MODE_LIMITED_DDA_VARIANT_READ_MORE')}
                </a>
              </div>
            }
          />
        ),
      };
    }
    return { isReadonlyMode: false };
  };

  const displaySelection = displaySelect();

  if (displaySelection.isReadonlyMode) {
    return (
      <ReadOnlyRemote
        autoDeaeration={currentAutoDeaeration}
        controlMode={currentControlMode?.value}
        operation={currentPumpState}
        pulseMemory={currentPulseMemory}
        pulseDosingVolume={currentMlPerPulse?.value}
        manualDosingFlowUnit={currentManualDosingFlowUnit}
        manualDosingVolume={currentManualDosingFlowValue}
        slowMode={currentSlowModeSetting}
        flowControl={currentFlowControl}
        delay={currentFlowControlDelay}
        sensitivity={currentFlowControlSensitivity}
        maxPressureAlamUnit={currentMaxPressureAlarm?.unit}
        maxPressureAlarm={currentMaxPressureAlarm?.value}
        autoFlowAdapt={currentFlowControlAutoFlowAdapt}
        minPressureAsAlarm={currentFlowControlMinPressure}
        pumpVariant={node?.pumpInformation?.pumpVariant}
        Message={displaySelection.MessagComponent}
        // Use the store-and-network policy to avoid loading spinner
        refetch={() => data.retry(undefined, { fetchPolicy: 'store-and-network' })}
      />
    );
  }

  return (
    <>
      {showSnackbar && (
        <Snackbar message={errorMessage || t('WP.ERROR.UNKNOWN')} onClose={() => setShowSnackbar(false)} />
      )}
      {
        <RemoteControlForm
          maxFlow={node?.pumpInformation?.maxFlow}
          pumpVariant={node?.pumpInformation?.pumpVariant}
          currentSlowModeSetting={currentSlowModeSetting ?? undefined}
          currentManualDosingFlowUnit={currentManualDosingFlowUnit ?? undefined}
          currentPumpState={currentPumpState ?? undefined}
          currentControlMode={getControlModeDefault(currentControlMode?.value)}
          currentControlModeValue={currentControlMode?.value ?? undefined}
          currentMlPerPulse={`${currentMlPerPulse?.value}`}
          currentManualDosingFlowValue={`${currentManualDosingFlowValue}`}
          currentPulseMemory={currentPulseMemory === 1}
          currentAutoDeaeration={currentAutoDeaeration === 1}
          currentFlowControl={currentFlowControl === 1}
          currentFlowControlMinPressure={currentFlowControlMinPressure === 1}
          currentFlowControlAutoFlowAdapt={currentFlowControlAutoFlowAdapt === 1}
          currentMaxPressureAlarm={currentMaxPressureAlarm?.value || undefined}
          maxPressureAlamUnit={currentMaxPressureAlarm?.unit || undefined}
          isStoppedByOtherThanRemote={isStoppedByOtherThanRemote}
          currentFlowControlDelay={currentFlowControlDelay ?? undefined}
          currentFlowControlSensitivity={currentFlowControlSensitivity ?? undefined}
          onFormSubmit={(geniValues) => {
            // If no settings have been changed and the user press send we should still pretend to update
            if (!geniValues.length) {
              setErrorMessage(t('WP.REMOTE.UPDATE_SUCCESS'));
              setShowSnackbar(true);
              closeSnackbarAfterDelay();
            } else {
              commit({
                variables: {
                  input: {
                    deviceTwinId,
                    geniValues,
                  },
                },
                updater: (store) => {
                  // We mark the store as stale, so that Relay knows to refetch it the next time it is rendered
                  const userRecordProxy = store.getRoot();
                  userRecordProxy?.invalidateRecord();
                },
                onCompleted: (res) => {
                  setRunningInstanceId(res.writeGeniValues?.runningInstanceId);
                },
                onError: (error: any) => {
                  // eslint-disable-next-line no-console
                  console.error(error);
                  setErrorMessage(t(`WP.ERROR.${error.errors[0]?.message}`));
                  setShowSnackbar(true);
                },
              });
            }
          }}
          runningInstanceId={runningInstanceId || ''}
          onUpdate={data.retry}
          isLoading={isInFlight}
          Message={displaySelection.MessagComponent}
        />
      }
    </>
  );
};

export default RemoteControl;
