// eslint-disable-next-line import/no-extraneous-dependencies
import { ConnectionHandler, Environment, FetchFunction, Network, RecordSource, Store } from 'relay-runtime';
import { commitLocalUpdate } from 'react-relay';
import { v4 } from 'uuid';
import CorrelatedError from '../utils/errors/CorrelatedError';

const getAccessToken = (): string => {
  const sessionKey = process.env.GIC_UI_AUTH_SESSION_KEY;
  const b2cInfo = sessionKey ? sessionStorage.getItem(sessionKey) : undefined;
  return b2cInfo ? JSON.parse(b2cInfo).access_token : '';
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-function-return-type
const fetchRelay: FetchFunction = async (params, variables, _cacheConfig) => {
  const correlationId = v4();

  const token = getAccessToken();

  if (!token) return;

  const response = await fetch(process.env.GIC_UI_GRAPHQL_URL as string, {
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'x-correlation-id': correlationId,
      'x-caller-id': 'sdcs-client',
      ...((localStorage.getItem('accountId') && { 'x-account-id': localStorage.getItem('accountId') }) as any),
    },
    method: 'POST',
  });

  const json = await response.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    // eslint-disable-next-line no-console
    if (process.env.GIC_UI_ENVIRONMENT === 'localhost') console.log(json.errors);

    throw new CorrelatedError(
      `Error fetching GraphQL query '${params.name}' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        json.errors,
      )}`,
      correlationId,
      json.errors,
    );
  }

  // Otherwise, return the full payload.
  return json;
};

const handlerProvider = (handle: string): unknown => {
  switch (handle) {
    case 'connection':
      return ConnectionHandler;
    default:
      throw new Error(`Handler for ${handle} not found.`);
  }
};

export default () => {
  const environment = new Environment({
    handlerProvider,
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
  });

  // Initialize client state
  commitLocalUpdate(environment, (store) => {
    const clientState = store.create('client:clientState', 'ClientState');
    store.getRoot().setLinkedRecord(clientState, 'clientState');
  });

  return environment;
};
