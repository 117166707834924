import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const itemStyles = (theme: Theme): SerializedStyles => css`
  display: grid;
  grid-template-areas:
    'type title'
    'type datetime'
    'type device'
    'type address';
  grid-template-columns: max-content auto;
  grid-column-gap: 1rem;
  grid-row-gap: 0.2rem;
  background: ${theme.colors.secondaryBackground};
  min-height: 7rem;
  padding: 1rem;
  justify-items: left;
  color: ${theme.colors.primaryText};
  margin-bottom: 0.5rem;
  text-decoration: none;
  :hover {
    background-color: ${theme.colors.tertiaryBackground};
    cursor: pointer;
  }
  ${dsmMediaQuery('large device')} {
    min-height: 4rem;
    grid-template-areas: 'type title device address datetime troubleshoot';
    grid-template-columns: max-content minmax(1rem, 20rem) minmax(1rem, 20rem) minmax(1rem, 20rem) minmax(1rem, 10rem) max-content;
    align-items: center;
    :hover {
      button {
        background-color: #fff;
        border: none;
        color: #000;
      }
    }
  }
`;

const iconStyles = css`
  vertical-align: middle;
`;

const textStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
`;

const deviceTextStyles = css`
  padding-bottom: 0.05rem;
  :hover {
    padding-bottom: 0;
    border-bottom: 0.05rem solid #fff;
  }
`;

const typeStyles = css`
  grid-area: type;
  align-self: center;
`;

const datetimeStyles = css`
  grid-area: datetime;
  ${dsmMediaQuery('large device')} {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1rem auto;
    align-items: center;
  }
`;

const titleContainerStyles = css`
  grid-area: title;
  display: inherit;
`;

const titleStyles = css`
  font-size: 18px;
  color: #fff;
  word-break: break-all;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const deviceStyles = css`
  grid-area: device;
`;

const inlineIconStyles = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1rem auto;
  align-items: center;
  grid-column-gap: 0.5rem;
`;

const addressStyles = css`
  grid-area: address;
`;

const troubleshootButtonStyles = css`
  display: none;

  ${dsmMediaQuery('large device')} {
    display: unset;
    button {
      cursor: pointer;
      height: 2rem;
      width: 8rem;
      border: 1px solid #ffffff;
      background-color: transparent;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
`;

const timestampStyles = css`
  vertical-align: middle;
  margin-left: 0.3rem;
`;

export {
  itemStyles,
  typeStyles,
  datetimeStyles,
  titleStyles,
  deviceStyles,
  inlineIconStyles,
  addressStyles,
  troubleshootButtonStyles,
  textStyles,
  deviceTextStyles,
  timestampStyles,
  iconStyles,
  titleContainerStyles,
};
