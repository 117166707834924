const buttonClicked = (label: string, url?: string): void => {
  const state = window.digitalData[window.digitalData.length - 1];
  window.digitalData.push({
    ...state,
    event: 'Click',
    data: {
      ...state?.data,
      eventName: 'User Interaction',
      genericElementCategory: 'button',
      genericElementAction: label,
      pageInfo: {
        ...state?.data?.pageInfo,
        hier1: url || state.data.pageInfo.hier1,
      },
    },
  });
};

export default buttonClicked;
