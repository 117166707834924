import React from 'react';
import { useTheme } from '@emotion/react';
import AlarmIcon from '../Icons/WarningCircleOutline';
import InfoIcon from '../Icons/InfoCircleOutline';
import SuccessIcon from '../Icons/CheckCircleOutline';
import CloseIcon from '../Icons/CloseOutline';
import WarningIcon from '../Icons/WarningTriangleOutline';
import { closeIconContainer, informationAndActionContainer, snackbarContainer, iconWrapper, svgStyles } from './styles';

import { SnackbarProps, IconWrapperProps, SnackbarIconProps } from './types';
import { paragraphBrightStyles } from '../../styles';

const IconWrapper = ({ children, color }: IconWrapperProps) => <div css={iconWrapper(color)}>{children}</div>;

const SnackbarIcon = ({ snackbarType }: SnackbarIconProps) => {
  const theme = useTheme();

  switch (snackbarType) {
    case 'ALARM':
      return (
        <IconWrapper color={theme.colors.event.alarm}>
          <AlarmIcon color={theme.colors.white.primary} size="1.75rem" styles={svgStyles} />
        </IconWrapper>
      );
    case 'SUCCESS':
      return (
        <IconWrapper color={theme.colors.event.none}>
          <SuccessIcon color={theme.colors.white.primary} size="1.75rem" styles={svgStyles} />
        </IconWrapper>
      );
    case 'WARNING':
      return (
        <IconWrapper color={theme.colors.event.warning}>
          <WarningIcon color={theme.colors.white.primary} size="1.75rem" styles={svgStyles} />
        </IconWrapper>
      );
    case 'INFO':
      return (
        <IconWrapper color="transparent">
          <InfoIcon color={theme.colors.white.primary} size="1.75rem" styles={svgStyles} />
        </IconWrapper>
      );
    default:
      return <IconWrapper color="transparent" />;
  }
};

const Snackbar: React.FC<SnackbarProps> = ({ message, onClose, snackbarType }) => {
  const theme = useTheme();
  return (
    <div css={snackbarContainer}>
      <SnackbarIcon snackbarType={snackbarType} />
      <div css={informationAndActionContainer}>
        <div css={paragraphBrightStyles}>{message}</div>
      </div>
      {onClose && (
        <div css={closeIconContainer} onClick={onClose} role="button" onKeyDown={onClose} tabIndex={0}>
          <CloseIcon color={theme.colors.white.primary} size="1.5rem" styles={svgStyles} />
        </div>
      )}
    </div>
  );
};

export default Snackbar;
