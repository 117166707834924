import React from 'react';
import { useTheme } from '@emotion/react';
import { containerStyles, textTruncateStyles, iconValueUnitGroupStyles } from './styles';
import { paragraphBrightStyles, paragraphDimmedStyles } from '../../styles';

interface InfoItemProps {
  label: string | JSX.Element;
  Icon?: JSX.Element;
  value?: string | number | null | JSX.Element;
  unit?: string | null;
  className?: string;
  noBorder?: boolean;
  inActive?: boolean;
}

export const InfoItemIcon = ({ iconSrc }: { iconSrc: string }) => <img height="16" src={iconSrc} alt="icon" />;

const InfoItem = ({ label, value, Icon, unit, className, inActive = false, noBorder = false }: InfoItemProps) => {
  const theme = useTheme();
  return (
    <div className={className} css={[containerStyles(theme, noBorder), paragraphDimmedStyles]}>
      <div css={textTruncateStyles}>{label}</div>
      <div css={[iconValueUnitGroupStyles(!!value), inActive ? paragraphDimmedStyles : paragraphBrightStyles]}>
        {Icon}
        <div>{value}</div>
        {unit && <div>{unit}</div>}
      </div>
    </div>
  );
};

export default InfoItem;
