import React, { useCallback, useEffect, useState } from 'react';
import { toGlobalId } from 'graphql-relay';
import { useTranslation } from 'react-i18next';
import { FetchPolicy, fetchQuery, graphql, useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks';
import { Tiles } from '../../../../components/Tiles';
import { overviewChemPairingStyles } from './styles';
import DeviceQuery, { GeniIdentity, OverviewChemPairingQuery } from './__generated__/OverviewChemPairingQuery.graphql';
import { NoDataAvailableMessage } from '../../../../components';
import CalculatedContainerLevel from './CalculatedContainerLevel/CalculatedContainerLevel';
import TimeToService from './TimeToService/TimeToService';
import PumpStatusData from './PumpStatus/PumpStatusData';
import DosedVolume from './DosedVolume/DosedVolume';
import OverviewTile from './OverviewTile/OverviewTile';
import VolumetricConcentration from './VolumetricConcentration/VolumetricConcentration';
import AlertsTile from './AlertsTile/AlertsTileRelay';

const UPDATE_INTERVAL = 10000;

interface OverviewChemPairingProps {
  startDate: number;
  endDate: number;
  id: string;
  goToEvents: () => void;
  // eslint-disable-next-line react/require-default-props
  geniIdentity?: {
    familyCode?: number | null;
    unitType?: number | null;
    unitVersion?: number | null;
  } | null;
  queryOptions?: QueryOptions;
}

interface OverviewChemPairingPropsRefresher {
  startDate: number;
  endDate: number;
  id: string;
  goToEvents: () => void;
  // eslint-disable-next-line react/require-default-props
  geniIdentity?: {
    familyCode?: number | null;
    unitType?: number | null;
    unitVersion?: number | null;
  } | null;
}

interface QueryOptions {
  fetchKey: number;
  fetchPolicy: FetchPolicy;
}

const defaultGeniIdentity = { familyCode: 30, unitType: 1, unitVersion: 1 };

const OverviewChemPairing: React.FC<OverviewChemPairingProps> = ({
  startDate,
  endDate,
  id,
  geniIdentity,
  goToEvents,
  queryOptions,
}) => {
  const { t } = useTranslation();
  const data = useLazyLoadQuery<OverviewChemPairingQuery>(
    graphql`
      query OverviewChemPairingQuery($id: ID!, $startDate: Int!, $endDate: Int!, $geniIdentity: GeniIdentity!) {
        node(id: $id) {
          ... on Device {
            latestDeviceData {
              dataPoints {
                value
                name
              }
            }
            ...OverviewTile_device
            ...TimeToService_device
            ...VolumetricConcentration_device @arguments(endDate: $endDate)
            ...DosedVolume_device @arguments(startDate: $startDate, endDate: $endDate)
            ...CalculatedContainerLevel_device @arguments(endDate: $endDate)
            ...PumpStatusData_device
            ...AlertsTileRelay_device
          }
        }
      }
    `,
    {
      id: toGlobalId('Device', id),
      startDate,
      endDate,
      geniIdentity: { ...defaultGeniIdentity, ...geniIdentity } as GeniIdentity,
    },
    queryOptions,
  );

  if (!data.node) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;

  const controlMode = data?.node?.latestDeviceData?.dataPoints?.find((dp) => dp?.name === 'gfdm:CONTROL_MODE');

  return (
    <Tiles styles={overviewChemPairingStyles}>
      <OverviewTile device={data.node} />
      <AlertsTile device={data} goToEvents={goToEvents} />
      <CalculatedContainerLevel device={data} />
      {/* controlMode.value = 1 is pulse mode and 0 is manual, 2, 3 and 4 is not supported yet */}
      {controlMode?.value === 1 ? <VolumetricConcentration device={data} /> : <DosedVolume device={data} />}
      <PumpStatusData device={data} />
      <TimeToService device={data} />
    </Tiles>
  );
};
// Refresh a lazyload query essentially requires the fetchKey to be updated: https://relay.dev/docs/next/guided-tour/refetching/refreshing-queries/#when-using-uselazyloadquery
// But refreshing a lazyload query without triggering Suspense requires manual fetchQuery request: https://relay.dev/docs/next/guided-tour/refetching/refreshing-queries/#if-you-need-to-avoid-suspense-1
const OverviewChemPairingRefresher: React.FC<OverviewChemPairingPropsRefresher> = ({
  startDate,
  endDate,
  id,
  geniIdentity,
  goToEvents,
}) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<
    | {
        fetchKey: number;
        fetchPolicy: FetchPolicy;
      }
    | undefined
  >();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const environment = useRelayEnvironment();
  const refresh = useCallback(() => {
    if (isRefreshing) {
      return;
    }
    setIsRefreshing(true);

    // fetchQuery will fetch the query and write
    // the data to the Relay store. This will ensure
    // that when we re-render, the data is already
    // cached and we don't suspend
    fetchQuery(environment, DeviceQuery, {
      id: toGlobalId('Device', id),
      startDate,
      endDate,
      geniIdentity: { ...defaultGeniIdentity, ...geniIdentity } as GeniIdentity,
    }).subscribe({
      complete: () => {
        setIsRefreshing(false);

        // *After* the query has been fetched, we update
        // our state to re-render with the new fetchKey
        // and fetchPolicy.
        // At this point the data for the query should
        // be cached, so we use the 'store-only'
        // fetchPolicy to avoid suspending.
        setRefreshedQueryOptions((prev) => ({
          fetchKey: (prev?.fetchKey ?? 0) + 1,
          fetchPolicy: 'store-only',
        }));
      },
      error: () => {
        setIsRefreshing(false);
      },
    });
  }, [endDate, environment, geniIdentity, id, isRefreshing, startDate]);

  useEffect(() => {
    const interval = setInterval(refresh, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, [refresh]);

  return (
    <OverviewChemPairing
      startDate={startDate}
      endDate={endDate}
      id={id}
      geniIdentity={geniIdentity}
      goToEvents={goToEvents}
      queryOptions={refreshedQueryOptions}
    />
  );
};

export default OverviewChemPairingRefresher;
