import { SnackbarType } from '../../components/Snackbar/types';

export interface SnackbarProps {
  snackbarType?: SnackbarType;
  message: string;
}

// eslint-disable-next-line no-shadow
export enum UnitSystem {
  EU = 'EU',
  US = 'US',
}
