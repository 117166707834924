import { css, useTheme } from '@emotion/react';
import React from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay/hooks';
import clockIcon from '../../assets/images/clock.svg';
import deviceIcon from '../../assets/images/device.svg';
import inactiveAlarmIcon from '../../assets/images/inactive-alarm.svg';
import inactiveWarningIcon from '../../assets/images/inactive-warning.svg';
import sectionIcon from '../../assets/images/section.svg';
import DateTimeFormatter from '../DateTimeFormatter/DateTimeFormatter';
import { Alarm, Warning } from '../Icons';
import {
  eventCodeStyles,
  eventIconStyles,
  datetimeStyles,
  deviceStyles,
  headerContainerStyles,
  sectionStyles,
  subTextStyles,
  textIconContainerStyles,
  timestampStyles,
  typeCodeStyles,
} from './styles';
import { EventHeaderProps } from './types';
import { useEventCodeTranslations } from '../../utils/mappings/event-code-translations';

const EventHeader: React.FC<EventHeaderProps> = (props) => {
  const event = useFragment(
    graphql`
      fragment EventHeader_event on Event {
        createdAt
        clearedAt
        device {
          name
          location {
            name
          }
        }
        type
        code
      }
    `,
    // eslint-disable-next-line react/destructuring-assignment
    props.event,
  );

  const theme = useTheme();
  const code = useEventCodeTranslations(event?.code);
  const { type, createdAt, clearedAt } = event;

  return (
    <div
      css={css`
        grid-area: eventTitleHeader;
      `}
    >
      <div css={headerContainerStyles(theme.colors.primaryBorder)}>
        <div css={typeCodeStyles}>
          {clearedAt && type === 'ALARM' && <img css={eventIconStyles} src={inactiveAlarmIcon} alt="inactive-alarm" />}
          {!clearedAt && type === 'ALARM' && (
            <div css={eventIconStyles}>
              <Alarm color="#E33353" />
            </div>
          )}
          {clearedAt && type === 'WARNING' && (
            <img css={eventIconStyles} src={inactiveWarningIcon} alt="inactive-warning" />
          )}
          {!clearedAt && type === 'WARNING' && (
            <div css={eventIconStyles}>
              <Warning color="#F6AA33" />
            </div>
          )}
          <span css={eventCodeStyles} data-test-id="event-code">
            {code}
          </span>
        </div>
        <div css={subTextStyles}>
          <div css={[textIconContainerStyles, deviceStyles]}>
            <img src={deviceIcon} alt="device-icon" />
            <span data-test-id="device-name">{event.device.name}</span>
          </div>
          <div css={[textIconContainerStyles, sectionStyles]}>
            <img src={sectionIcon} alt="device-icon" />
            <span data-test-id="device-section">{event.device.location?.name}</span>
          </div>
        </div>
        <div css={[textIconContainerStyles, timestampStyles]}>
          <img src={clockIcon} alt="clock-icon" />
          <span css={datetimeStyles}>
            <DateTimeFormatter timestamp={createdAt} />
            {clearedAt && (
              <span>
                (cleared <DateTimeFormatter timestamp={clearedAt} />)
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
