/* eslint-disable jsx-a11y/no-static-element-interactions */
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@gic/react-auth';
import React, { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Link, NavLink, useLocation } from 'react-router-dom';
import sentryCaptureError from '../../../../utils/sentry/sentry-capture-error';
import CustomersIcon from '../../../../assets/images/customers.svg';
import SwitchAccountIcon from '../../../../assets/images/switch-account.svg';
import HomeIcon from '../../../../assets/images/house.svg';
import UserIcon from '../../../../assets/images/userOutline.svg';
import { ErrorBoundary } from '../../../../components';
import { GFLogo } from '../../../../components/Icons';
import LogoutIcon from '../../../../components/Icons/Logout';
import { Item, Items, Logo, Navigation } from '../../../../components/Navigation';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import AccountSwitcher from '../../AccountSwitcher/AccountSwitcher';
import AccountSwitcherErrorFallback from '../../AccountSwitcher/ErrorFallback/ErrorFallback';
import { accountSwitcher, activeLink, logoutButtonStyles, logoutWrapperStyles, navLink } from './styles';
import { MainNavigationProps } from './types';
import { dsmMediaMinWidthSelector } from '../../../../utils/media-queries/media-queries';

const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
  e.nativeEvent.stopImmediatePropagation();
};

const onTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
  e.nativeEvent.stopImmediatePropagation();
};

const ActiveLinkWrapper = ({ children, isActive }: { children: JSX.Element; isActive: boolean }) => (
  <div className={isActive ? 'active' : ''} css={activeLink}>
    {children}
  </div>
);
const ImageExtraSpaceFix = ({ imageHeightPx, children }: { children: JSX.Element; imageHeightPx?: number }) => (
  <div
    css={css`
      height: ${imageHeightPx || '16'}px;
    `}
  >
    {children}
  </div>
);

const MainNavigation: React.FC<MainNavigationProps> = (props) => {
  const { logout } = useAuth();
  const { pathname } = useLocation();
  const accounts = useFragment(
    graphql`
      fragment MainNavigation_accounts on AccountConnection {
        totalCount
      }
    `,
    props?.accounts,
  );

  const account = useFragment(
    graphql`
      fragment MainNavigation_account on Account {
        locations(typeCodes: [INSTALLATION, SECTION]) {
          edges {
            node {
              __typename
              uuid
            }
          }
        }
      }
    `,
    props?.account,
  );

  const [isAccountSwitcherOpen, setIsAccountSwitcherOpen] = useState(false);
  const { t } = useTranslation();
  const mediumDeviceQuery = dsmMediaMinWidthSelector('medium device');
  const [placement, setPlacaement] = useState<'right' | 'top'>(
    window.matchMedia(mediumDeviceQuery).matches ? 'right' : 'top',
  );

  useEffect(() => {
    const mediaFunc = (e: MediaQueryListEvent) => setPlacaement(e.matches ? 'right' : 'top');
    window.matchMedia(mediumDeviceQuery).addEventListener('change', mediaFunc);
  }, [mediumDeviceQuery]);
  if (!account || !accounts) return null;

  const nodes = account.locations?.edges
    ?.filter(Boolean)
    .map((edge) => edge?.node)
    .filter(Boolean);

  return (
    <Navigation
      // eslint-disable-next-line react/destructuring-assignment
      styles={props.styles}
      Logo={
        <Logo>
          <Link to="/alerts">
            <GFLogo />
          </Link>
        </Logo>
      }
      Items={
        <Items>
          <Item>
            <NavLink to="/alerts" css={navLink}>
              {({ isActive }) => (
                <ActiveLinkWrapper isActive={isActive}>
                  <Tooltip content={t('WP.MENU.ALERTS')} placement="right" size="small">
                    <ImageExtraSpaceFix imageHeightPx={32}>
                      <img width="32" height="32" src={HomeIcon} alt="organizations" />
                    </ImageExtraSpaceFix>
                  </Tooltip>
                </ActiveLinkWrapper>
              )}
            </NavLink>
          </Item>

          <div onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
            <Item>
              <NavLink
                to={`/${nodes?.[0]?.__typename?.toLowerCase()}s/${nodes?.[0]?.uuid}`}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
                  if (nodes && nodes?.length > 1) {
                    e.preventDefault();
                    props.toggleShowSubNavigation();
                  }
                }}
                css={navLink}
              >
                {() => {
                  const isActive = ['/devices', '/sections'].some((path) => pathname.includes(path));
                  return (
                    <ActiveLinkWrapper isActive={isActive}>
                      <Tooltip content={t('WP.MENU.LOCATIONS')} placement="right" size="small">
                        <ImageExtraSpaceFix imageHeightPx={32}>
                          <img width="32" height="32" src={CustomersIcon} alt="organizations" />
                        </ImageExtraSpaceFix>
                      </Tooltip>
                    </ActiveLinkWrapper>
                  );
                }}
              </NavLink>
            </Item>
          </div>
          <Item autoMargin="top">
            <NavLink to="/profile" css={navLink}>
              {({ isActive }) => (
                <ActiveLinkWrapper isActive={isActive}>
                  <Tooltip content={t('WP.MENU.PROFILE')} placement="right" size="small">
                    <ImageExtraSpaceFix imageHeightPx={32}>
                      <img width="32" height="32" src={UserIcon} alt="user" />
                    </ImageExtraSpaceFix>
                  </Tooltip>
                </ActiveLinkWrapper>
              )}
            </NavLink>
          </Item>
          {accounts?.totalCount > 1 ? (
            <Item>
              <Tooltip
                content={
                  <ErrorBoundary
                    onError={(error): void => sentryCaptureError(error, 'MainNavigation - AccountSwitcher')}
                    FallbackComponent={AccountSwitcherErrorFallback}
                  >
                    <AccountSwitcher onClick={() => setIsAccountSwitcherOpen(false)} />
                  </ErrorBoundary>
                }
                trigger="click"
                visible={isAccountSwitcherOpen}
                placement={placement}
                size="medium"
                onClose={() => setIsAccountSwitcherOpen(false)}
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div
                  onTouchStart={(e: React.TouchEvent<HTMLDivElement>): void => {
                    setIsAccountSwitcherOpen(!isAccountSwitcherOpen);
                  }}
                  onClick={(): void => {
                    setIsAccountSwitcherOpen(!isAccountSwitcherOpen);
                  }}
                >
                  <div css={accountSwitcher}>
                    <ImageExtraSpaceFix imageHeightPx={32}>
                      <img width="32" height="32" src={SwitchAccountIcon} alt="switch-account" />
                    </ImageExtraSpaceFix>
                  </div>
                </div>
              </Tooltip>
            </Item>
          ) : null}
          <Item>
            <div css={logoutWrapperStyles}>
              <Tooltip content={t('WP.MENU.LOG_OUT')} placement={placement} size="small">
                <button
                  title={t('WP.MENU.LOG_OUT')}
                  data-test-id="logout-button"
                  css={logoutButtonStyles}
                  type="button"
                  onClick={logout}
                >
                  <LogoutIcon />
                </button>
              </Tooltip>
            </div>
          </Item>
        </Items>
      }
    />
  );
};

export default MainNavigation;
