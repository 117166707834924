import { toGlobalId } from 'graphql-relay';
import React, { useEffect } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { validate } from 'uuid';
import sentryCaptureError from '../../utils/sentry/sentry-capture-error';
import EventBody from '../EventBody/EventBody';
import EventFooter from './EventFooter/EventFooter';
import EventHeader from '../EventHeader/EventHeader';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import { EventQuery } from './__generated__/EventQuery.graphql';
import { headerBodyContent, notFoundHeaderStyles, notFoundStyles, headerContainerStyles, eventStyles } from './styles';
import { EventProps } from './types';
import 'react-loading-skeleton/dist/skeleton.css';

const Event: React.FC<EventProps> = ({
  id,
  onButtonGoToAssetDetailsClick,
  onClose,
  onComponentDidMount,
  onComponentWillUnmount,
  onLinkServicePhoneClick,
}) => {
  const data = useLazyLoadQuery<EventQuery>(
    graphql`
      query EventQuery($id: ID!) {
        node(id: $id) {
          id
          ... on Event {
            code
            type
            ...EventBody_items
            ...EventHeader_event
            ...EventFooter_item
          }
        }
      }
    `,
    { id: validate(id) ? toGlobalId('Event', id) : id },
  );

  useEffect(() => {
    if (onComponentDidMount) onComponentDidMount(data?.node);

    return () => {
      if (onComponentWillUnmount) onComponentWillUnmount(data?.node);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { node } = data;

  return (
    <div css={eventStyles}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error): void => sentryCaptureError(error, 'Event')}>
        {node ? (
          <div css={headerBodyContent}>
            <div css={headerContainerStyles}>
              <EventHeader event={node} />
            </div>
            <EventBody eventDetailsData={node} />
          </div>
        ) : (
          <div css={headerBodyContent}>
            <div css={notFoundStyles}>
              <div css={notFoundHeaderStyles}>Event not found</div>
              <div>It&apos;s likely that the event has already been resolved</div>
            </div>
          </div>
        )}
        <EventFooter
          eventDetailsData={node}
          onGoToAssetDetails={(): void => {
            if (onButtonGoToAssetDetailsClick) onButtonGoToAssetDetailsClick();
          }}
          onLinkServicePhoneClick={onLinkServicePhoneClick}
          onClose={onClose}
        />
      </ErrorBoundary>
    </div>
  );
};

export default Event;
