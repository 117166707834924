import { css, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const bodyContainer = css`
  grid-template-areas: 'problem' 'risk' 'solution';
  display: grid;
  margin: 0;
  padding: 0.5rem 0;
  ${dsmMediaQuery('large device')} {
    grid-template-columns: 1fr 0;
    grid-template-areas: 'text device-img';
    grid-column-gap: 1rem;
    overflow: hidden;
    padding: 4rem 0;
  }
`;

const remedyTextContainer = css`
  position: relative;
  z-index: 1000;
  grid-area: text;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, min-content);
  grid-gap: 1rem;
  ${dsmMediaQuery('large device')} {
    grid-template-columns: 3fr 2fr;
  }
`;

const remedyText = css`
  color: #abaeaf;
  font-size: 1rem;
  padding-right: 1rem; // to avoid overlap with scroll bar on mobile
`;

const deviceImgContainer = css`
  display: none;
  ${dsmMediaQuery('large device')} {
    position: fixed;
    left: 50%;
    transform: translateX(25%);

    display: grid;
    grid-area: device-img;
    justify-self: center;
    padding: 1rem 0;
  }
`;

const deviceImage = css`
  width: 75%;
`;

const listStyles = css`
  ul {
    color: #abaeaf;
    list-style-type: circle;
    list-style-position: inside;
  }
`;

export { remedyText, bodyContainer, deviceImage, deviceImgContainer, remedyTextContainer, listStyles };
