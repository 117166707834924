import { css } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const profileStyles = css`
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
  gap: 1rem;
  height: 100%;
`;

const formStyles = css`
  grid-column: 1/-1;
  display: grid;
  gap: 1rem;
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas:
    'header'
    'tiles'
    'buttons';
  ${dsmMediaQuery('medium device')} {
    grid-template-areas:
      'header header'
      'tiles tiles'
      '. buttons';
  }
  ${dsmMediaQuery('large device')} {
    grid-template-areas:
      'header header'
      'tiles .'
      '. buttons';
  }
`;

const buttonStyles = css`
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${dsmMediaQuery('medium device')} {
    flex-direction: unset;
    justify-content: end;
  }
`;

const dropDownStyles = css`
  padding-top: 1rem;
  select {
    padding: 0.5rem;
  }
`;

const headerStyles = css`
  grid-area: header;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  h1 {
    font-size: 2rem;
    font-weight: 400;
  }
`;

export { profileStyles, formStyles, buttonStyles, dropDownStyles, headerStyles };
