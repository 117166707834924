import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoItem } from '../../../../../components';
import { ControlModeIcon } from '../../../../../components/Icons';
import { gridPlacement } from './styles';
import { ControlModeProps } from './types';

const ControlMode: React.FC<ControlModeProps> = ({ controlMode, setpoint }) => {
  const { t } = useTranslation();

  switch (controlMode) {
    case 'PULSE':
      return (
        <>
          <InfoItem
            css={gridPlacement('controlMode')}
            Icon={<ControlModeIcon mode={controlMode} />}
            label={t('WP.OVERVIEW.CONTROL_MODE')}
            value={t(`WP.OVERVIEW.${controlMode}`)}
          />
          <InfoItem
            css={gridPlacement('setpoint')}
            label={t('WP.OVERVIEW.ML_PER_PULSE').toLowerCase()}
            value={setpoint?.value}
          />
        </>
      );
    case 'MANUAL':
      return (
        <>
          <InfoItem
            css={gridPlacement('controlMode')}
            Icon={<ControlModeIcon mode={controlMode} />}
            label={t('WP.OVERVIEW.CONTROL_MODE')}
            value={t(`WP.OVERVIEW.${controlMode}`)}
          />
          <InfoItem
            css={gridPlacement('setpoint')}
            label={t('WP.OVERVIEW.SETPOINT')}
            value={setpoint?.value?.toFixed(4)}
            unit={setpoint?.unit}
          />
        </>
      );
    default:
      return (
        <InfoItem
          css={gridPlacement('controlMode')}
          label={t('WP.OVERVIEW.CONTROL_MODE')}
          value={`${t(`WP.OVERVIEW.${controlMode}`)} mode ${t(`WP.COMMON.NOT_SUPPORTED`).toLowerCase()}`}
        />
      );
  }
};

export default ControlMode;
