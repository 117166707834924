/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import { labelStyles, errorStyles, textInputStyles, containerStyles, hideDefaultArrowsStyles } from './styles';

interface TextInputFieldProps {
  label: string | JSX.Element;
  className?: string;
  isVertical?: boolean;
  step?: string;
}

const TextInputField: React.FC<FieldHookConfig<string> & TextInputFieldProps> = (props) => {
  const [field, meta] = useField(props);
  const { name, placeholder, label, className, type, step = 'any', isVertical = false } = props;

  return (
    <div className={className} css={containerStyles(isVertical)}>
      <label css={labelStyles} htmlFor={name}>
        {label}
      </label>
      <input
        {...field}
        placeholder={placeholder}
        type={type}
        step={step}
        css={[textInputStyles, hideDefaultArrowsStyles]}
      />
      {meta.touched && meta.error ? <div css={errorStyles}>{meta.error}</div> : null}
    </div>
  );
};

export default TextInputField;
