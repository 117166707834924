import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../utils/media-queries/media-queries';

const footerStyles = (borderColor: string): SerializedStyles => css`
  display: grid;
  grid-template-areas: 'service-phone' 'asset-btn';
  grid-row-gap: 1rem;
  padding: 1rem 0;
  border-top: 0.125rem solid ${borderColor};
  align-items: center;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column: 1/-1;
  background: rgba(34, 37, 39, 0.7); /*Make sure this color has an opacity of less than 1*/
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(34, 37, 39, 0.9) 15%,
    rgba(34, 37, 39, 0.9) 85%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 4000; // to prevent device img in overlapping footer in Firefox
  ${dsmMediaQuery('large device')} {
    grid-row-gap: 2.2rem;
    padding: 2rem 0;
    grid-template-areas: 'service-phone asset-btn';
  }
`;

const textStyles = css`
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
`;

const servicePhoneStyles = css`
  grid-area: service-phone;
  color: #33b1e8;
  justify-self: center;
  ${dsmMediaQuery('large device')} {
    justify-self: start;
  }
`;

const assetBtnStyles = (theme: Theme): SerializedStyles => css`
  cursor: pointer;
  background-color: ${theme.colors.button.primary.background};
  grid-area: asset-btn;
  text-decoration: none;
  border: none;
  outline: none;
  padding: 1rem;
  text-align: center;
  color: ${theme.colors.button.primary.text};
  ${dsmMediaQuery('large device')} {
    justify-self: end;
  }
`;

const assetBtnDisabledStyles = css`
  pointer-events: none;
  background-color: #ccc;
`;

export { assetBtnStyles, servicePhoneStyles, textStyles, footerStyles, assetBtnDisabledStyles };
