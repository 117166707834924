export const applyColor = (color?: number | null): string => {
  switch (color) {
    case 0:
      return '#F8F8F8';
    case 1:
      return '#6EB456';
    case 2:
      return '#F6AA33';
    case 3:
      return '#E33353';
    default:
      return 'transparent';
  }
};

export const applyControlMode = (mode?: number | null): 'MANUAL' | 'PULSE' | 'BATCH' | 'ANALOG' | undefined => {
  switch (mode) {
    case 0:
      return 'MANUAL';
    case 1:
      return 'PULSE';
    case 2:
      return 'ANALOG';
    case 3:
    case 4:
      return 'BATCH'; // Timer and Batch is almost identical at least for this scenario
    default:
      return undefined;
  }
};

export const applyStopControlState = (
  state?: number | null,
):
  | 'DISPLAY'
  | 'EXTERNAL'
  | 'DISPLAY_AND_EXTERNAL'
  | 'REMOTE'
  | 'DISPLAY_AND_BUS'
  | 'EXTERNAL_AND_BUS'
  | 'DISPLAY_EXTERNAL_AND_BUS'
  | 'RUNNING'
  | undefined => {
  switch (state) {
    case 0:
      return undefined; // running
    case 1:
      return 'DISPLAY'; // Display = HMI, HMI stop
    case 2:
      return 'EXTERNAL';
    case 3:
      return 'DISPLAY_AND_EXTERNAL'; // stopped by both external & HMI
    case 4:
      return 'REMOTE';
    case 5:
      return 'DISPLAY_AND_BUS';
    case 6:
      return 'EXTERNAL_AND_BUS';
    case 7:
      return 'DISPLAY_EXTERNAL_AND_BUS';
    default:
      return undefined;
  }
};

const displayMode: { [key: number]: 'Active' | 'Stopped' | 'Standby' | 'Service' | 'Calibrating' | 'Deaerating' } = {
  0: 'Active',
  1: 'Stopped',
  2: 'Standby',
  3: 'Service',
  4: 'Calibrating',
  5: 'Deaerating',
};

export const applyDisplayMode = (
  mode: number,
): 'Active' | 'Stopped' | 'Standby' | 'Service' | 'Calibrating' | 'Deaerating' => displayMode[mode];

export const pumpRunning = (pumpState?: number | null): 'YES' | 'NO' | void => {
  if (pumpState === 0) return 'NO';
  if (pumpState === 1) return 'YES';
};

/*
    0 = GENIbus control disabled
    1 = GENIbus control enabled
*/
export const applyRemoteControl = (source?: number | null) => {
  if (source === 0) return 'OFF';
  if (source === 1) return 'ON';
};
