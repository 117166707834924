import { Global, ThemeProvider } from '@emotion/react';
import { AuthProvider } from '@gic/react-auth';
import { UserManagerSettings } from 'oidc-client';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Environment } from 'relay-runtime';
import { RelayEnvironmentProvider } from 'relay-hooks';
import { BrowserRouter } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import BrowserSupport from '../BrowserSupport/BrowserSupport';
import environment from '../../relay/createRelayEnvironment';
import Routes from '../../routes/Routes';
import theme from '../../themes/dark';
import sentryCaptureError from '../../utils/sentry/sentry-capture-error';
import { ErrorFallback, Loader } from '..';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { globalStyles } from './styles';
import RootContext from './RootContext';
import setInitialDataLayer from '../../adobe-launch/set-initial-data-layer';

const userManagerSettings: UserManagerSettings = {
  authority: process.env.GIC_UI_AUTH_AUTHORITY,
  automaticSilentRenew: process.env.GIC_UI_AUTH_AUTOMATIC_SILENT_RENEW === 'true',
  client_id: process.env.GIC_UI_AUTH_CLIENT_ID,
  loadUserInfo: process.env.GIC_UI_AUTH_LOAD_USER_INFO === 'true',
  post_logout_redirect_uri: process.env.GIC_UI_AUTH_POST_LOGOUT_REDIRECT_URI,
  redirect_uri: process.env.GIC_UI_AUTH_REDIRECT_URI,
  response_type: process.env.GIC_UI_AUTH_RESPONSE_TYPE,
  scope: process.env.GIC_UI_AUTH_SCOPE,
  silent_redirect_uri: process.env.GIC_UI_AUTH_SILENT_REDIRECT_URI,
};

const Root: React.FC<{}> = () => {
  useEffect(() => {
    setInitialDataLayer();
  }, []);

  const [relayEnvironment, setRelayEnvironment] = useState<Environment>(environment());
  const context = useMemo(() => ({ relayEnvironment, setRelayEnvironment }), [relayEnvironment]);

  const { baseColor, highlightColor } = theme.colors.skeletonLoader;

  return (
    <RootContext.Provider value={context}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
          <BrowserSupport>
            <AuthProvider
              userManagerSettings={userManagerSettings}
              postSignoutRedirectCallbackUri={process.env.GIC_UI_APP_URL}
            >
              <BrowserRouter>
                <RelayEnvironmentProvider environment={relayEnvironment}>
                  <ErrorBoundary
                    onError={(error): void => sentryCaptureError(error, 'Root')}
                    FallbackComponent={ErrorFallback}
                  >
                    <Suspense fallback={<Loader />}>
                      <Routes />
                    </Suspense>
                  </ErrorBoundary>
                </RelayEnvironmentProvider>
              </BrowserRouter>
            </AuthProvider>
          </BrowserSupport>
        </SkeletonTheme>
      </ThemeProvider>
    </RootContext.Provider>
  );
};

export default Root;
