import React from 'react';
import { accountInstallationStyles, containerStyles, lastSyncStyles } from './styles';
import { headingLargeStyles, headingMediumStyles, paragraphBrightStyles } from '../../../styles';

interface DeviceHeaderProps {
  customerName?: string | null;
  installationName?: string;
  label: string;
  LastSync: JSX.Element;
}

const DevicePageHeader = ({ customerName, label, installationName, LastSync }: DeviceHeaderProps) => (
  <div css={containerStyles}>
    <div css={accountInstallationStyles}>
      <span css={headingLargeStyles}>{customerName}</span>
      <span css={headingMediumStyles}>{installationName}</span>
    </div>
    <div css={[lastSyncStyles, paragraphBrightStyles]}>
      <span>{label}:</span>
      <span> {LastSync}</span>
    </div>
  </div>
);

export default DevicePageHeader;
