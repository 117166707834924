/**
 * @generated SignedSource<<01108bc117366e3402887b16b28f939d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LocaleProviderQuery$variables = {};
export type LocaleProviderQuery$data = {
  readonly me: {
    readonly countryName: string | null;
    readonly languageIso6391: string | null;
    readonly countryIso31662: string | null;
    readonly timeFormatName: string | null;
    readonly shortDateFormatName: string | null;
    readonly unitSystemName: string | null;
  } | null;
};
export type LocaleProviderQuery = {
  variables: LocaleProviderQuery$variables;
  response: LocaleProviderQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageIso6391",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryIso31662",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeFormatName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDateFormatName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSystemName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocaleProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LocaleProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "891330138935d63eee098dd37a9bd10c",
    "id": null,
    "metadata": {},
    "name": "LocaleProviderQuery",
    "operationKind": "query",
    "text": "query LocaleProviderQuery {\n  me {\n    countryName\n    languageIso6391\n    countryIso31662\n    timeFormatName\n    shortDateFormatName\n    unitSystemName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2caf8824d4fff3be235ebbfc639b704e";

export default node;
