/**
 * @generated SignedSource<<b47e2bf63cdff7b09b800ee7af725397>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PumpStatusData_device$data = {
  readonly latestSync: {
    readonly outOfSync: boolean | null;
  } | null;
  readonly pumpStatusDataLatestDeviceData: {
    readonly dataPoints: ReadonlyArray<{
      readonly value: number | null;
      readonly name: string | null;
      readonly unit: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "PumpStatusData_device";
};
export type PumpStatusData_device$key = {
  readonly " $data"?: PumpStatusData_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"PumpStatusData_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PumpStatusData_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LatestSync",
      "kind": "LinkedField",
      "name": "latestSync",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outOfSync",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "pumpStatusDataLatestDeviceData",
      "args": null,
      "concreteType": "LatestDeviceData",
      "kind": "LinkedField",
      "name": "latestDeviceData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceData",
          "kind": "LinkedField",
          "name": "dataPoints",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "828539502f10f1d0625729f8bdddc500";

export default node;
