import { UnitSystemName } from '../../../providers/LocaleProvider/types';
import {
  DelayValues,
  FlowSensitivityValues,
  FormValues,
  Label,
  SlowModeValues,
  GeniValue,
  SupportedGeniValueName,
} from './types';

export const getLabelFromValue = (value: string | number | undefined | null, options: Label[]) =>
  options.find((opt) => opt.value === value?.toString())?.label;

export const sensitivityOptions: Label[] = [
  { label: 'LOW', value: FlowSensitivityValues.LOW },
  { label: 'MEDIUM', value: FlowSensitivityValues.MEDIUM },
  { label: 'HIGH', value: FlowSensitivityValues.HIGH },
];

export const delayOptions: Label[] = [
  { label: 'SHORT', value: DelayValues.SHORT },
  { label: 'MEDIUM', value: DelayValues.MEDIUM },
  { label: 'LONG', value: DelayValues.LONG },
];

export const slowModeOptions: Label[] = [
  {
    label: 'OFF',
    value: SlowModeValues.OFF,
  },
  {
    label: '25',
    value: SlowModeValues.QUARTER,
  },
  {
    label: '50',
    value: SlowModeValues.HALF,
  },
];

export const getFlowControlDelay = (value: number | undefined | null): DelayValues => {
  switch (value) {
    case 0:
      return DelayValues.SHORT;
    case 1:
      return DelayValues.MEDIUM;
    case 2:
      return DelayValues.LONG;
    default:
      return DelayValues.SHORT;
  }
};

export const getFlowControlSensitivity = (value: number | undefined | null): FlowSensitivityValues => {
  switch (value) {
    case 0:
      return FlowSensitivityValues.LOW;
    case 1:
      return FlowSensitivityValues.MEDIUM;
    case 2:
      return FlowSensitivityValues.HIGH;
    default:
      return FlowSensitivityValues.LOW;
  }
};

export const getSlowModeValueFromLabel = (label: string): number => {
  switch (label) {
    case SlowModeValues.OFF:
      return 0;
    case SlowModeValues.HALF:
      return 1;
    case SlowModeValues.QUARTER:
      return 3;
    default:
      return 0;
  }
};

export const getSlowModeValue = (value: number | undefined): SlowModeValues => {
  switch (value) {
    case 0:
      return SlowModeValues.OFF;
    case 1:
      return SlowModeValues.HALF;
    case 3:
      return SlowModeValues.QUARTER;
    default:
      return SlowModeValues.OFF;
  }
};

export const operationModeOptions: Label[] = [
  {
    label: 'START',
    value: SupportedGeniValueName.START,
  },
  {
    label: 'STOP',
    value: SupportedGeniValueName.STOP,
  },
];

export const controlModeOptions: Label[] = [
  {
    label: 'PULSE',
    value: SupportedGeniValueName.PULSE,
  },
  {
    label: 'MANUAL',
    value: SupportedGeniValueName.MANUAL,
  },
];

export const getControlModeDefault = (currentMode?: number | null | undefined) => {
  if (currentMode === 0) return SupportedGeniValueName.MANUAL;
  if (currentMode === 1) return SupportedGeniValueName.PULSE;
  if (currentMode === 2) return 'ANALOG';
  if (currentMode === 3) return 'TIMER';
  if (currentMode === 4) return 'BATCH';
  return 'UNKNOWN';
};

export const getControlModeLabel = (value: number | null | undefined) => {
  if (value === 0) return 'MANUAL';
  if (value === 1) return 'PULSE';
  if (value === 2) return 'ANALOG';
  if (value === 3) return 'TIMER';
  if (value === 4) return 'BATCH';
  return 'UNKNOWN';
};

// Currently we only support MANUAL and PULSE mode
export const isUnsupportedControlMode = (controlMode?: number | null) => !(controlMode === 0 || controlMode === 1);

export const getPulseMinMaxValues = (maxFlow: number | undefined) => {
  switch (maxFlow) {
    case 7.5:
      return { min: 0.0015, max: 14.9 };
    case 12:
      return { min: 0.0029, max: 29.0 };
    case 17:
      return { min: 0.0031, max: 31.0 };
    case 30:
      return { min: 0.0062, max: 62.0 };
    default:
      // full range
      return { min: 0.0015, max: 62.0 };
  }
};

interface PumpRange {
  7.5: {
    min: number;
    max: number;
  };
  12: {
    min: number;
    max: number;
  };
  17: {
    min: number;
    max: number;
  };
  30: {
    min: number;
    max: number;
  };
}

const UsPSIRange = {
  7.5: { min: 44, max: 244 },
  12: { min: 44, max: 165 },
  17: { min: 44, max: 115 },
  30: { min: 44, max: 74 },
};

const EuBarRange = {
  7.5: { min: 3, max: 17 },
  12: { min: 3, max: 11 },
  17: { min: 3, max: 8 },
  30: { min: 3, max: 5 },
};

// max value is the base max when slow mode is disabled
const USManualFlowRange = {
  7.5: { min: 0.0007, max: 2 },
  12: { min: 0.0031, max: 3.1 },
  17: { min: 0.0045, max: 4.5 },
  30: { min: 0.008, max: 8.0 },
};

// max value is the base max when slow mode is disabled
const metricManualFlowRange = {
  7.5: { min: 0.0025, max: 7.5 },
  12: { min: 0.012, max: 12.0 },
  17: { min: 0.017, max: 17.0 },
  30: { min: 0.03, max: 30.0 },
};

const calcPercentage = (num: number, percentage: number) => num * (percentage / 100);
// The rounding for max range is not consistent so in certain cases we need to round differently
const roundToNearestInSomeCases = (num: number) => {
  if (num === 1.875 || num === 1.125) {
    return Math.ceil(num * 100) / 100;
  }
  return num;
};
/*
    Here we calculate the max range based on the pump's slow mode setting(anti_cavitation_setup)
    // 0 = DISABLED, suction stroke velocity = 100% of base max
    // 1 = ENABLED, suction stroke velocity = 50% of base max
    // 3 = ENABLED, suction stroke velocity = 25% of base max
*/
const applyMaxRange = (maxBaseValue: number, antiCavitationSetup: number | undefined | null) => {
  switch (antiCavitationSetup) {
    case 0:
      return calcPercentage(maxBaseValue, 100);
    case 1:
      return calcPercentage(maxBaseValue, 50);
    case 3:
      return roundToNearestInSomeCases(calcPercentage(maxBaseValue, 25));
    default:
      return calcPercentage(maxBaseValue, 100);
  }
};

const isUSUnit = (unitSystem: UnitSystemName) => unitSystem === 'US Unit';

const getPumpMinMaxValues = (
  usRange: PumpRange,
  metricRange: PumpRange,
  maxFlow: number | undefined,
  unitSystem: UnitSystemName,
  actualValue: number | null | undefined,
) => {
  switch (maxFlow) {
    case 7.5:
      return {
        min: isUSUnit(unitSystem) ? usRange['7.5'].min : metricRange['7.5'].min,
        max: isUSUnit(unitSystem)
          ? applyMaxRange(usRange['7.5'].max, actualValue)
          : applyMaxRange(metricRange['7.5'].max, actualValue),
      };
    case 12:
      return {
        min: isUSUnit(unitSystem) ? usRange['12'].min : metricRange['12'].min,
        max: isUSUnit(unitSystem)
          ? applyMaxRange(usRange['12'].max, actualValue)
          : applyMaxRange(metricRange['12'].max, actualValue),
      };
    case 17:
      return {
        min: isUSUnit(unitSystem) ? usRange['17'].min : metricRange['17'].min,
        max: isUSUnit(unitSystem)
          ? applyMaxRange(usRange['17'].max, actualValue)
          : applyMaxRange(metricRange['17'].max, actualValue),
      };
    case 30:
      return {
        min: isUSUnit(unitSystem) ? usRange['30'].min : metricRange['30'].min,
        max: isUSUnit(unitSystem)
          ? applyMaxRange(usRange['30'].max, actualValue)
          : applyMaxRange(metricRange['30'].max, actualValue),
      };
    default:
      throw new Error(`[getPumpMinMaxValues] Current maxflow '${maxFlow}' is not supported.`);
  }
};

export const getPressureMinMaxValues = (
  maxFlow: number | undefined,
  unitSystem: UnitSystemName,
  pressure: number | null | undefined,
): {
  min: number;
  max: number;
} => getPumpMinMaxValues(UsPSIRange, EuBarRange, maxFlow, unitSystem, pressure);

export const getManualMinMaxValues = (
  maxFlow: number | undefined,
  unitSystem: UnitSystemName,
  antiCavitaionSetup: number | null | undefined,
): {
  min: number;
  max: number;
} => getPumpMinMaxValues(USManualFlowRange, metricManualFlowRange, maxFlow, unitSystem, antiCavitaionSetup);

export const removeUnchangedValues = (initialValues: FormValues, formValues: FormValues): Partial<FormValues> => {
  const changedValues = Object.entries(formValues).reduce((acc, [key, val]) => {
    if (val !== initialValues[key as keyof FormValues]) {
      return { ...acc, [key]: val };
    }
    return acc;
  }, {});

  return changedValues;
};

// GeniWrite Service expects boolean values to be expressed as '0' and '1'
export const convertBoolean = (val: string | boolean) => (val.toString() === 'true' ? '1' : '0');

const buildSlowModeGeniValue = (value: string): string => {
  const slowModeSetting = {
    viscosity: 0,
    antiCavitation: 0,
  };
  switch (value) {
    case SlowModeValues.HALF:
      slowModeSetting.antiCavitation = 1;
      slowModeSetting.viscosity = 0;
      break;
    case SlowModeValues.QUARTER:
      slowModeSetting.antiCavitation = 1;
      slowModeSetting.viscosity = 1;
      break;
    case SlowModeValues.OFF:
      slowModeSetting.antiCavitation = 0;
      slowModeSetting.viscosity = 0;
      break;
    default:
      break;
  }
  return JSON.stringify(slowModeSetting);
};

export const mapGeniValToFormVal = (dataPointName: string, formValues: FormValues): Partial<FormValues> => {
  const {
    autoDeaeration,
    operationMode,
    slowMode,
    controlMode,
    pulseDosingVolume,
    manualDosingVolume,
    pulseMemory,
    flowControl,
    delay,
    sensitivity,
    autoFlowAdapt,
    maxPressureAlarm,
    minPressureAsAlarm,
  } = formValues;

  switch (dataPointName) {
    case SupportedGeniValueName.START:
    case SupportedGeniValueName.STOP:
      return { operationMode };
    case SupportedGeniValueName.PULSE:
    case SupportedGeniValueName.MANUAL:
      return { controlMode };
    case SupportedGeniValueName.SLOW_MODE:
      return { slowMode };
    case SupportedGeniValueName.AUTO_DEAERATION:
      return { autoDeaeration };
    case SupportedGeniValueName.MAX_PRESSURE_ALARM:
      return { maxPressureAlarm };
    case SupportedGeniValueName.PULSE_DOSING_VOLUME:
      return { pulseDosingVolume };
    case SupportedGeniValueName.MANUAL_DOSING_VOLUME:
      return { manualDosingVolume };
    case SupportedGeniValueName.PULSE_MEMORY:
      return { pulseMemory };
    case SupportedGeniValueName.FLOW_CONTROL:
      return { flowControl };
    case SupportedGeniValueName.FLOW_CONTROL_SETUP:
      // TODO: This could be wrong. Some of these fields might be okay / no longer have initial values
      return { delay, sensitivity, autoFlowAdapt, minPressureAsAlarm };
    default:
      return {};
  }
};

export const buildGeniValue = (name: string, value: string, unitSystem: UnitSystemName): GeniValue | undefined => {
  switch (name) {
    case 'slowMode':
      return {
        name: SupportedGeniValueName.SLOW_MODE,
        value: buildSlowModeGeniValue(value),
        unit: '',
      };
    case 'maxPressureAlarm':
      return {
        name: `${SupportedGeniValueName.MAX_PRESSURE_ALARM}`,
        value: `${value}`,
        unit: `${isUSUnit(unitSystem) ? 'psi' : 'bar'}`,
      };
    case 'controlMode':
    case 'operationMode':
      return { name: `${value}`, value: '', unit: '' };
    case 'pulseDosingVolume':
      return { name: `${SupportedGeniValueName.PULSE_DOSING_VOLUME}`, value: `${value}`, unit: 'ml' };
    case 'manualDosingVolume':
      return {
        name: `${SupportedGeniValueName.MANUAL_DOSING_VOLUME}`,
        value: `${value}`,
        unit: `${isUSUnit(unitSystem) ? 'gal/h' : 'l/h'}`,
      };
    case 'pulseMemory':
      return {
        name: `${SupportedGeniValueName.PULSE_MEMORY}`,
        value: `${convertBoolean(value)}`,
        unit: '',
      };
    case 'flowControl':
      return {
        name: `${SupportedGeniValueName.FLOW_CONTROL}`,
        value: `${convertBoolean(value)}`,
        unit: '',
      };
    case 'autoDeaeration':
      return {
        name: `${SupportedGeniValueName.AUTO_DEAERATION}`,
        value: `${convertBoolean(value)}`,
        unit: '',
      };
    default:
  }
};

export const isNullOrUndefined = (val: any) => val === null || val === undefined;

export const getGeniValues = (formValuesToSend: Partial<FormValues>, unitSystem: UnitSystemName): GeniValue[] => {
  const values: { name: string; value: string; unit: string }[] = [];
  if (!Object.keys(formValuesToSend).length) return [];
  Object.entries(formValuesToSend).forEach(([key, val]) => {
    const geniValue = buildGeniValue(key, val.toString(), unitSystem);
    if (geniValue) values.push(geniValue);
  });

  return values;
};

export const formValuesHasFlowControl = (formValuesToSend: Partial<FormValues>): boolean =>
  'autoFlowAdapt' in formValuesToSend ||
  'delay' in formValuesToSend ||
  'sensitivity' in formValuesToSend ||
  'minPressureAsAlarm' in formValuesToSend;

export const removeFlowControlValues = (formValuesToSend: Partial<FormValues>): Partial<FormValues> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { delay, sensitivity, autoFlowAdapt, minPressureAsAlarm, ...rest } = formValuesToSend;
  return rest;
};

export const createFlowControlGeniValue = (formValues: FormValues) => {
  const { delay, sensitivity, autoFlowAdapt, minPressureAsAlarm } = formValues;
  const geniValue: GeniValue = {
    value: JSON.stringify({
      delay,
      sensitivity,
      automaticCapacity: `${convertBoolean(autoFlowAdapt)}`,
      lowPressureAlarm: `${convertBoolean(minPressureAsAlarm)}`,
    }),
    name: 'gfdm:FLOW_MONITORING_SETUP',
    unit: '',
  };
  return geniValue;
};

/*
    0 = GENIbus control disabled
    1 = GENIbus control enabled
*/
export const remoteControlEnabled = (source?: number | null) => source === 1;
