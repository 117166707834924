import { css, Theme } from '@emotion/react';

const containerStyles = (theme: Theme, noBorder = false) => css`
  display: grid;
  grid-template-columns: 1fr max-content max-content max-content;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-bottom: ${noBorder ? '0' : `0.063rem solid${theme.colors.border.subtle}`};
`;

const textTruncateStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 0.5rem;
`;

const iconValueUnitGroupStyles = (addGap: boolean) => css`
  display: flex;

  align-items: center;
  gap: ${addGap && '0.5rem'};
`;

export { containerStyles, textTruncateStyles, iconValueUnitGroupStyles };
