/**
 * @generated SignedSource<<8c57ff2ca027ee37f94d909c78ab6d6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnitSystem = "EU" | "US";
export type UpdateUserSettingsInput = {
  unitSystem?: UnitSystem | null;
};
export type ProfileUpdateUserSettingsMutation$variables = {
  input?: UpdateUserSettingsInput | null;
};
export type ProfileUpdateUserSettingsMutation$data = {
  readonly updateUserSettings: {
    readonly id: string;
    readonly user: {
      readonly unitSystemName: string | null;
    } | null;
  } | null;
};
export type ProfileUpdateUserSettingsMutation = {
  variables: ProfileUpdateUserSettingsMutation$variables;
  response: ProfileUpdateUserSettingsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSystemName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileUpdateUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserSettingsPayload",
        "kind": "LinkedField",
        "name": "updateUserSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileUpdateUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserSettingsPayload",
        "kind": "LinkedField",
        "name": "updateUserSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9815587570d295611a81f241ea486025",
    "id": null,
    "metadata": {},
    "name": "ProfileUpdateUserSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileUpdateUserSettingsMutation(\n  $input: UpdateUserSettingsInput\n) {\n  updateUserSettings(input: $input) {\n    id\n    user {\n      unitSystemName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "598e430835403e041af8a2881d702ce9";

export default node;
