import { css, Theme } from '@emotion/react';
import { Margin } from './types';

const dividerStyles = (theme: Theme, marginRight?: Margin, marginLeft?: Margin) => css`
  border: unset;
  border-top: 0.06rem solid ${theme.colors.border.subtle};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: ${marginLeft === 'large' ? '2rem' : '0rem'};
  margin-right: ${marginRight === 'small' && '-1rem'};
  margin-right: ${marginRight === 'large' && '-2rem'};
`;

// eslint-disable-next-line import/prefer-default-export
export { dividerStyles };
