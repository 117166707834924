import React, { PropsWithChildren, Suspense } from 'react';
import { createPortal } from 'react-dom';
import Loader from '../Loader/Loader';
import { showStyle, hideStyle } from './styles';
import { ModalProps } from './types';

const modalRoot = document.getElementById('modal');

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({ children, show, styles }) => {
  if (!show) return null;

  // Portals allow rendering of an element from anywhere in the virtual DOM tree to a specific location in the actual DOM.
  return createPortal(
    <div css={[showStyle, styles]}>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </div>,
    modalRoot as HTMLInputElement,
  );
};

export default Modal;
