import React from 'react';
import pulseModeIcon from '../../../assets/images/pulseModeIcon.svg';
import manualModeIcon from '../../../assets/images/handOutline.svg';

const ControlModeIcon = ({ mode }: { mode?: 'PULSE' | 'MANUAL' | 'BATCH' | 'ANALOG' }) => {
  if (mode === 'PULSE') return <img height="16" src={pulseModeIcon} alt="icon" />;
  if (mode === 'MANUAL') return <img height="16" src={manualModeIcon} alt="icon" />;
  return null;
};

export default ControlModeIcon;
