import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Button, DropDownSelect, TextInputField, TogglerFormik, Divider } from '../../../components';
import {
  buttonContainerStyles,
  containerStyles,
  flowControlContainer,
  remoteContainerContentStyles,
  remoteContainerStyles,
  remoteHeader,
  textInputStyles,
} from './styles';
import GetProgressModal from './GetProgressModal';
import {
  controlModeOptions,
  createFlowControlGeniValue,
  formValuesHasFlowControl,
  getFlowControlDelay,
  getFlowControlSensitivity,
  getGeniValues,
  getManualMinMaxValues,
  getPressureMinMaxValues,
  getPulseMinMaxValues,
  getSlowModeValue,
  getSlowModeValueFromLabel,
  isUnsupportedControlMode,
  operationModeOptions,
  removeFlowControlValues,
  slowModeOptions,
} from './utils';
import { FormValues, GeniValue, SupportedGeniValueName } from './types';
import { LocaleContext } from '../../../providers/LocaleProvider/LocaleProvider';
import FlowControl from './FlowControl/FlowControl';
import { PumpVariant } from './__generated__/RemoteControlQuery.graphql';
import RemoteHeader from './RemoteHeader';
import RemoteSection from './RemoteSection';
import RemoteControlDisabledSectionHeader from './RemoteControlDisabledSectionHeader';

const RemoteControlForm: React.FC<{
  maxFlow: number | undefined;
  currentSlowModeSetting: number | undefined;
  currentManualDosingFlowUnit: string | undefined;
  currentPumpState: number | undefined;
  currentControlMode: string;
  currentControlModeValue: number | undefined;
  currentMlPerPulse: string;
  currentManualDosingFlowValue: string;
  currentPulseMemory: boolean;
  currentAutoDeaeration: boolean;
  currentFlowControl: boolean;
  currentFlowControlAutoFlowAdapt: boolean;
  currentFlowControlMinPressure: boolean;
  currentMaxPressureAlarm: number | undefined;
  currentFlowControlDelay: number | undefined;
  currentFlowControlSensitivity: number | undefined;
  maxPressureAlamUnit: string | undefined;
  onFormSubmit: (geniValues: GeniValue[]) => void;
  runningInstanceId: string;
  onUpdate: () => void;
  isLoading: boolean;
  isStoppedByOtherThanRemote: boolean;
  pumpVariant: string | undefined;
  Message?: JSX.Element;
}> = ({
  maxFlow,
  currentSlowModeSetting,
  currentManualDosingFlowUnit,
  currentPumpState,
  currentControlMode,
  currentControlModeValue,
  currentMlPerPulse,
  currentManualDosingFlowValue,
  currentPulseMemory,
  currentAutoDeaeration,
  currentFlowControlAutoFlowAdapt,
  currentFlowControlMinPressure,
  currentMaxPressureAlarm,
  currentFlowControlDelay,
  currentFlowControlSensitivity,
  maxPressureAlamUnit,
  runningInstanceId,
  onFormSubmit,
  onUpdate,
  isLoading = false,
  isStoppedByOtherThanRemote = false,
  pumpVariant,
  currentFlowControl,
  Message,
}) => {
  const { t } = useTranslation();
  const { unitSystemName } = useContext(LocaleContext);
  const [disabled, setDisabled] = useState(false);
  const disableSubmitButton = (val: boolean) => setDisabled(val);

  const RemoteSchema = React.useMemo(
    () =>
      Yup.lazy((values: FormValues) =>
        Yup.object().shape({
          flowControl: Yup.boolean(),
          operationMode: Yup.string().required(t('WP.COMMON.VALIDATION.REQUIRED')),
          slowMode: Yup.string().required(t('WP.COMMON.VALIDATION.REQUIRED')),
          controlMode: Yup.string().required(t('WP.COMMON.VALIDATION.REQUIRED')),
          // If flowControl is true, then we  have validation on maxPressureAlarm. Otherwise no validation
          maxPressureAlarm: Yup.number().when('flowControl', {
            is: true,
            then: Yup.number()
              .typeError(t('WP.COMMON.VALIDATION.NUMBERS_ONLY'))
              .required(t('WP.COMMON.VALIDATION.REQUIRED'))
              .min(
                getPressureMinMaxValues(maxFlow, unitSystemName, Number(values.maxPressureAlarm))?.min,
                `${t('WP.REMOTE.VALIDATION.VALUE_TOO_LOW')}. ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_PREFIX')} ${
                  getPressureMinMaxValues(maxFlow, unitSystemName, Number(values.maxPressureAlarm)).min
                } - ${
                  getPressureMinMaxValues(maxFlow, unitSystemName, Number(values.maxPressureAlarm)).max
                } ${maxPressureAlamUnit}`,
              )
              .max(
                getPressureMinMaxValues(maxFlow, unitSystemName, Number(values.maxPressureAlarm)).max,
                `${t('WP.REMOTE.VALIDATION.VALUE_TOO_HIGH')}. ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_PREFIX')} ${
                  getPressureMinMaxValues(maxFlow, unitSystemName, Number(values.maxPressureAlarm)).min
                } - ${
                  getPressureMinMaxValues(maxFlow, unitSystemName, Number(values.maxPressureAlarm)).max
                } ${maxPressureAlamUnit} `,
              ),
            // No validation
            otherwise: undefined,
          }),
          pulseDosingVolume: Yup.number().when('controlMode', {
            is: (val: string) => val === SupportedGeniValueName.PULSE,
            then: Yup.number()
              .typeError(t('WP.COMMON.VALIDATION.NUMBERS_ONLY'))
              .required(t('WP.COMMON.VALIDATION.REQUIRED'))
              .min(
                getPulseMinMaxValues(maxFlow).min,
                `${t('WP.REMOTE.VALIDATION.VALUE_TOO_LOW')}. ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_PREFIX')} ${
                  getPulseMinMaxValues(maxFlow).min
                } - ${getPulseMinMaxValues(maxFlow).max} ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_POSTFIX')}`,
              )
              .max(
                getPulseMinMaxValues(maxFlow).max,
                `${t('WP.REMOTE.VALIDATION.VALUE_TOO_HIGH')}. ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_PREFIX')} ${
                  getPulseMinMaxValues(maxFlow).min
                } - ${getPulseMinMaxValues(maxFlow).max} ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_POSTFIX')} `,
              ),
            // no validation if PULSE mode is not selected
            otherwise: undefined,
          }),
          manualDosingVolume: Yup.number().when('controlMode', {
            is: (val: string) => val === SupportedGeniValueName.MANUAL,
            then: Yup.number()
              .typeError(t('WP.COMMON.VALIDATION.NUMBERS_ONLY'))
              .min(
                getManualMinMaxValues(maxFlow, unitSystemName, getSlowModeValueFromLabel(values.slowMode))?.min,
                `${t('WP.REMOTE.VALIDATION.VALUE_TOO_LOW')}. ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_PREFIX')} ${
                  getManualMinMaxValues(maxFlow, unitSystemName, getSlowModeValueFromLabel(values.slowMode)).min
                } - ${
                  getManualMinMaxValues(maxFlow, unitSystemName, getSlowModeValueFromLabel(values.slowMode)).max
                } ${currentManualDosingFlowUnit}`,
              )
              .max(
                getManualMinMaxValues(maxFlow, unitSystemName, getSlowModeValueFromLabel(values.slowMode)).max,
                `${t('WP.REMOTE.VALIDATION.VALUE_TOO_HIGH')}. ${t('WP.REMOTE.VALIDATION.PULSE_RANGE_PREFIX')} ${
                  getManualMinMaxValues(maxFlow, unitSystemName, getSlowModeValueFromLabel(values.slowMode)).min
                } - ${
                  getManualMinMaxValues(maxFlow, unitSystemName, getSlowModeValueFromLabel(values.slowMode)).max
                } ${currentManualDosingFlowUnit} `,
              ),
            // no validation if MANUAL mode is not selected
            otherwise: undefined,
          }),
        }),
      ),
    [currentManualDosingFlowUnit, maxFlow, maxPressureAlamUnit, t, unitSystemName],
  );
  const initialValues: FormValues = {
    autoDeaeration: currentAutoDeaeration,
    operationMode: `${currentPumpState === 1 ? SupportedGeniValueName.START : SupportedGeniValueName.STOP}`,
    slowMode: `${getSlowModeValue(currentSlowModeSetting)}`,
    controlMode: currentControlMode,
    pulseDosingVolume: currentMlPerPulse,
    manualDosingVolume: currentManualDosingFlowValue,
    maxPressureAlarm: currentMaxPressureAlarm?.toString() || '',
    pulseMemory: currentPulseMemory,
    flowControl: currentFlowControl,
    autoFlowAdapt: currentFlowControlAutoFlowAdapt,
    delay: `${getFlowControlDelay(currentFlowControlDelay)}`,
    sensitivity: `${getFlowControlSensitivity(currentFlowControlSensitivity)}`,
    minPressureAsAlarm: currentFlowControlMinPressure,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={RemoteSchema}
      onSubmit={(values) => {
        let geniValues: GeniValue[] = [];
        let formValuesToSend: Partial<FormValues> = values;
        // Handle special scenario for flow control values,
        // where we have to merge mulitple form fields into one json object as genivalue
        if (formValuesHasFlowControl(formValuesToSend)) {
          formValuesToSend = removeFlowControlValues(formValuesToSend);
          if (values.flowControl) {
            // If flow control has changes and is enabled we build the flow control genivalue
            const flowControlGeniValue: GeniValue = createFlowControlGeniValue(values);
            geniValues = [...geniValues, flowControlGeniValue];
          }
        }
        geniValues = [...geniValues, ...getGeniValues(formValuesToSend, unitSystemName)];
        onFormSubmit(geniValues);
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <GetProgressModal
            key={runningInstanceId}
            runningInstanceId={runningInstanceId}
            update={onUpdate}
            disableFormSubmit={disableSubmitButton}
          />
          <Form css={containerStyles}>
            <div css={remoteContainerStyles}>
              <div css={remoteHeader}>
                {Boolean(Message) && Message}
                <RemoteHeader
                  title={t('WP.REMOTE.REMOTE')}
                  controlMode={currentControlModeValue === 0 ? t(`WP.REMOTE.MANUAL`) : t(`WP.REMOTE.PULSE`)}
                />
              </div>
              <div css={remoteContainerContentStyles}>
                <div>
                  <RemoteSection
                    css={css`
                      padding-bottom: 2rem;
                    `}
                    title={
                      isStoppedByOtherThanRemote ? (
                        <RemoteControlDisabledSectionHeader />
                      ) : (
                        t('WP.REMOTE.OPERATION_MODE')
                      )
                    }
                  >
                    <DropDownSelect
                      disabled={isStoppedByOtherThanRemote}
                      name="operationMode"
                      label={t('WP.REMOTE.DOSING_PUMP')}
                    >
                      {operationModeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {t(`WP.REMOTE.${option.label}`)}
                        </option>
                      ))}
                    </DropDownSelect>
                  </RemoteSection>
                  <RemoteSection title={t('WP.REMOTE.CONTROL_MODE_SETTINGS')}>
                    <DropDownSelect
                      onSelectValue={(fieldName, value) => {
                        if (values.operationMode !== SupportedGeniValueName.STOP) {
                          // We set the operation mode to  SupportedGeniValueName.STOP,
                          // because we want to make sure that we stop the pump when doing control mode changes
                          // this will keep the remote control pump behaviour consistant with physical pump behavour
                          // If the user manually sets the operationMode back to SupportedGeniValueName.START
                          // then the pump will start itself after chaning the control mode
                          setFieldValue('operationMode', SupportedGeniValueName.STOP);
                        }
                        // When changing between MANUAL and PULSE control mode we want to reset any input made by the user in the now hidden input field.
                        // We do this to avoid sending out of range and unintended input values to the pump
                        // now that we have removed input validation from 'hidden' input fields
                        if (value === SupportedGeniValueName.PULSE)
                          setFieldValue('manualDosingVolume', initialValues.manualDosingVolume);
                        if (value === SupportedGeniValueName.MANUAL)
                          setFieldValue('pulseDosingVolume', initialValues.pulseDosingVolume);
                      }}
                      name="controlMode"
                      label={t('WP.REMOTE.CONTROL_MODE')}
                    >
                      {/* We only support MANUAL and PULSE mode currently, however, if the device is in BATCH/TIMER/ANALOG mode,
                    we display that as the current state but do not allow the user to select an unsupported mode
                  */}
                      {isUnsupportedControlMode(currentControlModeValue) && (
                        <>
                          <option disabled key={2} value="ANALOG">
                            {t('WP.REMOTE.ANALOG')}
                          </option>
                          <option disabled key={3} value="TIMER">
                            {t('WP.REMOTE.TIMER')}
                          </option>
                          <option disabled key={4} value="BATCH">
                            {t('WP.REMOTE.BATCH')}
                          </option>
                        </>
                      )}
                      {controlModeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {t(`WP.REMOTE.${option.label}`)}
                        </option>
                      ))}
                    </DropDownSelect>
                    <Divider />

                    <div>
                      {values.controlMode === SupportedGeniValueName.PULSE && (
                        <div
                          css={css`
                            display: flex;
                            flex-direction: column;
                          `}
                        >
                          <TextInputField
                            css={[textInputStyles]}
                            type="number"
                            step="any"
                            name="pulseDosingVolume"
                            label={t('WP.REMOTE.PULSE_DOSING_VOL')}
                          />
                          <Divider />
                          <TogglerFormik
                            label={t('WP.REMOTE.PULSE_MEMORY')}
                            isToggled={currentPulseMemory}
                            name="pulseMemory"
                          />
                        </div>
                      )}
                      {values.controlMode === SupportedGeniValueName.MANUAL && (
                        <TextInputField
                          css={[textInputStyles]}
                          type="number"
                          step="any"
                          name="manualDosingVolume"
                          label={`${t('WP.REMOTE.MANUAL_DOSING_FLOW')} ${
                            currentManualDosingFlowUnit && `(${currentManualDosingFlowUnit})`
                          }`}
                        />
                      )}
                    </div>
                  </RemoteSection>
                </div>
              </div>
              <div css={flowControlContainer}>
                <div>
                  <RemoteSection title={t('WP.REMOTE.FLOW_CONTROL_TITLE')}>
                    <TogglerFormik
                      label={t('WP.REMOTE.AUTO_DEAERATION')}
                      name="autoDeaeration"
                      isToggled={currentAutoDeaeration}
                    />
                    <Divider />
                    <DropDownSelect name="slowMode" label={t('WP.REMOTE.SLOW_MODE')}>
                      {slowModeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {t(`WP.REMOTE.SLOW_MODEL_OPTION_${option.label}`)}
                        </option>
                      ))}
                    </DropDownSelect>
                    <Divider />
                    <FlowControl maxPressureAlamUnit={maxPressureAlamUnit} pumpVariant={pumpVariant as PumpVariant} />
                  </RemoteSection>
                </div>
              </div>
            </div>

            <div css={buttonContainerStyles}>
              <Button type="submit" variant="primary" disabled={isLoading || disabled}>
                {t('WP.REMOTE.SEND')}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default RemoteControlForm;
