import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '../../../components';
import { headingSmallDimmedStyles, paragraphBrightStyles } from '../../../styles';
import { containerStyles, boxStyles, footerStyles, contentStyles } from './styles';
import CsvDownloadButton from '../../../services/CSV/CsvDownloadButton';

const Reports = ({
  deviceTwinId,
  deviceId,
  accountId,
}: {
  deviceTwinId?: string | null;
  deviceId?: string | null;
  accountId: string;
}) => {
  const [selectedReport, setSelectedReport] = useState<'EVENTS' | 'TRENDS'>('TRENDS');
  const { t } = useTranslation();

  return (
    <div css={containerStyles}>
      <div css={boxStyles}>
        <h2 css={headingSmallDimmedStyles}>{t('WP.REPORTS.DOWNLOAD_CSV')}</h2>
        <div css={[paragraphBrightStyles, contentStyles]}>
          <RadioButton
            label={t('WP.REPORTS.TREND_DATA')}
            isSelected={selectedReport === 'TRENDS'}
            id="trend-data"
            onChange={() => setSelectedReport('TRENDS')}
          />
          <RadioButton
            label={t('WP.REPORTS.EVENTS')}
            isSelected={selectedReport === 'EVENTS'}
            id="event-data"
            onChange={() => setSelectedReport('EVENTS')}
          />
        </div>
      </div>
      <div css={footerStyles}>
        <CsvDownloadButton
          twinId={deviceTwinId}
          deviceId={deviceId}
          accountId={accountId}
          report={selectedReport}
          fileName={`report-${selectedReport}.csv`.toLowerCase()}
          Idle={t('WP.REPORTS.DOWNLOAD')}
          Downloading={t('WP.REPORTS.DOWNLOADING')}
        />
      </div>
    </div>
  );
};

export default Reports;
