import React from 'react';
import { useTranslation } from 'react-i18next';
import CountDownBar from '../../../../../components/CountDownBar/CountDownBar';
import { timeToServiceProgressStyles } from './styles';

const TimeToServiceProgress = ({
  motorHours,
  maxValue,
  thresholdValue,
}: {
  motorHours: number;
  maxValue: number;
  thresholdValue: number;
}) => {
  const { t } = useTranslation();

  return (
    <div css={timeToServiceProgressStyles}>
      <CountDownBar maxValue={maxValue} value={motorHours} threshold={{ value: thresholdValue }}>
        <div>{t('WP.OVERVIEW.MOTOR_OPERATING_HOURS_LEFT')}</div>
      </CountDownBar>
    </div>
  );
};
export default TimeToServiceProgress;
