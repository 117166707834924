import { css, SerializedStyles, Theme } from '@emotion/react';

const headerContainer = (theme: Theme): SerializedStyles => css`
  display: flex;
  margin: 1.25rem 0 1.125rem 0;
  h2 {
    color: ${theme.colors.secondaryText};
    font-size: 1.75rem;
    margin-left: 0.5rem;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { headerContainer };
