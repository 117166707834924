/**
 * @generated SignedSource<<c503c4aa8df20a0565cca4a6869d9d01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventState = "ACTIVE" | "CLEARED";
export type EventType = "ALARM" | "WARNING";
export type BlockingEventsSeverityLevelQuery$variables = {
  exclude?: EventState | null;
};
export type BlockingEventsSeverityLevelQuery$data = {
  readonly events: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly type: EventType;
      } | null;
    } | null> | null;
  } | null;
};
export type BlockingEventsSeverityLevelQuery = {
  variables: BlockingEventsSeverityLevelQuery$variables;
  response: BlockingEventsSeverityLevelQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "exclude"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "exclude",
    "variableName": "exclude"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockingEventsSeverityLevelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EventConnection",
        "kind": "LinkedField",
        "name": "events",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlockingEventsSeverityLevelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EventConnection",
        "kind": "LinkedField",
        "name": "events",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e8a60274f25759333297c8cd9437277",
    "id": null,
    "metadata": {},
    "name": "BlockingEventsSeverityLevelQuery",
    "operationKind": "query",
    "text": "query BlockingEventsSeverityLevelQuery(\n  $exclude: EventState\n) {\n  events(exclude: $exclude, first: 10) {\n    edges {\n      node {\n        __typename\n        type\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "17a50b95a21dc53cd2d8bbdc2f1abba1";

export default node;
