import { css } from '@emotion/react';
import { transparentize } from 'polished';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

export const width = 40;
export const offBlack = '#383a3c';
export const white = '#FFFFFF';
export const blue = '#0068b4';
export const fadedWhite = transparentize(0.4, white);
export const darkOffBlack = '#222527';
export const lightWhite = transparentize(0.1, white);
export const blueHover = '#1a77bb';
export const weekdayGrey = '#8B9898';

const outer = css`
  ${dsmMediaQuery('medium device')} {
    position: relative;
  }
`;

const dayPickerContainer = css`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  position: absolute;
  width: 100%;
  left: 0;
  top: 3.25rem;
  z-index: 2001;
  background-color: ${offBlack};
  color: ${fadedWhite};

  ${dsmMediaQuery('medium device')} {
    width: ${width}rem;
    right: 0;
    left: unset;
  }

  // override library styles
  --rdp-cell-size: 1.9rem;
  --rdp-accent-color: ${darkOffBlack};
  --rdp-background-color: ${blueHover};
  /* Outline border for focused elements */
  --rdp-outline: 2px solid ${fadedWhite};
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0);

  .rdp-caption,
  .rdp-day {
    text-transform: uppercase;
  }

  .rdp-day {
    border-radius: 0;
    font-size: 0.875rem;
  }

  .rdp-day_today {
    color: ${fadedWhite};
    border: 0.0625rem solid ${blue};
  }

  .rdp-day:focus {
    outline: 0.0625rem solid ${fadedWhite};
  }

  .DayPicker-wrapper:focus {
    outline: none;
  }

  .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside):not(.rdp-day_range_start):not(.rdp-day_range_end) {
    background-color: ${darkOffBlack};
  }

  .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
    background-color: ${blue};
  }

  .rdp-head_cell {
    color: ${weekdayGrey};
    font-weight: 100;
    font-size: 0.875rem;
  }

  .rdp-caption_label {
    font-weight: 100;
    font-size: 1.15rem;
  }
`;

const leftColumn = css`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
  margin: 0 auto;
  padding: 0.25rem;

  ${dsmMediaQuery('medium device')} {
    grid-column: 1;
    height: 18rem;
    margin: unset;
  }
`;

const rightColumn = css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1.5rem 1.5rem 0 1.5rem;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;

  ${dsmMediaQuery('medium device')} {
    grid-column: 2;
    grid-row: 1;
    margin: unset;
    padding: 1.5rem 1.5rem 0 0;
  }
`;

export { outer, dayPickerContainer, leftColumn, rightColumn };
