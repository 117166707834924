import { toGlobalId } from 'graphql-relay';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router-dom';
import { getUnixTime, subDays, subHours, addYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import linkClicked from '../../adobe-launch/events/link-clicked';
import buttonClicked from '../../adobe-launch/events/button-clicked';
import tabClicked from '../../adobe-launch/events/tab-clicked';
import { Event, ErrorBoundary, ErrorFallback, Loader } from '../../components';
import usePageViewed from '../../adobe-launch/hooks/usePageViewed';
import { pageStyles } from '../../styles';
import sentryCaptureError from '../../utils/sentry/sentry-capture-error';
import { DeviceQuery } from './__generated__/DeviceQuery.graphql';
import Events from './Events';
import DeviceLatestSync from './DeviceLatestSync/DeviceLatestSync';
import { childrenRoutesContainer, deviceStyles, navItemStyles, navStyles, tabContainerStyles } from './styles';
import { DeviceProps, TParams, GeniIdentity } from './types';
import pageViewed from '../../adobe-launch/events/page-viewed';
import Overview from './Overview/Overview';
import { useAccount } from '../../providers/AccountProvider';
import eventModalViewed from '../../adobe-launch/events/event-modal-viewed';
import DevicePageHeader from './DevicePageHeader/DevicePageHeader';
import Reports from './Reports/Reports';
import MoreData from './MoreData/MoreData';
import useQueryStringParam from '../../hooks/useQueryParameters/useQueryStringParam';
import RemoteControl from './RemoteControl/RemoteControl';

const Device: React.FC<DeviceProps> = ({ startDate, endDate }) => {
  usePageViewed('Device');

  const { id } = useParams<TParams>();
  const [eventId, setEventId] = useQueryStringParam('eventId');
  const [tabParam] = useQueryStringParam('tab');
  const [tab, setTab] = useState<string | undefined>('overview');
  const { account } = useAccount();
  const { t } = useTranslation();

  useEffect(() => {
    if (tabParam) {
      setTab(tabParam);
    }
  }, [tabParam]);

  const goToEvents = useCallback(() => setTab('events'), []);

  const data = useLazyLoadQuery<DeviceQuery>(
    graphql`
      query DeviceQuery($id: ID!, $count: Int, $cursor: String) {
        node(id: $id) {
          ... on Device {
            uuid
            name
            familyCode
            customerName
            unitType
            unitVersion
            deviceTwinId
            ...DeviceLatestSync_device
            ...Overview_device
            ...DeviceEvents_device
          }
        }
      }
    `,
    { id: toGlobalId('Device', id || ''), count: 30 },
  );

  const { node } = data;
  if (!node) return <span>Device not found</span>;
  const defaultGeniIdentity: GeniIdentity = { familyCode: 30, unitType: 1, unitVersion: 1 };
  const geniIdentity: GeniIdentity =
    node.familyCode && node.unitType && node.unitVersion
      ? {
          familyCode: node.familyCode,
          unitType: node.unitType,
          unitVersion: node.unitVersion,
        }
      : defaultGeniIdentity;

  return (
    <div css={[pageStyles, deviceStyles]}>
      <DevicePageHeader
        customerName={node.customerName}
        installationName={node.name}
        label={t('WP.OVERVIEW.LAST_SYNC')}
        LastSync={<DeviceLatestSync device={node} />}
      />

      <div css={tabContainerStyles}>
        {tab ? (
          <>
            <nav css={navStyles}>
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                className={tab === 'overview' ? 'active' : undefined}
                css={navItemStyles}
                onClick={(e): void => {
                  e.preventDefault();
                  setTab('overview');
                  tabClicked('OVERVIEW');
                }}
              >
                {t('WP.OVERVIEW.OVERVIEW')}
              </a>
              <a
                href="#"
                className={tab === 'events' ? 'active' : undefined}
                css={navItemStyles}
                onClick={(e): void => {
                  e.preventDefault();
                  goToEvents();
                  tabClicked('events');
                }}
              >
                {t('WP.EVENTS.TAB.ALL_EVENTS')}
              </a>
              <a
                href="#"
                className={tab === 'more-data' ? 'active' : undefined}
                css={navItemStyles}
                onClick={(e): void => {
                  e.preventDefault();
                  setTab('more-data');
                  tabClicked('MORE DATA');
                }}
              >
                {t('WP.MORE.DATA.TAB_TITLE')}
              </a>
              <a
                href="#"
                className={tab === 'remote' ? 'active' : undefined}
                css={navItemStyles}
                onClick={(e): void => {
                  e.preventDefault();
                  setTab('remote');
                  tabClicked('REMOTE');
                }}
              >
                {t('WP.REMOTE.REMOTE')}
              </a>
              <a
                href="#"
                className={tab === 'reports' ? 'active' : undefined}
                css={navItemStyles}
                onClick={(e): void => {
                  e.preventDefault();
                  setTab('reports');
                  tabClicked('REPORTS');
                }}
              >
                {t('WP.REPORTS.REPORTS')}
              </a>
            </nav>
            <div css={childrenRoutesContainer}>
              <ErrorBoundary
                onError={(error): void => sentryCaptureError(error, 'Device')}
                FallbackComponent={ErrorFallback}
              >
                <Suspense fallback={<Loader />}>
                  {tab === 'overview' && (
                    <Overview
                      setEventId={setEventId}
                      startDate={startDate}
                      endDate={endDate}
                      device={node}
                      goToEvents={goToEvents}
                    />
                  )}
                  {tab === 'events' && (
                    <Events
                      device={node}
                      onItemClick={(aId): void => {
                        if (aId) setEventId(aId);
                      }}
                    />
                  )}
                  {tab === 'more-data' && (
                    <MoreData endDate={endDate} startDate={startDate} geniIdentity={geniIdentity} />
                  )}
                  {tab === 'remote' && <RemoteControl deviceTwinId={node.deviceTwinId} />}
                  {tab === 'reports' && (
                    <Reports deviceTwinId={node.deviceTwinId} deviceId={node.uuid} accountId={account.uuid} />
                  )}
                </Suspense>
              </ErrorBoundary>
              {eventId && (
                <Event
                  id={eventId}
                  onClose={() => setEventId(undefined)}
                  onButtonCloseClick={(): void => {
                    buttonClicked('X');
                  }}
                  onButtonGoToAssetDetailsClick={(): void => {
                    buttonClicked('GO TO ASSET DETAILS');
                  }}
                  onComponentDidMount={(event) => {
                    if (event?.code && event?.type) {
                      eventModalViewed(event);
                    }
                  }}
                  onComponentWillUnmount={() => pageViewed('Device')}
                  onLinkServicePhoneClick={(): void => {
                    linkClicked('Grundfos Service (+45 87504444)');
                  }}
                  show={!!eventId}
                />
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default (() => {
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  useEffect(() => {
    setStartDate(getUnixTime(subHours(subDays(new Date(), 1), 12)));
    // Hack: We set the enddate to 5 years in the future in order to make sure we always get the latest data when fetch data for the overview every 10 sec.
    setEndDate(getUnixTime(addYears(new Date(), 5)));
  }, []);

  if (!startDate || !endDate) return null;

  return <Device {...{ startDate, endDate }} />;
}) as React.FC;
