import { Theme } from '@emotion/react';

const dark: Theme = {
  colors: {
    navigation: {
      hover: '#2F3336',
      mainNavigationSelected: '#4dbbeb26',
    },
    neutral: {
      neutral0: { hex: '#ffffff', rgba: 'rgba(255,255,255,1)' },
      neutral100: { hex: '#f8f8f8f8', rgba: 'rgba(248, 248,248,1)' },
      neutral200: { hex: '#f1f1f1f1', rgba: 'rgba(241,241,241,1)' },
      neutral300: { hex: '#dedede', rgba: 'rgba(222,222,222,1)' },
      neutral400: { hex: '#c6c7c7', rgba: 'rgba(198,199,199,1)' },
      neutral500: { hex: '#999999', rgba: 'rgba(153,153,153,1)' },
      neutral600: { hex: '#6c6e6e', rgba: 'rgba(108,110,110,1)' },
      neutral700: { hex: '#525454', rgba: 'rgba(82,84,84,1)' },
      neutral800: { hex: '#383a3c', rgba: 'rgba(56,58,60)' },
      neutral900: { hex: '#2e2132', rgba: 'rgba(46,49,50,1)' },
      neutral1000: { hex: '#222527', rgba: 'rgba(34,37,39,1)' },
      neutralDark: { hex: '#121515', rgba: 'rgba(18,21,22,1)' },
    },
    white: {
      primary: 'rgba(255, 255, 255, 0.9)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      inactive: 'rgba(255, 255, 255, 0.38)',
    },
    blue: {
      blue3: {
        baseColor: '#0068b4',
        hoverColor: '#1a77bb',
        pressedColor: '#186399',
      },
      blue4: {
        baseColor: '#4DBBEB',
        hoverColor: '#5fc1ed',
        pressedColor: '#509ec1',
      },
      coporateBlue: {
        baseColor: '#11497b',
        hoverColor: '#295b88',
        pressedColor: '#244c70',
      },
    },
    graphs: {
      blue: {
        primary: '#3386c3', // rgba(51, 134, 195, 1)
        secondary: '#33a3dc', // rgba(51, 163, 220, 1)
        primaryTransparent20: 'rgba(51, 134, 195, 0.2)',
        primaryTransparent60: 'rgba(51, 134, 195, 0.6)',
      },
      green: {
        primary: '#7abfa8', // rgba(122, 191, 168, 1)
        secondary: '#d6d357', // rgba(214, 211, 87, 1)
        primaryTransparent20: 'rgba(122, 191, 168, 0.2)',
        primaryTransparent60: 'rgba(122, 191, 168, 0.6)',
      },
      sand: {
        primary: '#e6dcbd',
        primaryTransparent20: 'rgba(230,220,189, 0.2)',
        primaryTransparent60: 'rgba(230,220,189, 0.6)',
      },
      yellow: {
        primary: '#D6D357',
        primaryTransparent20: 'rgba(214, 211, 87, 0.2)',
        primaryTransparent60: 'rgba(214, 211, 87, 0.6)',
      },
    },
    primary: '#11497b', // Corporate blue
    secondary: '#6EB456',
    primaryBackground: '#121516',
    primaryBackgroundTransparent: 'rgba(18, 21, 22, 0.7)',
    secondaryBackground: '#222527',
    tertiaryBackground: '#2F3133',
    primaryText: '#C0C2C3',
    secondaryText: '#FFFFFF',
    tertiaryText: '#4DBBEB', // blue 4 30%white
    errorText: '#E33353',
    button: {
      primary: {
        background: '#0068B4', // blue 3
        text: '#fff',
        hover: '#1a77bb', // Blue 3 hover
      },
    },
    chart: {
      default: {
        warningThreshold: '#F6AA33',
        alarmThreshold: '#E33353',
      },
      analysis: {
        valueMin: '#D2D474',
        valueMax: '#9FD8DC',
        valueAvg: '#10C0E1',
        valueRaw: '#10C0E1',
      },
      sparkline: '#6EB456',
    },
    // disabledText: '',
    primaryBorder: '#42484C',
    secondaryBorder: '#454647',
    event: {
      alarm: '#E33353',
      warning: '#F6AA33',
      none: '#6EB456',
    },
    tag: {
      red: '#E33353',
      purple: '#A685E2',
      green: '#72BF70',
    },
    skeletonLoader: {
      baseColor: '#2F3133',
      highlightColor: '#585a5b',
    },
    border: {
      subtle: '#2e3132',
    },
    loader: { light: '#d9d9d9' },
  },
};

export default dark;
