import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Tooltip } from '../../../components';
import { tooltipIcon } from './styles';
import tooltip from '../../../assets/images/tooltip.svg';

const RemoteControlDisabledSectionHeader = () => {
  const { t } = useTranslation();
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <div
        css={css`
          padding-right: 0.5rem;
        `}
      >
        {t('WP.REMOTE.OPERATION_MODE')}
      </div>
      <Tooltip
        placement="top"
        content={t('WP.REMOTE.DOSING_PUMP_STOPPED_LOCALLY_TOOLTIP')}
        size="large"
        trigger="click"
      >
        <img css={tooltipIcon} src={tooltip} alt="tooltip" />
      </Tooltip>
    </div>
  );
};

export default RemoteControlDisabledSectionHeader;
