/**
 * @generated SignedSource<<0d2eab7317ddae08a7836a13aed1b0f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventFooter_item$data = {
  readonly device: {
    readonly uuid: string;
  };
  readonly " $fragmentType": "EventFooter_item";
};
export type EventFooter_item$key = {
  readonly " $data"?: EventFooter_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventFooter_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventFooter_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "34885aa4dc2b776435f220dc83979797";

export default node;
