import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useTranslation } from 'react-i18next';
import { Tile, TileBody, TileHeader } from '../../../../../components/Tiles';
import { NoDataAvailableMessage, Button, Portal } from '../../../../../components';
import dda from '../../../../../assets/images/dda.png';
import { OverviewTile_device$key } from './__generated__/OverviewTile_device.graphql';
import { OverviewTileProps } from './types';
import {
  addressStyles,
  buttonStyles,
  contactPersonText,
  containerStyles,
  imageStyles,
  tileBodyStyles,
  tileStyles,
  verticalLineStyles,
} from './styles';
import { headingSmallBrightStyles, paragraphBrightStyles } from '../../../../../styles';
import InfoContainer from '../InstallationInfo/InfoContainer';

const DisplayAddress = ({
  street,
  postalCode,
  city,
  country,
}: {
  street: string | null | undefined;
  postalCode: string | null | undefined;
  city: string | null | undefined;
  country: string | null | undefined;
}) => (
  <div>
    <div>{street}</div>
    <div css={addressStyles}>
      <span>{postalCode}</span>
      <span>{city}</span>
    </div>
    <div>{country}</div>
  </div>
);

const OverviewTile = ({ device, className }: OverviewTileProps) => {
  const [showModal, setShowModal] = useState(false);
  const data = useFragment<OverviewTile_device$key>(
    graphql`
      fragment OverviewTile_device on Device {
        name
        customerName
        location {
          street
          city
          postalCode
          country
        }
        staticdata {
          id
          value
          code
        }
      }
    `,
    device,
  );

  const { name: installationName, location, staticdata, customerName } = data;

  const contactPersonName = staticdata?.find((item) => item?.code === 'sdcs:CONTACT_PERSON_NAME')?.value || '';
  const contactPersonPhone = staticdata?.find((item) => item?.code === 'sdcs:CONTACT_PERSON_PHONE')?.value || '';

  const { t } = useTranslation();

  if (!data)
    return (
      <Tile
        key="noData"
        Header={<TileHeader text={t('WP.OVERVIEW.PUMP_STATUS')} />}
        Body={<NoDataAvailableMessage size="text" message={t('WP.ERROR.NO_DATA')} />}
      />
    );

  return (
    <div css={[containerStyles, className]}>
      <div css={imageStyles}>
        <img src={dda} alt="dda pump" />
      </div>
      <div css={verticalLineStyles} />
      <Tile
        styles={tileStyles}
        key="overview"
        Body={
          <div>
            <TileHeader text={t('WP.OVERVIEW.OVERVIEW')} />
            <TileBody css={[tileBodyStyles, paragraphBrightStyles]}>
              <h3 css={headingSmallBrightStyles}>{customerName}</h3>
              <p>{installationName}</p>
              <DisplayAddress
                street={location?.street}
                city={location?.city}
                postalCode={location?.postalCode}
                country={location?.country}
              />
              <div>
                <div>
                  <span>{t('WP.OVERVIEW.CONTACT_PERSON_ON_SITE')}:</span>
                  <p css={contactPersonText}>{contactPersonName}</p>
                </div>
                <div>
                  <span>{t('WP.OVERVIEW.MODAL.CONTACT_PERSON_PHONE')}:</span>
                  <p css={contactPersonText}>{contactPersonPhone}</p>
                </div>
              </div>
              <Button css={buttonStyles} type="button" variant="text link" onClick={() => setShowModal(true)}>
                {t('WP.OVERVIEW.INSTALLATION_INFO')}
              </Button>
            </TileBody>
          </div>
        }
      />
      {showModal ? (
        <Portal>
          <InfoContainer onClose={() => setShowModal(false)} />
        </Portal>
      ) : null}
    </div>
  );
};

export default OverviewTile;
