import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import Root from './components/Root/Root';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '@reach/menu-button/styles.css';
import './i18n';

LogRocket.init('grsmbs/sdcs');

Sentry.init({
  dsn: process.env.GIC_UI_SENTRY_DSN,
  maxBreadcrumbs: 10,
  environment: process.env.GIC_UI_ENVIRONMENT,
  beforeSend: (event) => {
    if (LogRocket.sessionURL) {
      // eslint-disable-next-line no-param-reassign
      event.extra = { ...event.extra, LogRocket: LogRocket.sessionURL };
    }
    return event;
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(React.createElement(React.StrictMode, null, React.createElement(Root)));
