import { graphql, useFragment } from 'react-relay/hooks';
import React from 'react';
// eslint-disable-next-line camelcase
import { PumpStatusData_device$key } from './__generated__/PumpStatusData_device.graphql';
import { PumpStatusDataProps } from './types';
import PumpStatus from './PumpStatus';

const PumpStatusData: React.FC<PumpStatusDataProps> = ({ device }) => {
  // eslint-disable-next-line camelcase
  const data = useFragment<PumpStatusData_device$key>(
    graphql`
      fragment PumpStatusData_device on Device {
        latestSync {
          outOfSync
        }
        pumpStatusDataLatestDeviceData: latestDeviceData {
          dataPoints {
            value
            name
            unit
          }
        }
      }
    `,
    device.node,
  );

  const controlMode = data?.pumpStatusDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:CONTROL_MODE',
  )?.value;
  const backlight = data?.pumpStatusDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:BACKLIGHT',
  )?.value;
  const pumpStatus = data?.pumpStatusDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:DISPLAY_MODE',
  )?.value;
  const stoppedBy = data?.pumpStatusDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:STOP_CONTROL_STATE',
  )?.value;
  const pumpState = data?.pumpStatusDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:PUMPING_STATE',
  )?.value;
  const commSetup = data?.pumpStatusDataLatestDeviceData?.dataPoints?.find(
    (item) => item?.name === 'gfdm:COMM_SETUP',
  )?.value;
  const setpoint = data?.pumpStatusDataLatestDeviceData?.dataPoints?.find(
    (item) => item?.name === 'gfdm:MANUAL_DOSING_CAPACITY',
  );
  const mlPerPulse = data?.pumpStatusDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:PULSE_DOSING_VOLUME',
  );
  const isOutOfSync = data?.latestSync?.outOfSync;
  if (isOutOfSync) return <PumpStatus />;

  return (
    <PumpStatus
      backlight={backlight}
      geniBusControl={commSetup}
      pumpStatus={pumpStatus}
      stoppedBy={stoppedBy}
      pumpState={pumpState}
      setpoint={setpoint}
      mlPerPulse={mlPerPulse}
      controlMode={controlMode}
    />
  );
};

export default PumpStatusData;
