import React, { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useParams } from 'react-router-dom';
import OverviewChemPairing from './OverviewChemPairing/OverviewChemPairing';
import { OverviewProps, TParams } from './types';

const Overview: React.FC<OverviewProps> = ({
  device,
  startDate,
  endDate,
  onComponentDidMount,
  onComponentWillUnmount,
  goToEvents,
}) => {
  const { id } = useParams<TParams>();

  useEffect(() => {
    if (onComponentDidMount) onComponentDidMount();

    return () => {
      if (onComponentWillUnmount) onComponentWillUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deviceData = useFragment(
    graphql`
      fragment Overview_device on Device {
        descendants {
          nodes {
            familyCode
            unitType
            unitVersion
          }
        }
      }
    `,
    device,
  );

  if (!id) return null;

  const ddaIdentity = deviceData.descendants?.nodes?.find((node) => node?.familyCode === 30);

  return (
    <OverviewChemPairing
      startDate={startDate}
      endDate={endDate}
      id={id}
      geniIdentity={ddaIdentity}
      goToEvents={goToEvents}
    />
  );
};

export default Overview;
