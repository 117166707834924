import { css, Theme } from '@emotion/react';
import { ContentFlow, Placement, Size } from './types';

const wrapper = css`
  position: relative;
  cursor: pointer;
`;

const handleSizeCreator =
  (size: Size) =>
  <T>(values: T[], defaultValue = 'unset') => {
    switch (size) {
      case 'small':
        return values[0] ?? defaultValue;
      case 'medium':
        return values[1] ?? defaultValue;
      case 'large':
        return values[2] ?? defaultValue;
      default:
        return defaultValue;
    }
  };

const handleDirectionCreator =
  (placement: Placement, contentFlow: ContentFlow) =>
  <T>(values: T[], defaultValue = 'unset') => {
    const { horizontal, vertical } = contentFlow;
    const isLeftDirection = horizontal === 'left';
    const isUpDirection = vertical === 'up';

    switch (placement) {
      case 'top':
        return (isLeftDirection ? values[0] : values[1]) ?? defaultValue;
      case 'right':
        return (isUpDirection ? values[2] : values[3]) ?? defaultValue;
      case 'bottom':
        return (isLeftDirection ? values[4] : values[5]) ?? defaultValue;
      case 'left':
        return (isUpDirection ? values[6] : values[7]) ?? defaultValue;
      default:
        return defaultValue;
    }
  };

const contentWrapper = (
  size: Size,
  placement: Placement,
  contentFlow: ContentFlow,
  centeredText: boolean,
  theme: Theme,
) => {
  const handleSize = handleSizeCreator(size);

  const handlePlacement = handleDirectionCreator(placement, contentFlow);

  return css`
    position: absolute;
    z-index: 10;
    background-color: ${theme.colors.blue.blue3.baseColor};
    color: ${theme.colors.neutral.neutral0.hex};
    width: auto;
    min-width: ${handleSize(['5rem', '10rem', '30rem'])};
    max-width: ${handleSize(['8rem', '15rem', '40rem'])};
    ${handlePlacement(
      [
        // top - content goes left
        `
        bottom: 100%;
        margin-bottom: 1rem;
        right: -1.5rem;
        `,
        // top - content goes right
        `
        bottom: 100%;
        margin-bottom: 1rem;
        left: -1.5rem;
        `,
        // right - content goes up
        `
        left: 100%;
        bottom: 0rem;
        margin-left: 1rem;
        padding-top: 0.5rem;`,
        // right - content goes down
        `
        left: 100%;
        top: 0rem;
        margin-left: 1rem;
        margin-top: -0.5rem;`,
        // bottom - content goes right
        `top: 100%;
        margin-top: 0.75rem;
        right: -1.5rem;
        `,
        // bottom - content goes left
        `top: 100%;
        margin-top: 0.75rem;
        left: -1.5rem;
        `,
        // left - content goes up
        `
        right: 100%;
        bottom: 0rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
        `, // left - content goes down
        `
        right: 100%;
        top: -1rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
        `,
      ],
      // If something goes wrong we just insert empty string
      ' ',
    )}
    font-size: 14px;
    padding: ${handleSize(['0.3rem', '1rem', '1rem'])};
    text-align: ${centeredText ? 'center' : 'unset'};
    animation: opacity 0.33s ease-in-out;
    @keyframes opacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `;
};

const arrow = (placement: Placement, contentFlow: ContentFlow, theme: Theme) => {
  const handlePlacement = handleDirectionCreator(placement, contentFlow);
  return css`
    width: 0;
    height: 0;

    color: ${theme.colors.blue.blue3.baseColor};
    position: absolute;
    ${handlePlacement(
      [
        // top - arrow placement right (content goes left)
        `
        bottom: -0.5rem;
        right: 1.5rem;
        border-right: 0.5rem solid transparent;
        border-left: 0.5rem solid transparent;
        border-top: 0.5rem solid;
      `,
        // top -  arrow placement left (content goes right)
        `
        bottom: -0.5rem;
        left: 1.5rem;
        border-right: 0.5rem solid transparent;
        border-left: 0.5rem solid transparent;
        border-top: 0.5rem solid;
      `,
        // right - arrow placement down (content goes up)
        `
        bottom: 0.5rem;
        left: -0.5rem;
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        border-right: 0.5rem solid;
      `,
        // right - arrow placement top (content goes down)
        `
        top: 0.5rem;
        left: -0.5rem;
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        border-right: 0.5rem solid;
        `,
        // bottom  - arrpow placement right (content goes left)
        `
        top: -0.5rem;
        right: 1.5rem;
        border-right: 0.5rem solid transparent;
        border-left: 0.5rem solid transparent;
        border-bottom: 0.5rem solid;
        `,
        // bottom  - arrpow placement left (content goes right)
        `
        top: -0.5rem;
        left: 1.5rem;
        border-right: 0.5rem solid transparent;
        border-left: 0.5rem solid transparent;
        border-bottom: 0.5rem solid;
            `,
        // left- arrow placement down (content goes up)
        `
        bottom: 0.5rem;
        right: -0.5rem;
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        border-left: 0.5rem solid;
      `,
        // left- arrow placement top (content goes down)
        `
       top: 0.5rem;
       right: -0.5rem;
       border-top: 0.5rem solid transparent;
       border-bottom: 0.5rem solid transparent;
       border-left: 0.5rem solid;
     `,
      ],
      ' ',
    )}
  `;
};

const icon = (theme: Theme) => css`
  height: 1.1rem;
  width: 1.1rem;
  fill: ${theme.colors.neutral.neutral0.hex};
  margin-left: 0.5rem;
`;

export { wrapper, icon, contentWrapper, arrow };
