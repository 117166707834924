import React from 'react';
import { css } from '@emotion/react';
import { paragraphBoldStyles } from '../../../styles';
import { sectionStyles } from './styles';

const RemoteSection = ({
  title,
  children,
  className,
}: {
  title: string | JSX.Element[] | JSX.Element;
  children: JSX.Element[] | JSX.Element;
  className?: string;
}) => (
  <div className={className} css={[sectionStyles]}>
    <p
      css={[
        paragraphBoldStyles,
        css`
          padding-bottom: 1rem;
        `,
      ]}
    >
      {title}
    </p>
    {children}
  </div>
);

export default RemoteSection;
