/**
 * @generated SignedSource<<0f9f61b7f0174ccde08a0f6e6dfc9590>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InstallationInfoQuery$variables = {
  id: string;
};
export type InstallationInfoQuery$data = {
  readonly node: {
    readonly gatewayIMEI?: string | null;
    readonly name?: string;
    readonly customerName?: string | null;
    readonly qrCode?: string | null;
    readonly location?: {
      readonly name: string | null;
      readonly street: string | null;
      readonly country: string | null;
      readonly postalCode: string | null;
      readonly city: string | null;
      readonly geoLocation?: string | null;
      readonly commissioningData?: {
        readonly commissioningDate: string | null;
        readonly commissioningEngineer: string | null;
        readonly commissioningNote: string | null;
      };
    } | null;
    readonly staticdata?: ReadonlyArray<{
      readonly id: string;
      readonly value: string | null;
      readonly code: string | null;
    } | null> | null;
  } | null;
};
export type InstallationInfoQuery = {
  variables: InstallationInfoQuery$variables;
  response: InstallationInfoQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gatewayIMEI",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qrCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "geoLocation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommissioningData",
      "kind": "LinkedField",
      "name": "commissioningData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commissioningDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commissioningEngineer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commissioningNote",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Installation",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Metadata",
  "kind": "LinkedField",
  "name": "staticdata",
  "plural": true,
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InstallationInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "type": "Device",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InstallationInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "type": "Device",
            "abstractKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e39501f5ee76cd1a6e928cbfbe4c7a42",
    "id": null,
    "metadata": {},
    "name": "InstallationInfoQuery",
    "operationKind": "query",
    "text": "query InstallationInfoQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Device {\n      gatewayIMEI\n      name\n      customerName\n      qrCode\n      location {\n        __typename\n        name\n        street\n        country\n        postalCode\n        city\n        ... on Installation {\n          geoLocation\n          commissioningData {\n            commissioningDate\n            commissioningEngineer\n            commissioningNote\n          }\n        }\n        id\n      }\n      staticdata {\n        id\n        value\n        code\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "add3ccdc1e761bbd59f556b4e3c673f7";

export default node;
