import React, { PropsWithChildren } from 'react';
import { EventBodySectionProps } from './types';
import { headerContainer } from './styles';

const EventBodySection: React.FC<PropsWithChildren<EventBodySectionProps>> = ({ children, icon, title }) => (
  <div data-test-id="remedy-text">
    <div css={headerContainer}>
      <img src={icon} alt="remedyHeaderIcon" />
      <h2>{title}</h2>
    </div>
    {children}
  </div>
);

export default EventBodySection;
