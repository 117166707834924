import { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { useTheme } from '@emotion/react';
import { DefaultOptionsProps } from './types';
import { LocaleContext } from '../../../../../../providers/LocaleProvider/LocaleProvider';
import { dateTimeFormatter } from '../../../../utils';

const marginTop = 20;
const marginBottom = 35;
const marginLeft = 18;

const useDefaultOptions = ({ labels, thresholds }: DefaultOptionsProps): Highcharts.Options => {
  const { yAxis, postFix, dosedVolume } = labels;
  const { locale, shortDateTimeFormat, shortDateFormat, hourMinuteFormat } = useContext(LocaleContext);

  const {
    colors: { secondaryBackground, secondary, primaryText, tertiaryBackground, secondaryText },
  } = useTheme();
  // If we have thresholds define, we make room for rendering the thresholds (dashed line, triangle etc)
  const marginRight = thresholds ? 125 : 25;
  Highcharts.setOptions({
    // When we do custom formatting of the x-axis datelabels
    time: { useUTC: false },
  });
  const defaultOptions: Highcharts.Options = {
    legend: { enabled: false },
    chart: {
      type: 'column',
      backgroundColor: secondaryBackground,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
    },
    // Remove the highcharts credit section
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: dateTimeFormatter(locale),
      lineColor: primaryText,
      showLastLabel: true,
      tickLength: 0,
      labels: {
        formatter() {
          if (this.isFirst || this.isLast) {
            return Highcharts.dateFormat(
              `${hourMinuteFormat.highChartsTimeFormat}</br>${shortDateFormat.highchartsFormat}`,
              Number(this.value),
            );
          }
          return Highcharts.dateFormat(hourMinuteFormat.highChartsTimeFormat, Number(this.value));
        },
        style: {
          color: primaryText,
        },
      },
      gridLineWidth: 0,
      crosshair: true,
    },

    yAxis: {
      min: 0,
      minRange: 0.0005,
      gridLineWidth: 0,
      lineColor: primaryText,
      lineWidth: 1,
      labels: {
        enabled: false,
      },
      title: {
        // E.g 'ml/m3 /h'
        text: yAxis.legend,
        // Move to top of graph so we get more space for our label
        align: 'high',
        style: { color: primaryText },
        rotation: 0,
        y: -10,
        offset: 0,
        // Try to fix the issue with gal/h and l/h, where gal/h will conflict with the graph
        x: 10 + yAxis.legend.length,
      },

      plotLines: [
        {
          color: primaryText,
          width: 1,
          value: thresholds?.thresholdMin?.value,
          dashStyle: 'Dash',
          label: {
            style: { color: primaryText },
            text: `${thresholds?.thresholdMin?.label}<br />${(thresholds?.thresholdMin?.value || 0).toFixed(
              2,
            )} ${postFix}`,
            align: 'right',
            rotation: 0,
            y: -5,
            x: 70,
          },
        },
        {
          color: primaryText,
          width: 1,
          value: thresholds?.thresholdMax?.value,
          dashStyle: 'Dash',
          label: {
            style: { color: primaryText },
            text: `${thresholds?.thresholdMax?.label}<br />${(thresholds?.thresholdMax?.value || 0).toFixed(
              2,
            )} ${postFix}`,
            align: 'right',
            rotation: 0,
            y: -5,
            x: 70,
          },
        },
      ],
    },
    tooltip: {
      // useUTC is set to false in the time object
      pointFormat: `${dosedVolume}: {point.y:.4f} ${postFix}`,
      shared: true,
      shape: 'square',
      useHTML: true,
      backgroundColor: tertiaryBackground,
      borderWidth: 0,
      borderRadius: 0,
      shadow: false,
      style: {
        color: primaryText,
        fontSize: '0.8rem',
      },
      headerFormat: `<div style="text-transform: uppercase; margin-bottom: 0.5rem; color: ${secondaryText}">{point.key}</div>`,
      xDateFormat: shortDateTimeFormat.highchartsDateTimeFormat,
      padding: 16,
    },
    plotOptions: {
      column: {
        maxPointWidth: 10,
        pointWidth: 10,
        pointPadding: 0.2,
        groupPadding: 0.2,
      },
      series: {
        color: secondary,
        dataLabels: {
          enabled: false,
        },
      },
    },
  };

  const [options, setOptions] = useState<Highcharts.Options>(defaultOptions);

  useEffect(() => {
    // Hack:
    // Re-create the entire options object. That will cause the re-render we so desperately need...
    setOptions({ ...defaultOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryBackground, primaryText, thresholds, labels, postFix]);

  return options;
};

export default useDefaultOptions;
