import { css } from '@emotion/react';

const tileBodyStyles = css`
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-areas:
    'controlMode controlMode'
    'setpoint setpoint'
    'pumpOperation pumpOperation'
    '. pumpStatus'
    '. pumpRunning'
    '. stoppedBy'
    'remoteControl remoteControl';
  padding-top: 1rem;
  gap: 0.5rem;
`;

const gridPlacement = (placement: string) => css`
  grid-area: ${placement};
`;

export { tileBodyStyles, gridPlacement };
