import React from 'react';
import { Field, useField, FieldHookConfig } from 'formik';
import { useTheme } from '@emotion/react';
import { labelStyles, fieldStyles, errorStyles, containerStyles } from './styles';

interface DropDownSelectProps {
  label: string | JSX.Element;
  className?: string;
  alignment?: 'center' | 'start' | 'end';
  disabled?: boolean;
  onSelectValue?: (fieldName: string, value: string) => void;
}

const DropDownSelect: React.FC<FieldHookConfig<string> & DropDownSelectProps> = (props) => {
  const [field, meta] = useField(props);
  const theme = useTheme();
  const { name, label, className, children, alignment, onSelectValue, disabled = false } = props;
  return (
    <div css={containerStyles(alignment)} className={className}>
      <label htmlFor={name} css={labelStyles(disabled, theme)}>
        {label}
      </label>

      <Field
        name={name}
        as="select"
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (onSelectValue) onSelectValue(e.target.name, e.target.value);
          field.onChange(e);
        }}
        css={fieldStyles}
      >
        {children}
      </Field>
      {meta.touched && meta.error ? <div css={errorStyles}>{meta.error}</div> : null}
    </div>
  );
};

export default DropDownSelect;
