import React, { useContext } from 'react';
import { formatDistanceToNowStrict, fromUnixTime } from 'date-fns';
import { LocaleContext } from '../../providers/LocaleProvider/LocaleProvider';

interface TimeAgoProps {
  timestamp: number;
  className?: string;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ timestamp, className }) => {
  const { locale } = useContext(LocaleContext);
  return (
    <span className={className}>
      {formatDistanceToNowStrict(fromUnixTime(timestamp), { addSuffix: true, unit: 'day', locale })}
    </span>
  );
};

export default TimeAgo;
