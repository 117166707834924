import { useAuth } from '@gic/react-auth';
import React from 'react';
import Loader from '../components/Loader/Loader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }): JSX.Element => {
  const { isAuthenticated, signinRedirect } = useAuth();

  if (isAuthenticated() || process.env.GIC_UI_AUTH_BYPASS === 'true') {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return children;
  }

  signinRedirect();

  return <Loader />;
};

export default PrivateRoute;
