import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, TileBody, TileHeader } from '../../../../../components/Tiles';
import { InfoItem, InfoItemIcon, NoDataAvailableMessage } from '../../../../../components';
import { StatusSquare, PumpStatusIcon } from '../../../../../components/Icons';
import operationUpdate from '../../../../../assets/images/operationUpdate.svg';
import { tileBodyStyles, gridPlacement } from './styles';
import { PumpStatusProps } from './types';
import {
  applyColor,
  applyControlMode,
  applyDisplayMode,
  applyRemoteControl,
  applyStopControlState,
  pumpRunning,
} from './utils';
import ControlMode from './ControlMode';

const notUndefinedOrNull = (num: null | undefined | number) => num !== undefined && num !== null;

export const PumpStatusContainer = ({
  TileTitle,
  children,
}: {
  TileTitle: JSX.Element;
  children: JSX.Element[] | JSX.Element;
}) => <Tile key="pumpStatus" Header={TileTitle} Body={<TileBody css={tileBodyStyles}>{children}</TileBody>} />;

const PumpStatus: React.FC<PumpStatusProps> = ({
  backlight,
  pumpStatus,
  geniBusControl,
  stoppedBy,
  pumpState,
  controlMode,
  setpoint,
  mlPerPulse,
}) => {
  const { t } = useTranslation();
  if (controlMode === undefined || controlMode === null || pumpStatus === undefined || pumpStatus === null) {
    return (
      <Tile
        Header={<TileHeader text={t('WP.OVERVIEW.PUMP_STATUS')} />}
        Body={<NoDataAvailableMessage size="text" message={t('WP.ERROR.NO_DATA')} />}
      />
    );
  }
  return (
    <PumpStatusContainer TileTitle={<TileHeader text={t('WP.OVERVIEW.PUMP_STATUS')} />}>
      <ControlMode
        controlMode={applyControlMode(controlMode)}
        setpoint={
          // eslint-disable-next-line no-nested-ternary
          applyControlMode(controlMode) === 'PULSE'
            ? mlPerPulse
            : applyControlMode(controlMode) === 'MANUAL'
            ? setpoint
            : undefined
        }
      />
      <InfoItem
        css={gridPlacement('pumpOperation')}
        label={t('WP.OVERVIEW.PUMP_OPERATION')}
        Icon={<StatusSquare fill={applyColor(backlight)} />}
      />
      <InfoItem
        css={gridPlacement('pumpStatus')}
        label={t('WP.OVERVIEW.PUMP_STATUS')}
        value={t(`WP.OVERVIEW.${applyDisplayMode(pumpStatus).toLocaleUpperCase()}`)}
        Icon={<PumpStatusIcon status={applyDisplayMode(pumpStatus)} />}
      />
      <InfoItem
        css={gridPlacement('pumpRunning')}
        label={t('WP.OVERVIEW.PUMP_RUNNING')}
        value={pumpState || pumpState === 0 ? t(`WP.COMMON.${pumpRunning(pumpState)}`) : ''}
        Icon={pumpRunning(pumpState) === 'YES' ? <InfoItemIcon iconSrc={operationUpdate} /> : undefined}
      />
      <InfoItem
        css={gridPlacement('stoppedBy')}
        label={t('WP.OVERVIEW.STOPPED_BY')}
        value={applyStopControlState(stoppedBy) ? t(`WP.OVERVIEW.STOPPED_BY_${applyStopControlState(stoppedBy)}`) : ''}
      />
      <InfoItem
        css={gridPlacement('remoteControl')}
        label={t('WP.COMMON.REMOTE_CONTROL')}
        value={
          notUndefinedOrNull(geniBusControl)
            ? t(`WP.COMMON.${applyRemoteControl(geniBusControl)}`).toUpperCase()
            : t('WP.ERROR.NO_DATA')
        }
      />
    </PumpStatusContainer>
  );
};

export default PumpStatus;
