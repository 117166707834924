import React from 'react';

const User: React.FC<{}> = () => (
  <svg viewBox="0 0 24 24" width="24pt" height="24pt">
    <defs>
      <clipPath id="_clipPath_MvKYetTMSZkCFGPTPrCQ2nqHTVSBAVW8">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
    <g clipPath="url(#_clipPath_MvKYetTMSZkCFGPTPrCQ2nqHTVSBAVW8)">
      <g>
        <g>
          <path
            d=" M 12 1.5 C 17.799 1.5 22.5 6.201 22.5 12 C 22.5 17.799 17.799 22.5 12 22.5 C 6.201 22.5 1.5 17.799 1.5 12 C 1.5 6.201 6.201 1.5 12 1.5 Z  M 12 3 C 7.029 3 3 7.029 3 12 C 3 14.253 3.828 16.313 5.197 17.892 C 7.162 17.038 9.452 16.5 12 16.5 C 14.548 16.5 16.838 17.038 18.803 17.892 C 20.172 16.314 21 14.254 21 12 C 21 7.029 16.971 3 12 3 Z  M 11.999 5.994 C 14.48 5.994 15.75 7.603 15.75 10.497 C 15.75 13.501 14.565 15.006 11.999 15.006 C 9.559 15.006 8.25 13.351 8.25 10.497 C 8.25 7.611 9.531 5.994 11.999 5.994 Z "
            fill="rgb(0,0,0)"
          />
          <clipPath id="_clipPath_QhPaPmGGRR7QPquUhc7vsPJvdJpjonMC">
            <path
              d=" M 12 1.5 C 17.799 1.5 22.5 6.201 22.5 12 C 22.5 17.799 17.799 22.5 12 22.5 C 6.201 22.5 1.5 17.799 1.5 12 C 1.5 6.201 6.201 1.5 12 1.5 Z  M 12 3 C 7.029 3 3 7.029 3 12 C 3 14.253 3.828 16.313 5.197 17.892 C 7.162 17.038 9.452 16.5 12 16.5 C 14.548 16.5 16.838 17.038 18.803 17.892 C 20.172 16.314 21 14.254 21 12 C 21 7.029 16.971 3 12 3 Z  M 11.999 5.994 C 14.48 5.994 15.75 7.603 15.75 10.497 C 15.75 13.501 14.565 15.006 11.999 15.006 C 9.559 15.006 8.25 13.351 8.25 10.497 C 8.25 7.611 9.531 5.994 11.999 5.994 Z "
              fill="rgb(255,255,255)"
            />
          </clipPath>
          <g clipPath="url(#_clipPath_QhPaPmGGRR7QPquUhc7vsPJvdJpjonMC)">
            <g>
              <g>
                <rect x="0" y="0" width="24" height="24" transform="matrix(1,0,0,1,0,0)" fill="rgb(255,255,255)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default User;
