import { css, keyframes, Theme } from '@emotion/react';

const slideUp = keyframes`
from {
  transform: translate(-50%, 0%);
}
to {
  transform: translate(-50%,-50%);
}
`;

const showStyle = css`
  animation: ${slideUp} 0.5s ease forwards;
  display: block;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  @-moz-document url-prefix() {
    background: rgba(34, 37, 39, 0.96);
  }
  transform: translate(-50%, -50%);
  background: rgba(34, 37, 39, 0.7); // Make sure this color has an opacity of less than 1
  backdrop-filter: blur(12px); // the higher px the more blurry
`;

const hideStyle = css`
  display: none;
`;

export { showStyle, hideStyle };
