import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { AppSetting, VolumetricConcentrationProps } from './types';
import { Tile, TileBody, TileHeader } from '../../../../../components/Tiles';
import { DateTimeFormatter, NoDataAvailableMessage } from '../../../../../components';
import { VolumetricConcentration_device$key } from './__generated__/VolumetricConcentration_device.graphql';
import {
  tileBodyStyles,
  summaryContainerStyles,
  summaryStyles,
  graphContainerStyles,
  summaryValueStyles,
  lastConnectedDateStyles,
  summaryErrorStyles,
} from './styles';
import VolumetricConcentrationGraph from './VolumetricConcentrationGraph/VolumetricConcentrationGraph';
import { Labels } from './VolumetricConcentrationGraph/types';
import { convertToSuperScript } from '../../../utils';

const TargetSummary = ({
  label,
  value,
  valueType,
  error,
}: {
  label: string;
  value: number;
  valueType: string;
  error: string;
}) => (
  <div css={summaryStyles}>
    <div>{label}</div>
    {value > 0.0015 ? (
      <div css={summaryValueStyles}>{`${value.toFixed(1)} ${valueType}`}</div>
    ) : (
      <div css={summaryErrorStyles}>{error}</div>
    )}
  </div>
);

const LastHourSummary = ({ label, value, valueType }: { label: string; value: number; valueType: string }) => (
  <div css={summaryStyles}>
    <div>{label}</div>
    <div css={summaryValueStyles}>{`${value.toFixed(1)} ${valueType}`}</div>
  </div>
);

const VolumetricSummary = ({
  valueActual,
  valueTarget,
  unit,
}: {
  valueTarget: number;
  valueActual: number;
  unit: string;
}) => {
  const { t } = useTranslation();
  return (
    <div css={summaryContainerStyles}>
      <LastHourSummary value={valueActual} label={t('WP.VOLUMETRIC.CONCENTRATION.TILE_LAST_HOUR')} valueType={unit} />
      <TargetSummary
        value={valueTarget}
        label={t('WP.VOLUMETRIC.CONCENTRATION.TILE_TARGET')}
        valueType={unit}
        error={t('WP.VOLUMETRIC.CONCENTRATION.TILE_ERROR')}
      />
    </div>
  );
};

const VolumetricContainer = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { t } = useTranslation();
  return (
    <Tile
      key="volumetric"
      styles={css`
        grid-area: volumetric;
      `}
      Header={<TileHeader text={t('WP.VOLUMETRIC.CONCENTRATION.TILE_TITLE')} />}
      Body={<TileBody css={tileBodyStyles}>{children}</TileBody>}
    />
  );
};

const rawBucketsToBucketData = (
  buckets:
    | readonly {
        readonly bucket: number;
        readonly value: number;
        readonly unit: string;
      }[]
    | undefined,
) => {
  const bucketsLength = buckets?.length || 0;
  return (buckets || []).map((bucketData, i) => {
    if (i === 0) {
      return {
        ...bucketData,
        bucket: `<${Number(bucketData?.bucket).toFixed(1)}`,
        value: Number(bucketData.value.toFixed(1)),
      };
    }
    if (i === bucketsLength - 1) {
      return {
        ...bucketData,
        bucket: `>${Number(bucketData?.bucket).toFixed(1)}`,
        value: Number(bucketData.value.toFixed(1)),
      };
    }
    return {
      ...bucketData,
      bucket: `${Number(bucketData?.bucket).toFixed(1)}`,
      value: Number(bucketData.value.toFixed(1)),
    };
  });
};

const height = 150;

const VolumetricConcentration: React.FC<VolumetricConcentrationProps> = ({ device }) => {
  // TODO: We need unit from settings.
  const data = useFragment<VolumetricConcentration_device$key>(
    graphql`
      fragment VolumetricConcentration_device on Device @argumentDefinitions(endDate: { type: Int }) {
        latestSync {
          outOfSync
        }
        volumetricConcentrationAppSettings: appSettings(
          settingSelector: [{ name: "sdcs:VOLUMETIC_CONCENTRATION_TARGET" }]
          endDate: $endDate
        ) {
          updatedAt
          name
        }
        latestDeviceData {
          latestSync
          dataPoints {
            value
            name
            unit
          }
          buckets {
            bucket
            value
            unit
          }
        }
        volumetricConcentrationTimeSeriesDatapoints: timeSeriesDatapoints(
          datapointSelectors: [
            { name: "sdcs:PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION", raw: true, geniIdentity: $geniIdentity }
          ]
          startDate: $startDate
          endDate: $endDate
        ) {
          name
          unit
          datapoints {
            valueRaw
          }
        }
      }
    `,
    device.node,
  );

  const getSetting = (name: string): AppSetting | undefined | null =>
    data?.volumetricConcentrationAppSettings?.find((setting) => setting?.name === name);

  const lastConnected = getSetting('sdcs:VOLUMETIC_CONCENTRATION_TARGET')?.updatedAt;
  // targetVolume is a calculated value which is why we don't fetch it directly from settings service
  const targetVolume =
    data?.latestDeviceData?.dataPoints?.find((dp) => dp?.name === 'sdcs:PROPORTIONAL_DOSING_TARGET')?.value || 0;
  const targetVolUnit = convertToSuperScript(
    data?.latestDeviceData?.dataPoints?.find((dp) => dp?.name === 'sdcs:PROPORTIONAL_DOSING_TARGET')?.unit || '',
  );
  const volumetricDataPoints =
    data?.volumetricConcentrationTimeSeriesDatapoints?.find(
      (item) => item.name === 'PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION',
    )?.datapoints || [];

  const buckets = rawBucketsToBucketData(data?.latestDeviceData?.buckets);
  const isOutOfSync = data?.latestSync?.outOfSync;

  const actualVolume = volumetricDataPoints[volumetricDataPoints.length - 1]?.valueRaw || 0;

  const { t } = useTranslation();
  const labels: Labels = {
    postFixY: '%',
    yAxis: {
      legend: `${t('WP.VOLUMETRIC.CONCENTRATION.TILE_GRAPH_YAXSIS_LEGEND_1')} </br>${t(
        'WP.VOLUMETRIC.CONCENTRATION.TILE_GRAPH_YAXIS_LEGEND_2',
      )}`,
      to: '100',
    },
    xAxis: {
      legend: targetVolUnit,
    },
    target: t('WP.VOLUMETRIC.CONCENTRATION.TILE_TARGET'),
  };

  if (!targetVolume || !buckets || isOutOfSync || !volumetricDataPoints) {
    return (
      <VolumetricContainer>
        <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} size="text" />
      </VolumetricContainer>
    );
  }

  return (
    <VolumetricContainer>
      <VolumetricSummary valueActual={actualVolume} valueTarget={targetVolume} unit={targetVolUnit} />
      <div css={graphContainerStyles}>
        {Boolean(lastConnected) && (
          <div>
            <span>{t('WP.VOLUMETRIC.CONCENTRATION.TILE_CONCENTRATION_CHANGE')} - </span>
            <span css={lastConnectedDateStyles}>
              <DateTimeFormatter timestamp={lastConnected || 0} />
            </span>
          </div>
        )}
        {Boolean(buckets) && (
          <VolumetricConcentrationGraph
            height={height}
            labels={labels}
            data={buckets}
            // Comment these bad bois in to test thresholds
            // thresholds={{
            //   thresholdMin: { minText: 'Min', categoryText: '40' },
            //   thresholdMax: { maxText: 'Max', categoryText: '70' },
            // }}
            // We need the target to be rounded here, for later conditional logic to draw the middle bucket label
            target={Number(targetVolume.toFixed(1))}
          />
        )}
      </div>
    </VolumetricContainer>
  );
};

export default VolumetricConcentration;
