import React from 'react';
import { paragraphBrightStyles } from '../../../styles';
import { icon, messageContainer } from './styles';
import tooltip from '../../../assets/images/tooltip.svg';

const RemoteControlMesage = ({ message }: { message?: string | JSX.Element }) =>
  message ? (
    <div css={[messageContainer, paragraphBrightStyles]}>
      <img css={[icon, paragraphBrightStyles]} src={tooltip} alt="tooltip" />
      {message}
    </div>
  ) : null;

export default RemoteControlMesage;
