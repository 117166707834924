/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { TFunction, useTranslation } from 'react-i18next';
import { toGlobalId } from 'graphql-relay';
import { usePageViewed } from '../../../../adobe-launch/hooks';
import { Button, TextInputField, DropDownSelect, Spinner, InfoItem } from '../../../../components';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';
import { contentStyles, dropDownStyle, formStyles, note, noteLabel, noteText } from './styles';
import { FormValues } from './types';
import countries from '../../countries';
import { usStates } from '../../states';
import { CustomerSummaryQuery } from './__generated__/CustomerSummaryQuery.graphql';
import { CustomerSummaryMutation } from './__generated__/CustomerSummaryMutation.graphql';
import { heading05Styles, paragraphBaseStyles, paragraphBrightStyles } from '../../../../styles';
import { Summary } from './Summary';

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t('WP.ONBOARDING.CUSTOMER_DETAILS_NAME_ERROR')),
    address: Yup.string(),
    postalCode: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
  });

const CustomerSummary: React.FC = () => {
  usePageViewed('Onboarding:CustomerSummary');
  const { state } = useLocation();
  const claimId = (state as { claimId: string })?.claimId;
  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language;
  const regionName = new Intl.DisplayNames([selectedLanguage], { type: 'region' });
  const navigate = useNavigate();

  const data = useLazyLoadQuery<CustomerSummaryQuery>(
    graphql`
      query CustomerSummaryQuery($id: ID!) {
        clientState {
          onboarding {
            onboardingId
            section {
              name
              address
              postalCode
              city
              country
              state
            }
          }
        }
        node(id: $id) {
          ... on UnclaimedDevice {
            installationNote
          }
        }
      }
    `,
    { id: toGlobalId('UnclaimedDevice', claimId) },
  );
  const installationNote = data?.node?.installationNote || '';

  const [commit, isInFlight] = useMutation<CustomerSummaryMutation>(graphql`
    mutation CustomerSummaryMutation($id: ID!, $input: OnboardingCreateSectionInput!) {
      onboardingCreateSection(id: $id, input: $input) {
        id
        section {
          name
          address
          postalCode
          city
          country
          state
        }
      }
    }
  `);

  const section = data.clientState?.onboarding?.section;

  const initialValues: FormValues = {
    name: section?.name || '',
    address: section?.address || '',
    postalCode: section?.postalCode || '',
    city: section?.city || '',
    state: section?.state || '',
    country: section?.country || '',
  };

  if (isInFlight) return <Spinner />;

  if (section?.name) {
    return <Summary section={section} claimId={claimId} installationNote={installationNote} />;
  }

  return (
    <div css={contentStyles}>
      <h1 css={heading05Styles}>{t('WP.ONBOARDING.CUSTOMER_DETAILS_TITLE')}</h1>
      <br />
      <label htmlFor="installationNote" css={note}>
        <div css={[paragraphBaseStyles, noteLabel]}>{t('WP.ONBOARDING.INSTALLATION_NOTE_LABEL')}</div>
        <div css={[paragraphBrightStyles, noteText]}>{installationNote}</div>
      </label>
      <InfoItem
        css={css`
          grid-area: claim;
        `}
        label={t('WP.ONBOARDING.CUSTOMER_CLAIM_CODE_LABEL')}
        value={claimId}
        noBorder
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
          const onboardingRecordID = 'client:clientState:onboarding';

          const { address, city, country, postalCode, name, state: stateRegion } = values;

          const onboardingId = data.clientState?.onboarding?.onboardingId;

          if (onboardingId) {
            commit({
              variables: {
                id: onboardingRecordID,
                input: {
                  onboardingId,
                  name,
                  address,
                  city,
                  country,
                  state: stateRegion,
                  postalCode,
                },
              },
              onCompleted: () => navigate('/onboarding/installation', { state: { claimId } }),
            });
          }
        }}
      >
        {({ values }) => (
          <Form css={formStyles((values.country || '').toLocaleLowerCase().includes('us'))}>
            <TextInputField
              isVertical
              css={css`
                grid-area: name;
              `}
              name="name"
              placeholder={t('WP.ONBOARDING.CUSTOMER_DETAILS_NAME_PLACEHOLDER')}
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_NAME_LABEL')}
            />
            <TextInputField
              isVertical
              css={css`
                grid-area: address;
              `}
              name="address"
              placeholder={t('WP.ONBOARDING.CUSTOMER_DETAILS_ADDRESS_PLACEHOLDER')}
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_ADDRESS_LABEL')}
            />
            <TextInputField
              isVertical
              css={css`
                grid-area: postalCode;
              `}
              name="postalCode"
              placeholder={t('WP.ONBOARDING.CUSTOMER_DETAILS_POSTAL_PLACEHOLDER')}
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_POSTAL_LABEL')}
            />
            <TextInputField
              isVertical
              css={css`
                grid-area: city;
              `}
              name="city"
              placeholder={t('WP.ONBOARDING.CUSTOMER_DETAILS_CITY_PLACEHOLDER')}
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_CITY_LABEL')}
            />
            <DropDownSelect
              name="country"
              label={t('WP.ONBOARDING.CUSTOMER_DETAILS_COUNTRY_LABEL')}
              css={dropDownStyle('country')}
            >
              {countries.map(({ id, countryCode }) => (
                <option key={id} value={countryCode}>
                  {regionName.of(countryCode)}
                </option>
              ))}
            </DropDownSelect>
            {(values.country || '').toLocaleLowerCase().includes('us') && (
              <DropDownSelect
                name="state"
                label={t('WP.ONBOARDING.CUSTOMER_DETAILS_STATE_LABEL')}
                css={dropDownStyle('state')}
              >
                {usStates.map(({ name, code }) => (
                  <option key={code} value={name}>
                    {name}
                  </option>
                ))}
              </DropDownSelect>
            )}
            <div
              css={css`
                grid-area: back;
                padding-top: 2rem;
                ${dsmMediaQuery('medium device')} {
                  padding-top: 2rem;
                }
              `}
            >
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate('/onboarding/create-or-select-customer', { state: { claimId } })}
              >
                {t('WP.COMMON.BACK')}
              </Button>
            </div>
            <div
              css={css`
                grid-area: next;
                ${dsmMediaQuery('medium device')} {
                  justify-self: right;
                  padding-top: 0rem;
                  align-self: end;
                }
              `}
            >
              <Button type="submit" variant="primary">
                {t('WP.COMMON.NEXT')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomerSummary;
