/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import Toggler from '../Toggler/Toggler';

interface TogglerFormikProps {
  label: string | JSX.Element;
  className?: string;
  isToggled: boolean;
  name: string;
  isDisabled?: boolean;
  onToggle?: (nextValue: boolean) => void;
}

const TogglerFormik: React.FC<FieldHookConfig<string> & TogglerFormikProps> = (props) => {
  const { label, name, className, isToggled, onToggle, isDisabled = false } = props;
  const [field] = useField({ ...props, type: 'checkbox', checked: isToggled });
  const { onChange, ...rest } = field;

  return (
    <Toggler
      className={className}
      onToggle={(e) => {
        if (onToggle) onToggle(!field.checked);
        onChange(e);
      }}
      label={label}
      id={name}
      isToggled={!!field.checked}
      isDisabled={isDisabled}
      {...rest}
    />
  );
};

export default TogglerFormik;
