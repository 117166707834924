import React from 'react';
import stop from '../../../assets/images/stopFilled.svg';
import play from '../../../assets/images/playFilled.svg';
import pause from '../../../assets/images/pauseFilled.svg';

const PumpStatusIcon = ({
  status,
}: {
  status: 'Active' | 'Stopped' | 'Standby' | 'Service' | 'Calibrating' | 'Deaerating';
}) => {
  if (status === 'Active') return <img height="16" src={play} alt="play" />;
  if (status === 'Stopped') return <img height="16" src={stop} alt="stop" />;
  if (status === 'Standby') return <img height="16" src={pause} alt="pause" />;
  return null;
};

export default PumpStatusIcon;
