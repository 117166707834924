import pageViewed from './page-viewed';

interface Event {
  code?: string;
  type?: string;
}

const eventModalViewed = (event: Event): void => {
  pageViewed(`Modal:Event:${event.type}:${event.code}`, 'modal-event');
};

export default eventModalViewed;
