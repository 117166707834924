/* eslint-disable jsx-a11y/label-has-associated-control */
import { useTheme } from '@emotion/react';
import React from 'react';
import { checkboxStyles, containerStyles, labelStyles, togglerStyles } from './styles';
import { paragraphDimmedStyles } from '../../styles';

interface TogglerProps {
  label?: string | JSX.Element;
  onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isToggled: boolean;
  isDisabled?: boolean;
  id: string;
  className?: string;
}

const Toggler: React.FC<TogglerProps> = (props) => {
  const { onToggle, isToggled, isDisabled = false, id, label, className, ...rest } = props;
  const theme = useTheme();
  return (
    <div className={className} css={[containerStyles, paragraphDimmedStyles]}>
      {Boolean(label) && <div>{label}</div>}
      <input
        title="toggler"
        disabled={isDisabled}
        onChange={onToggle}
        checked={isToggled}
        id={id}
        type="checkbox"
        css={checkboxStyles}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <label htmlFor={id} css={labelStyles(isToggled, isDisabled, theme)}>
        <span css={togglerStyles(isToggled, isDisabled, theme)} />
      </label>
    </div>
  );
};

export default Toggler;
