/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { fromUnixTime, formatDistance, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DeviceLatestSync_device$key } from './__generated__/DeviceLatestSync_device.graphql';
import { DeviceLatestSyncProps } from './types';
import { spacingStyles } from './styles';
import getTimeFormatString from './utils';
import { LocaleContext } from '../../../providers/LocaleProvider/LocaleProvider';

const DeviceLatestSync: React.FC<DeviceLatestSyncProps> = ({ device, className }) => {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation();
  const now = new Date();

  const { latestSync } = useFragment<DeviceLatestSync_device$key>(
    graphql`
      fragment DeviceLatestSync_device on Device {
        latestSync {
          unixTimeStampSeconds
        }
      }
    `,
    device,
  );

  if (latestSync?.unixTimeStampSeconds) {
    const timeStamp = fromUnixTime(latestSync.unixTimeStampSeconds);

    return (
      <div css={className}>
        <span>
          {format(timeStamp, getTimeFormatString(now, timeStamp), {
            locale,
          })}
        </span>
        <span css={spacingStyles}>({formatDistance(timeStamp, now, { addSuffix: true, locale })})</span>
      </div>
    );
  }
  return <span>{t('WP.ERROR.NO_DATA')}</span>;
};

export default DeviceLatestSync;
