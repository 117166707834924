export interface RemedyTranslations {
  [index: string]: string;
}

const remedyTranslations: RemedyTranslations = {
  /* HIGH TEMPERATURE ALARM */
  HIGH_TEMPERATURE_ALARM_PROBLEM: `The liquid temperature has reached a critical level that requires immediate attention.`,
  HIGH_TEMPERATURE_ALARM_RISK: `When the liquid temperature exceeds the standard limit of 120 °C there is a high risk of damaging the pump, motor or installation.`,
  HIGH_TEMPERATURE_ALARM_REMEDY_SOLUTION: `
                                          <ul>
                                            <li>Check that there is flow in the system</li>
                                            <li>Check that the pump is not running against a closed valve</li>
                                            <li>Check that strainers and filters are not clogged</li>
                                            <li>Check that the stop function is working</li>
                                            <li>Check that the diaphragm tank is operating with the right pre-pressure</li>
                                          </ul>
                                          `,
  /* HIGH TEMPERATURE WARNING */
  HIGH_TEMPERATURE_WARNING_PROBLEM: `The liquid temperature has reached a level that is higher than normal.`,
  HIGH_TEMPERATURE_WARNING_RISK: `A higher than normal liquid temperature may indicate that the process or equipment is deviating from the optimal range due to a system issue.`,
  HIGH_TEMPERATURE_WARNING_REMEDY_SOLUTION: `
                                          <ul>
                                            <li>Check that there is flow in the system</li>
                                            <li>Check that the pump is not running against a closed valve</li>
                                            <li>Check that the strainers and filters are not clogged</li>
                                            <li>Check that the stop function is working</li>
                                            <li>Check that the diaphragm tank is operating with the right pre-pressure</li>
                                          </ul>
                                            `,
  /* LOW TEMPERATURE ALARM */
  LOW_TEMPERATURE_ALARM_PROBLEM: `The liquid temperature has reached a critical level that requires immediate attention.`,
  LOW_TEMPERATURE_ALARM_RISK: `When the liquid temperature is below the -20 °C standard limit there is a high risk of damaging the pump, motor or installation.`,
  LOW_TEMPERATURE_ALARM_REMEDY_SOLUTION: `
                                        <ul>
                                          <li>Check to ensure that the liquid is not beginning to freeze</li>
                                          <li>Check that there is flow in the system</li>
                                          <li>Check that the pump is not running against a closed valve</li>
                                          <li>Check that the strainers and filters are not clogged</li>
                                        </ul>
                                        `,
  /* LOW TEMPERATURE WARNING */
  LOW_TEMPERATURE_WARNING_PROBLEM: `The liquid temperature has reached a level that is lower than normal.`,
  LOW_TEMPERATURE_WARNING_RISK: `A lower than normal liquid temperature may indicate that the process or equipment is deviating from the optimal range due to a system issue.`,
  LOW_TEMPERATURE_WARNING_REMEDY_SOLUTION: `
                                          <ul>
                                            <li>Check that there is flow in the system</li>
                                            <li>Check that the pump is not running against a closed valve</li>
                                            <li>Check that the strainers and filters are not clogged</li>
                                          </ul>

                                          `,
  /* DRY RUN ALARM */
  DRY_RUN_ALARM_PROBLEM: `Dry running is an undesirable state for the pump that occurs in the total absence of the liquid component of the fluid being transported.`,
  DRY_RUN_ALARM_RISK: `Continuous operation with dry run will damage the shaft seal. Damage of shaft seal can lead to severe damage to the system and process.`,
  DRY_RUN_ALARM_REMEDY_SOLUTION: `
                                   <ul>
                                     <li>Prime the system and vent the pump</li>
                                     <li>The dry run alarm can also occur if the pump is running against closed valve</li>
                                     <li>Dry run can be created by air in the pump or gas evaporated in the pump. Secure good suction condition</li>
                                     <li>Avoid air/gas forming in the pump and secure a decent minimum flow</li>
                                   </ul>
                                   `,
  /* WATER HAMMER ALARM */
  WATER_HAMMER_ALARM_PROBLEM: `The pump has experienced a vibrational shock which could originate from a pressure surge in the fluid (water hammer).
                              <br>
                              The amplitude was sufficient to indicate a water hammer of critical severity.`,
  WATER_HAMMER_ALARM_RISK: `Water hammers of with this severity has the potential to immediately damage the pump and cause premature failure. Amongst potential problems are failure of the hydraulics, reduced motor bearing life time, damaged gaskets and shaft seal leakage.
                            <br><br>
                            Water hammers can also pose a risk to the connected system; especially in regards to fragile parts such as sensors.`,
  WATER_HAMMER_ALARM_REMEDY_SOLUTION: `
                                      The experienced water hammer is assessed to be of critical severity, and thus the pump should be stopped immediately and suitable counter-action should be taken.
                                      <br><br>
                                      It is recommended to actively prevent future events. It is also recommended to inspect the pump for potential failures.
                                      <br><br>
                                      Typical reasons for water hammer events are:
                                        <ul>
                                          <li>Rapid opening/closure of valves</li>
                                          <li>Big pumps with direct start (soft start or a frequency converter is recommended)</li>
                                          <li>High temperature liquids on the suction side that create water hammers at start-up</li>
                                          <li>Bad suction conditions side that create water hammers at start-up</li>
                                        </ul>
                                      <br><br>
                                      If it is experienced that the Water Hammer feature is too sensitive and outputs unwanted or irrelevant alarms, the alarm threshold can be increased. Likewise, the alarm threshold can be lowered if it is experienced that the Water Hammer feature does not output alarms when relevant.
                                     `,
  /* WATER HAMMER WARNING */
  WATER_HAMMER_WARNING_PROBLEM: `The pump has experienced a vibrational shock which could originate from a pressure surge in the fluid (water hammer).
                                <br>
                                The amplitude was sufficient to indicate a water hammer of high severity.
                                `,
  WATER_HAMMER_WARNING_RISK: `If the issue with water hammers of similar severity persists, it could cause excessive wear and premature failure over time. Amongst potential problems are failure of the hydraulics, reduced motor bearing life time, damaged gaskets and shaft seal leakage.
                              <br><br>
                              Water hammers can also pose a risk to the connected system; especially in regards to fragile parts such as sensors.
                              `,
  WATER_HAMMER_WARNING_REMEDY_SOLUTION: `
                                        The experienced water hammer is not assessed to be of critical severity, and thus the pump can be operated for a limited period of time until a suitable opportunity arises for appropriate counter-action.
                                        <br><br>
                                        If issues with frequent water hammers are persistent, it is recommended to actively prevent future events. If the issues have been persistent for a longer period of time, it is also recommended to inspect the pump for potential failures.
                                        <br><br>
                                        Typical reasons for water hammer events are:
                                          <ul>
                                            <li>Rapid opening/closure of valves</li>
                                            <li>Big pumps with direct start (soft start or a frequency converter is recommended)</li>
                                            <li>High temperature liquids on the suction side that create water hammers at start-up</li>
                                            <li>Bad suction conditions side that create water hammers at start-up</li>
                                          </ul>
                                        <br><br>
                                        If it is experienced that the Water Hammer feature is too sensitive and outputs unwanted or irrelevant warnings, the warning threshold can be increased. Likewise, the warning threshold can be lowered if it is experienced that the Water Hammer feature does not output warnings when relevant.
                                        `,
  /* MOTOR BEARING ALARM */
  MOTOR_BEARING_ALARM_PROBLEM: `The motor bearings are showing signs of extensive wear - typically resulting in substantial motor noise and vibration.`,
  MOTOR_BEARING_ALARM_RISK: `The bearing has reached a failure condition with eminent risk of breakdown with loss of pumping capability.`,
  MOTOR_BEARING_WEAR_ALARM_REMEDY_SOLUTION: `
                                            <ul>
                                              <li>Change the worn-down bearing as soon as possible</li>
                                            </ul>
                                            `,
  /* MOTOR BEARING WARNING */
  MOTOR_BEARING_WARNING_PROBLEM: `The motor bearings are starting to show signs of wear - typically resulting in increased motor noise and vibration.`,
  MOTOR_BEARING_WARNING_RISK: `The bearing has exceeded normal operation conditions, and has started development of failure mechanism.`,
  MOTOR_BEARING_WEAR_WARNING_REMEDY_SOLUTION: `
                                              <ul>
                                                <li>Observe / Change the worn-down bearing</li>
                                              </ul>
                                              `,
  /* UNBALANCE ALARM */
  UNBALANCE_ALARM_PROBLEM: `Unbalance is the term used for an asymmetric mass distribution in rotating bodies, and it will typically be detected as pump unit vibrations.`,
  UNBALANCE_ALARM_RISK: `A high unbalance level can cause wear on seals and bearings which can result in a mechanical structure failure - e.g. nuts, bolts, key ways, windings, welds and seals.`,
  UNBALANCE_ALARM_REMEDY_SOLUTION: `
                                    <ul>
                                      <li>Stop the pump and disassemble the mechanical system to locate the source of failure.</li>
                                    </ul>
                                    `,
  /* UNBALANCE WARNING */
  UNBALANCE_WARNING_PROBLEM: `Unbalance is the term used for an asymmetric mass distribution in rotating bodies , and it will typically be detected as pump unit vibrations.`,
  UNBALANCE_WARNING_RISK: `An increased unbalance level can cause wear on seals and bearings which can result in a mechanical structure failure - e.g. nuts, bolts, key ways, windings, welds and seals.`,
  UNBALANCE_WARNING_REMEDY_SOLUTION: `
                                      <ul>
                                        <li>Keep an eye on the pump to see if it continues or developes. In that case it is recommended to stop the pump and disassemble the mechanical system to locate the source of failure.</li>
                                      </ul>
                                      `,
  /* VIBRATION ABSOLUTE ALARM */
  VIBRATION_ABSOLUTE_ALARM_PROBLEM: `The vibrational level of the pump is so severe that it would normally be considered sufficient to cause damage to the pump.`,
  VIBRATION_ABSOLUTE_ALARM_RISK: `A high vibration level can cause excessive wear, premature failure and break down. Mechanical assemble such as nuts, bolts, key ways, weldings, and seals can fail.
                         <br><br>
                         A high vibration level can also be an indication of existing failure or inappropriate circumstances which will develop if not attended to.`,
  VIBRATION_ABSOLUTE_ALARM_REMEDY_SOLUTION: `
                                    Immediate action should be taken in order to reduce the vibration or stop the pump.
                                    <br><br>
                                    It is recommended to stop the pump and disassemble the mechanical system to locate the source of failure.
                                    <br><br>
                                    Typical reasons for a high vibration level are:
                                    <ul>
                                      <li>Poor mounting causing a strutural resonance at operating speed</li>
                                      <li>Unbalance of shaft or impeller</li>
                                      <li>Shaft misalignment</li>
                                      <li>Bearing wear</li>
                                      <li>Externally induced vibration</li>
                                    </ul>
                                  `,
  /* VIBRATION ABSOLUTE WARNING */
  VIBRATION_ABSOLUTE_WARNING_PROBLEM: `The pump is vibrating more than would normally be accepted for unrestricted long-term operation.`,
  VIBRATION_ABSOLUTE_WARNING_RISK: `A high vibration level can cause excessive wear, premature failure and break down. Mechanical assemble such as nuts, bolts, key ways, weldings, and seals can fail.
                            <br><br>
                            A high vibration level can also be an indication of existing failure or inappropriate circumstances which will develop if not attended to.`,
  VIBRATION_ABSOLUTE_WARNING_REMEDY_SOLUTION: `
                                      The pump can be operated for a limited period in this condition until a suitable opportunity arises.
                                      <br><br>
                                      If the warning persists, it is recommended to stop the pump and disassemble the mechanical system to locate the source of failure.
                                      <br><br>
                                      Typical reasons for a high vibration level are:
                                      <br>
                                      <ul>
                                        <li>Poor mounting causing a structural resonance at operating speed</li>
                                        <li>Unbalance of shaft or impeller</li>
                                        <li>Shaft misalignment</li>
                                        <li>Bearing wear</li>
                                        <li>Externally induced vibration</li>
                                      </ul>
                                     `,
  /* VIBRATION RELATIVE WARNING */
  VIBRATION_RELATIVE_WARNING_PROBLEM: `The vibration level has increased significantly and remedial action may be necessary.`,
  VIBRATION_RELATIVE_WARNING_RISK: `An increase in the vibration level may indicate incipient damage or other irregularities. If
                                    potential failures are left unattended, they may develop over time and cause excessive wear,
                                    premature failure and break down.`,
  VIBRATION_RELATIVE_WARNING_REMEDY_SOLUTION: `
                                    The pump can be operated for a limited period in this condition until a suitable opportunity arises.
                                    <br><br>

                                    If the warning persists, it is recommended to stop the pump and disassemble the mechanical system to locate the source of failure..
                                    <br><br>
                                        Typical reasons for a high vibration level are:
                                        <br><br>
                                        <ul>
                                          <li>Service overhaul, repair, or part replacement</li>
                                          <li>Changes in pump mounting</li>
                                          <li>Unbalance of shaft or impeller</li>
                                          <li>Shaft misalignment</li>
                                          <li>Bearing wear</li>
                                          <li>Cavitation</li>
                                          <li>New, externally induced vibration</li>
                                        </ul>
                                        <br>
                                    If modification or changes in the mechanical system has caused a change in the typical vibration level, the monitoring should be re-commissioned.`,

  // MOTOR SPEED VIBRATION RELATIVE WARNING
  VIBRATION_RELATIVE_MOTOR_SPEED_WARNING_PROBLEM: `Motor speed is above the maximum value configured for 'Overall vibration (relative)'`,
  VIBRATION_RELATIVE_MOTOR_SPEED_WARNING_RISK: `The monitoring of 'Overall vibration (relative)' will be less qualified for motor speeds at and above the configured maximum value.`,
  VIBRATION_RELATIVE_MOTOR_SPEED_WARNING_REMEDY_SOLUTION: `Change the configured maximum motor speed for 'Overall vibration (relative)' using GO Remote. <br> Please note that changing the configuration will reset the learning period associated with this feature.`,
  // FLOW RATE VIBRATION RELATIVE
  VIBRATION_RELATIVE_FLOW_RATE_WARNING_PROBLEM: `The flow rate is above the maximum value configured for 'Overall vibration (relative)'`,
  VIBRATION_RELATIVE_FLOW_RATE_WARNING_RISK: `The monitoring of 'Overall vibration (relative)' will be less qualified for flow rates at and above the configured maximum value.`,
  VIBRATION_RELATIVE_FLOW_RATE_WARNING_REMEDY_SOLUTION: `Change the configured maximum flow rate for 'Overall vibration (relative)' using GO Remote. <br>Please note that changing the configuration will reset the learning period associated with this feature.`,
  /* CAVITATION ALARM */
  CAVITATION_ALARM_PROBLEM: `Large amount of imploding bubbles or cavities may cause damage to the pump impeller and/or housing.`,
  CAVITATION_ALARM_RISK: `There is a high likelihood that hydraulics will break, increased shaft seal leakage, reduced bearing lifetime and significantly reduced efficiency. The pump may not be able to deliver the right flow or pressure.`,
  CAVITATION_ALARM_REMEDY_SOLUTION: `
                                    <ul>
                                      <li>Check if the filters are blocked on the inlet side</li>
                                      <li>Check if the inlet pressure is lower than normal</li>
                                      <li>Check if the liquid temperature is higher than normal</li>
                                      <li>Check for operation in the high flow range of the pump curve due to decreasing pressure on the discharge side of pump</li>
                                      <li>Check for low level in tank on the suction side of pump</li>
                                      <li>Check for vortex in feedwater tank</li>
                                    </ul>
                                    `,
  /* CAVITATION WARNING */
  CAVITATION_WARNING_PROBLEM: `Large amount of imploding bubbles or cavities may cause damage to the pump impeller and /or housing.`,
  CAVITATION_WARNING_RISK: `There is a likelihood that the hydraulics will fail, shaft seal leakage, reduced bearing lifetime and reduced efficiency. The pump may not be able to deliver the right flow or pressure. `,
  CAVITATION_WARNING_REMEDY_SOLUTION: `
                                      <ul>
                                        <li>Check if filters are blocked on the inlet side</li>
                                        <li>Check if inlet pressure is lower than normal</li>
                                        <li>Check if liquid is higher than normal</li>
                                        <li>Check for operation in the high flow range of the pump curve due to decreasing pressure on the discharge side of pump</li>
                                        <li>Check for low level in tank on the suction side of pump</li>
                                        <li>Check for vortex in feedwater tank</li>
                                      </ul>
                                      `,
  /* SENSOR FAULT WARNING */
  SENSOR_FAULT_WARNING_PROBLEM: `Sensor has been disconnected or is no longer able to deliver valid values.`,
  SENSOR_FAULT_WARNING_RISK: `The system is not able to operate as intended.`,
  SENSOR_FAULT_WARNING_REMEDY_SOLUTION: `
                                    <ul>
                                      <li>Check if the sensor is connected properly</li>
                                      <li>You might need to replace the sensor</li>
                                      <li>Call Grundfos Service if you need help to solve the issue</li>
                                    </ul>
                                    `,
  /* GIM IO LIMIT EXCEEDED 1 */
  GIM_IO_LIMIT_EXCEEDED_1_WARNING_PROBLEM: `Limit 1 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  GIM_IO_LIMIT_EXCEEDED_1_ALARM_PROBLEM: `Limit 1 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  /* GIM IO LIMIT EXCEEDED 2 */
  GIM_IO_LIMIT_EXCEEDED_2_WARNING_PROBLEM: `Limit 2 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  GIM_IO_LIMIT_EXCEEDED_2_ALARM_PROBLEM: `Limit 2 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  /* GIM IO LIMIT EXCEEDED 3 */
  GIM_IO_LIMIT_EXCEEDED_3_WARNING_PROBLEM: `Limit 3 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  GIM_IO_LIMIT_EXCEEDED_3_ALARM_PROBLEM: `Limit 3 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  /* GIM IO LIMIT EXCEEDED 4 */
  GIM_IO_LIMIT_EXCEEDED_4_WARNING_PROBLEM: `Limit 4 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  GIM_IO_LIMIT_EXCEEDED_4_ALARM_PROBLEM: `Limit 4 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  /* GIM IO LIMIT EXCEEDED 5 */
  GIM_IO_LIMIT_EXCEEDED_5_WARNING_PROBLEM: `Limit 5 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  GIM_IO_LIMIT_EXCEEDED_5_ALARM_PROBLEM: `Limit 5 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  /* GIM IO LIMIT EXCEEDED 6 */
  GIM_IO_LIMIT_EXCEEDED_6_WARNING_PROBLEM: `Limit 6 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  GIM_IO_LIMIT_EXCEEDED_6_ALARM_PROBLEM: `Limit 6 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  /* GIM IO LIMIT EXCEEDED 7 */
  GIM_IO_LIMIT_EXCEEDED_7_WARNING_PROBLEM: `Limit 7 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  GIM_IO_LIMIT_EXCEEDED_7_ALARM_PROBLEM: `Limit 7 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  /* GIM IO LIMIT EXCEEDED 8 */
  GIM_IO_LIMIT_EXCEEDED_8_WARNING_PROBLEM: `Limit 8 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,
  GIM_IO_LIMIT_EXCEEDED_8_ALARM_PROBLEM: `Limit 8 exceeded on GiM-IO: The configured parameter has exceeded its limit.`,

  /* E MOTOR LIMIT EXCEEDED 1 */
  E_MOTOR_LIMIT_EXCEEDED_1_WARNING_PROBLEM: `Limit 1 exceeded on MGE/CUE: The configured parameter has exceeded its limit.`,
  E_MOTOR_LIMIT_EXCEEDED_1_ALARM_PROBLEM: `Limit 1 exceeded on MGE/CUE: The configured parameter has exceeded its limit.`,
  /* E MOTOR LIMIT EXCEEDED 2 */
  E_MOTOR_LIMIT_EXCEEDED_2_WARNING_PROBLEM: `Limit 2 exceeded on MGE/CUE: The configured parameter has exceeded its limit.`,
  E_MOTOR_LIMIT_EXCEEDED_2_ALARM_PROBLEM: `Limit 2 exceeded on MGE/CUE: The configured parameter has exceeded its limit.`,
};

export default remedyTranslations;
