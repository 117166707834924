import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../utils/media-queries/media-queries';

const eventSkeletonStyles = css`
  grid-column: 1/-1;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: 8rem auto 7.5rem;
  height: 100%;
  width: 100%;
  font-size: 1.3rem;

  ${dsmMediaQuery('medium device')} {
    grid-template-rows: 11.5rem auto 7rem;
  }

  ${dsmMediaQuery('large device')} {
    grid-template-rows: 8.4375rem auto 7rem;
  }
`;

const headerStyles = (theme: Theme): SerializedStyles => css`
  grid-area: header;
  width: 100%;
  border-bottom: 0.125rem solid ${theme.colors.primaryBorder};
  display: grid;
  grid-template-columns: 2.1rem auto;
  grid-template-rows: 2.1rem 1.7rem 1.5rem 1.5rem;
  grid-template-areas:
    'icon title'
    'device device'
    'location location'
    'subtitle subtitle';

  ${dsmMediaQuery('medium device')} {
    grid-template-columns: 5rem auto;
    grid-template-rows: 5rem 2rem 1.8rem 1.8rem;
    padding: 0 4rem;
  }

  ${dsmMediaQuery('large device')} {
    grid-template-columns: 6rem auto auto;
    grid-template-rows: 6rem auto;
    grid-template-areas:
      'icon title .'
      'device location subtitle';
    padding: 0;
  }
`;

const bodyStyles = css`
  grid-area: body;

  ${dsmMediaQuery('medium device')} {
    padding: 2rem 0;
  }

  ${dsmMediaQuery('large device')} {
    padding: 4rem 0;
    display: grid;
    grid-template-areas: 'text image';
  }
`;

const footerStyles = (theme: Theme): SerializedStyles => css`
  grid-area: footer;
  display: grid;
  grid-template-areas:
    'phone'
    'button';
  border-top: 0.125rem solid ${theme.colors.primaryBorder};

  ${dsmMediaQuery('large device')} {
    grid-template-areas: 'phone . button';
    background: rgba(34, 37, 39, 0.7); /*Make sure this color has an opacity of less than 1*/
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(34, 37, 39, 0.9) 15%,
      rgba(34, 37, 39, 0.9) 85%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 4000; // to prevent device img in overlapping footer in Firefox
  }
`;

const iconStyles = css`
  grid-area: icon;
  height: 100%;
`;

const titleStyles = css`
  grid-area: title;
  padding-left: 0.5rem;
  font-size: 2rem;

  ${dsmMediaQuery('medium device')} {
    align-self: center;
    font-size: 3rem;
    padding-left: 1.5rem;
  }
`;

const deviceStyles = css`
  grid-area: device;
  padding: 0.2rem 4rem 0 4rem;

  ${dsmMediaQuery('medium device')} {
    padding: 0.2rem 8rem 0 8rem;
  }

  ${dsmMediaQuery('large device')} {
    padding: 0 0 0.5rem 0;
    align-self: end;
  }
`;

const locationStyles = css`
  grid-area: location;
  padding: 0 5rem;

  ${dsmMediaQuery('large device')} {
    padding: 0 15rem 0.5rem 0.5rem;
    align-self: end;
  }
`;

const subTitleStyles = css`
  grid-area: subtitle;

  ${dsmMediaQuery('medium device')} {
    padding: 0 16rem;
  }

  ${dsmMediaQuery('large device')} {
    padding: 0 0 0.5rem 15rem;
    align-self: end;
  }
`;

const bodyTitleStyles = css`
  margin-bottom: 1.6rem;

  ${dsmMediaQuery('medium device')} {
    max-width: 20rem;
  }
`;

const bodyLastLineStyles = css`
  ${dsmMediaQuery('medium device')} {
    max-width: 40rem;
  }
`;

const bodyFirstLineStyles = css`
  ${dsmMediaQuery('large device')} {
    max-width: 45rem;
  }
`;

const phoneStyles = css`
  grid-area: phone;
  align-self: center;

  ${dsmMediaQuery('medium device')} {
    padding-right: 20rem;
  }
`;

const buttonStyles = css`
  grid-area: button;
  align-self: center;

  ${dsmMediaQuery('large device')} {
    padding-left: 10rem;
  }
`;

const textStyles = css`
  grid-area: text;
`;

const deviceImgContainerStyles = css`
  display: none;

  ${dsmMediaQuery('large device')} {
    position: fixed;
    left: 50%;
    transform: translateX(25%);

    display: grid;
    grid-area: image;
    justify-self: center;
    padding: 1rem 0;
  }
`;

const deviceImageStyles = css`
  width: 75%;
`;

export {
  eventSkeletonStyles,
  headerStyles,
  bodyStyles,
  footerStyles,
  iconStyles,
  titleStyles,
  subTitleStyles,
  deviceStyles,
  locationStyles,
  bodyTitleStyles,
  bodyLastLineStyles,
  bodyFirstLineStyles,
  phoneStyles,
  buttonStyles,
  deviceImgContainerStyles,
  deviceImageStyles,
  textStyles,
};
