/**
 * @generated SignedSource<<a9c697ced82c5f9dc922852ab9051aea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SectionQuery$variables = {
  id: string;
};
export type SectionQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"PageContent_location" | "SectionAssets_section" | "SectionEvents_section">;
  } | null;
};
export type SectionQuery = {
  variables: SectionQuery$variables;
  response: SectionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceConnection",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "descendantDeviceInfo",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PageContent_location"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SectionAssets_section"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SectionEvents_section"
              }
            ],
            "type": "Section",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "typeCodes",
                    "value": [
                      "SECTION"
                    ]
                  }
                ],
                "concreteType": "LocationConnection",
                "kind": "LinkedField",
                "name": "locations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/),
                              (v7/*: any*/)
                            ],
                            "type": "Section",
                            "abstractKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "locations(typeCodes:[\"SECTION\"])"
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "exclude",
                    "value": "CLEARED"
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 50
                  }
                ],
                "concreteType": "EventConnection",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Event",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Device",
                            "kind": "LinkedField",
                            "name": "device",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "location",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v5/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "events(exclude:\"CLEARED\",first:50)"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "Installation",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "Section",
                    "abstractKey": null
                  }
                ],
                "type": "Location",
                "abstractKey": "__isLocation"
              }
            ],
            "type": "Section",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b63b4d054629ba7382292da109429c4",
    "id": null,
    "metadata": {},
    "name": "SectionQuery",
    "operationKind": "query",
    "text": "query SectionQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Section {\n      ...PageContent_location\n      ...SectionAssets_section\n      ...SectionEvents_section\n    }\n    id\n  }\n}\n\nfragment ActiveEventListItem_item on Event {\n  createdAt\n  device {\n    uuid\n    name\n    location {\n      __typename\n      name\n      id\n    }\n    id\n  }\n  type\n  code\n}\n\nfragment DeviceListItem_device on Device {\n  uuid\n  name\n  descendantDeviceInfo {\n    __typename\n    type\n  }\n}\n\nfragment DeviceList_devices on DeviceConnection {\n  edges {\n    node {\n      uuid\n      ...DeviceListItem_device\n      id\n    }\n  }\n}\n\nfragment PageContent_location on Location {\n  __isLocation: __typename\n  ... on Installation {\n    name\n    deviceCount\n  }\n  ... on Section {\n    name\n    deviceCount\n  }\n}\n\nfragment SectionAssets_section on Section {\n  locations(typeCodes: [SECTION]) {\n    totalCount\n    ...SectionList_sections\n  }\n  devices {\n    ...DeviceList_devices\n  }\n}\n\nfragment SectionEvents_section on Section {\n  __typename\n  events(exclude: CLEARED, first: 50) {\n    edges {\n      node {\n        __typename\n        id\n        ...ActiveEventListItem_item\n      }\n    }\n  }\n}\n\nfragment SectionListItem_section on Section {\n  name\n  devices {\n    ...DeviceList_devices\n  }\n}\n\nfragment SectionList_sections on LocationConnection {\n  edges {\n    node {\n      __typename\n      uuid\n      ...SectionListItem_section\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "efbf35e19ac7d27fb3e8de272c0f4e7a";

export default node;
