import { useEffect, useContext, useState } from 'react';
import { AuthContext } from '@gic/react-auth';
import { User } from 'oidc-client';

const useAuthUser = (): User | undefined => {
  const auth = useContext(AuthContext);
  const [data, setData] = useState<User | undefined>(undefined);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const user = await auth?.getUser();

        if (user) {
          setData(user);
        } else {
          // eslint-disable-next-line no-console
          console.warn('Failed to get user!');
        }
      } catch (error) {
        // We catch this specific error so it doesn't pollute Sentry
        if (error instanceof Error && error.message === 'No state in response') {
          // eslint-disable-next-line no-console
          console.info('Unauthenticated user detected. No action required');
        } else {
          throw error;
        }
      }
    })();
  }, [auth]);
  return data;
};

export default useAuthUser;
