import React from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay/hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { EventFooterProps } from './types';
import { assetBtnStyles, servicePhoneStyles, textStyles, footerStyles, assetBtnDisabledStyles } from './styles';

const eventFooterFragment = graphql`
  fragment EventFooter_item on Event {
    device {
      uuid
    }
  }
`;

const EventFooter: React.FC<EventFooterProps> = ({
  eventDetailsData,
  onGoToAssetDetails,
  onLinkServicePhoneClick,
  onClose,
}) => {
  const event = useFragment(eventFooterFragment, eventDetailsData);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div css={footerStyles(theme.colors.primaryBorder)}>
      <a
        href="tel:+45-87-50-44-44"
        onClick={(): void => {
          if (onLinkServicePhoneClick) onLinkServicePhoneClick();
        }}
      >
        <span css={[textStyles, servicePhoneStyles]}>Grundfos Service (+45 87504444)</span>
      </a>
      <button
        css={[assetBtnStyles(theme), event ? null : assetBtnDisabledStyles]}
        type="button"
        data-test-id="go-to-asset-btn"
        onClick={(): void => {
          onGoToAssetDetails();
          if (onClose) onClose();
          if (!location.pathname.includes(`/devices/${event?.device.uuid}`)) {
            navigate(`/devices/${event?.device.uuid}`);
          }
        }}
      >
        <span css={textStyles}>Go to asset details</span>
      </button>
    </div>
  );
};

export default EventFooter;
