/**
 * @generated SignedSource<<abf1166a906fb79e6e7d321f1f558020>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateContactPersonInput = {
  name: string;
  phone: string;
  deviceId: string;
};
export type EditInformationMutation$variables = {
  input?: UpdateContactPersonInput | null;
};
export type EditInformationMutation$data = {
  readonly updateContactPerson: ReadonlyArray<{
    readonly deviceId: string | null;
    readonly tag: string | null;
    readonly code: string | null;
    readonly value: string | null;
    readonly id: string | null;
  } | null> | null;
};
export type EditInformationMutation = {
  variables: EditInformationMutation$variables;
  response: EditInformationMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateContactPersonPayload",
    "kind": "LinkedField",
    "name": "updateContactPerson",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditInformationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditInformationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6eb7e6a3ad30290551e5ae2c8e6fc8ba",
    "id": null,
    "metadata": {},
    "name": "EditInformationMutation",
    "operationKind": "mutation",
    "text": "mutation EditInformationMutation(\n  $input: UpdateContactPersonInput\n) {\n  updateContactPerson(input: $input) {\n    deviceId\n    tag\n    code\n    value\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "af5fcd49bbd02bcd0d69ebbb8800abee";

export default node;
