import { css } from '@emotion/react';
import React, { Suspense } from 'react';
import { pageStyles } from '../../styles';
import Modal from '../Modal/Modal';
import { btnClose, closeButtonContainerStyles, containerStyles } from './styles';
import { EventProps } from './types';
import closeIcn from '../../assets/images/close.svg';
import Event from './Event';
import EventSkeletonLoader from './EventSkeletonLoader/EventSkeletonLoader';

const EventContainer: React.FC<EventProps> = ({
  id,
  onClose,
  onButtonCloseClick,
  onButtonGoToAssetDetailsClick,
  onComponentDidMount,
  onComponentWillUnmount,
  onLinkServicePhoneClick,
  show,
}) => {
  if (!show && onClose) onClose();

  return (
    <Modal
      show={show}
      styles={css`
        z-index: 8500;
      `}
    >
      <div css={[pageStyles, containerStyles]}>
        <div css={closeButtonContainerStyles}>
          <button
            data-test-id="close-button"
            css={btnClose}
            type="button"
            onClick={(): void => {
              if (onClose) onClose();
              if (onButtonCloseClick) onButtonCloseClick();
            }}
          >
            <img src={closeIcn} alt="closeIcn" />
          </button>
        </div>
        <Suspense fallback={<EventSkeletonLoader />}>
          <Event
            id={id}
            show={show}
            onClose={onClose}
            onButtonCloseClick={onButtonCloseClick}
            onButtonGoToAssetDetailsClick={onButtonGoToAssetDetailsClick}
            onComponentDidMount={onComponentDidMount}
            onComponentWillUnmount={onComponentWillUnmount}
            onLinkServicePhoneClick={onLinkServicePhoneClick}
          />
        </Suspense>
      </div>
    </Modal>
  );
};

export default EventContainer;
