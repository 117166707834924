import { css } from '@emotion/react';
import React from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay/hooks';
import SimpleBar from 'simplebar-react';
import parse from 'html-react-parser';
import dsmMediaQuery, { breakPoints } from '../../utils/media-queries/media-queries';
import { useWindowCurrentWidth } from '../../hooks';
import EventBodySection from './EventBodySection/EventBodySection';
import problemIcn from '../../assets/images/problem.svg';
import riskIcn from '../../assets/images/risk.svg';
import remedyIcn from '../../assets/images/remedy.svg';
import { remedyText, bodyContainer, deviceImage, deviceImgContainer, listStyles, remedyTextContainer } from './styles';
import { EventBodyProps } from './types';
import remedyTranslations from '../../utils/mappings/remedy-translations';
import deviceImg from '../../assets/images/device.png';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';

const eventBodyFragment = graphql`
  fragment EventBody_items on Event {
    type
    code
  }
`;

const EventBody: React.FC<EventBodyProps> = ({ eventDetailsData }) => {
  const windowWidth = useWindowCurrentWidth();

  const event = useFragment(eventBodyFragment, eventDetailsData);
  return (
    <div
      css={css`
        grid-area: eventBody;
        overflow-y: scroll;
        ${dsmMediaQuery('medium device')} {
          overflow-y: hidden;
        }
      `}
    >
      <SimpleBar style={windowWidth > breakPoints['medium device'] ? { maxHeight: 'calc(100%)' } : {}}>
        <div css={bodyContainer} data-test-id="remedy-container">
          <div css={remedyTextContainer}>
            <div>
              {remedyTranslations[`${event?.code}_${event?.type}_PROBLEM`] && (
                <EventBodySection title="What is the problem" icon={problemIcn}>
                  <p css={remedyText}>{parse(remedyTranslations[`${event?.code}_${event?.type}_PROBLEM`])}</p>
                </EventBodySection>
              )}
              {remedyTranslations[`${event?.code}_${event?.type}_RISK`] && (
                <EventBodySection title="What is the risk" icon={riskIcn}>
                  <p css={remedyText}>{parse(remedyTranslations[`${event?.code}_${event?.type}_RISK`])}</p>
                </EventBodySection>
              )}
              {remedyTranslations[`${event?.code}_${event?.type}_REMEDY_SOLUTION`] && (
                <EventBodySection title="How to solve" icon={remedyIcn}>
                  <div css={[remedyText, listStyles]}>
                    {parse(remedyTranslations[`${event?.code}_${event?.type}_REMEDY_SOLUTION`])}
                  </div>
                </EventBodySection>
              )}
            </div>
          </div>
          <div css={deviceImgContainer}>
            <img src={deviceImg} css={deviceImage} alt="deviceImg" />
          </div>
        </div>
      </SimpleBar>
    </div>
  );
};

export default EventBody;
