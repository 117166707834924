/**
 * @generated SignedSource<<5bdbd3605c6994697b92e65cae3d76b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EditInformationQuery$variables = {
  id: string;
};
export type EditInformationQuery$data = {
  readonly node: {
    readonly staticdata?: ReadonlyArray<{
      readonly id: string;
      readonly value: string | null;
      readonly code: string | null;
    } | null> | null;
  } | null;
};
export type EditInformationQuery = {
  variables: EditInformationQuery$variables;
  response: EditInformationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Metadata",
      "kind": "LinkedField",
      "name": "staticdata",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditInformationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditInformationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67ad080ef359bbbabe34a71d7c0d8980",
    "id": null,
    "metadata": {},
    "name": "EditInformationQuery",
    "operationKind": "query",
    "text": "query EditInformationQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Device {\n      staticdata {\n        id\n        value\n        code\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4aa1312aed8b2a6a46869eb6b0845c5c";

export default node;
