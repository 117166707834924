import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const headerContainerStyles = (borderColor: string): SerializedStyles => css`
  display: grid;
  justify-items: center;
  justify-content: center;
  grid-area: header;
  grid-template-columns: max-content;
  grid-template-areas:
    'type-code'
    'sub-text';
  border-bottom: 0.125rem solid ${borderColor};
  padding-bottom: 0.75rem;
  ${dsmMediaQuery('large device')} {
    grid-row-gap: 1rem;
    grid-template-areas: 'type-code .' 'sub-text timestamp';
    justify-content: unset;
  }
`;

const typeCodeStyles = css`
  grid-area: type-code;
  display: flex;
  align-items: center;
  ${dsmMediaQuery('large device')} {
    justify-self: left;
  }
`;

const subTextStyles = css`
  grid-area: sub-text;
  grid-template-areas: 'device' 'section';
  display: grid;
  justify-items: center;
  ${dsmMediaQuery('large device')} {
    grid-template-areas: 'device section';
    grid-template-columns: max-content auto;
    grid-row-gap: 1rem;
    justify-self: start;
    grid-column-gap: 1rem;
  }
`;

const eventCodeStyles = (theme: Theme): SerializedStyles => css`
  font-size: 1.5rem;
  color: ${theme.colors.secondaryText};
  font-weight: 600;
  text-transform: uppercase;
  ${dsmMediaQuery('medium device')} {
    font-size: 3rem;
  }
`;

const textIconContainerStyles = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1rem auto;
  align-items: center;
  grid-column-gap: 0.2rem;
  color: #abaeaf;
  text-transform: uppercase;
`;

const eventIconStyles = css`
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
  ${dsmMediaQuery('medium device')} {
    margin-right: 1rem;
    width: 5.25rem;
    height: 5.25rem;
  }
`;

const timestampStyles = css`
  ${dsmMediaQuery('large device')} {
    grid-area: timestamp;
    justify-self: end;
  }
`;

const deviceStyles = css`
  grid-area: device;
`;

const sectionStyles = css`
  grid-area: section;
`;

const datetimeStyles = css`
  display: grid;
  text-transform: lowercase;
  grid-template-columns: auto auto;
  grid-column-gap: 0.3rem;
`;

export {
  headerContainerStyles,
  eventCodeStyles,
  textIconContainerStyles,
  eventIconStyles,
  timestampStyles,
  deviceStyles,
  sectionStyles,
  typeCodeStyles,
  subTextStyles,
  datetimeStyles,
};
