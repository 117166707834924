import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { graphql, useLazyLoadQuery, useMutation } from 'relay-hooks';
import { useParams } from 'react-router';
import { toGlobalId } from 'graphql-relay';
import { formContentStyles, formStyles } from './styles';
import { EditInformationForm, EditInformationProps, SnackbarProps } from './types';
import { NoDataAvailableMessage, Snackbar, TextInputField } from '../../../../../components';
import { EditInformationQuery } from './__generated__/EditInformationQuery.graphql';
import { EditInformationMutation } from './__generated__/EditInformationMutation.graphql';

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  phone: Yup.string(),
});

const EditInformation: React.FC<EditInformationProps> = ({ formId, loadingCallback }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>();
  const data = useLazyLoadQuery<EditInformationQuery>(
    graphql`
      query EditInformationQuery($id: ID!) {
        node(id: $id) {
          ... on Device {
            staticdata {
              id
              value
              code
            }
          }
        }
      }
    `,
    {
      id: toGlobalId('Device', id || ''),
    },
  );

  const [commit] = useMutation<EditInformationMutation>(graphql`
    mutation EditInformationMutation($input: UpdateContactPersonInput) {
      updateContactPerson(input: $input) {
        deviceId
        tag
        code
        value
        id
      }
    }
  `);

  if (!data.data?.node) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;
  const { staticdata } = data.data.node;

  const name = staticdata?.find((item) => item?.code === 'sdcs:CONTACT_PERSON_NAME')?.value || '';
  const phone = staticdata?.find((item) => item?.code === 'sdcs:CONTACT_PERSON_PHONE')?.value || '';

  const closeSnackbarAfterDelay = () => setTimeout(() => setShowSnackbar(false), 3000);

  const initialValues: EditInformationForm = {
    name,
    phone,
  };

  return (
    <div css={formContentStyles}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (id) {
            loadingCallback(true);
            commit({
              variables: {
                input: {
                  deviceId: id,
                  name: values.name,
                  phone: values.phone,
                },
              },
              onCompleted: () => {
                loadingCallback(false);
                // eslint-disable-next-line no-console
                setSnackbarProps({ snackbarType: 'SUCCESS', message: t('WP.CONTACT_PERSON_SAVE_SUCCESS') });

                setShowSnackbar(true);
                closeSnackbarAfterDelay();
                if (data) data.retry();
              },
              onError: (error: any) => {
                loadingCallback(false);
                // eslint-disable-next-line no-console
                console.error(error);
                setSnackbarProps({ snackbarType: 'WARNING', message: t('WP.CONTACT_PERSON_SAVE_FAILED') });
                setShowSnackbar(true);
              },
            });
          }
        }}
      >
        <Form id={formId} css={formStyles}>
          {showSnackbar && (
            <Snackbar
              snackbarType={snackbarProps?.snackbarType}
              message={snackbarProps?.message || ''}
              onClose={() => setShowSnackbar(false)}
            />
          )}
          <TextInputField
            css={css`
              grid-area: name;
              align-items: flex-start;
            `}
            name="name"
            placeholder={t('WP.PUMPINFORMATION_EDIT_NAME_PLACEHOLDER')}
            label={t('WP.PUMPINFORMATION_EDIT_NAME_LABEL')}
          />
          <TextInputField
            css={css`
              grid-area: phone;
              align-items: flex-start;
            `}
            name="phone"
            placeholder={t('WP.PUMPINFORMATION_EDIT_PHONE_PLACEHOLDER')}
            label={t('WP.PUMPINFORMATION_EDIT_PHONE_LABEL')}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default EditInformation;
