import { SerializedStyles, Theme } from '@emotion/react';
import React from 'react';
import { tileStyles } from './styles';
import { TileProps } from './types';

const Tile: React.FC<TileProps> = ({ Body, Footer, Header, styles }) => {
  const toArray = (a: SerializedStyles | SerializedStyles[] | undefined): (SerializedStyles | undefined)[] =>
    Array.isArray(a) ? a : [a];

  return (
    <div
      css={(theme: Theme): Array<SerializedStyles | undefined> => [
        tileStyles(theme, Body, Footer, Header),
        ...toArray(styles),
      ]}
      data-test-id="tile"
    >
      {Header}
      {Body}
      {Footer}
    </div>
  );
};

export default Tile;
