import { css, keyframes, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../../utils/media-queries/media-queries';

const closeButtonStyles = css`
  padding: 0;
  min-width: 0;
  ${dsmMediaQuery('medium device')} {
    min-width: unset;
  }
`;

const modalStyles = css`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const containerStyles = (theme: Theme) => css`
  background-color: ${theme.colors.secondaryBackground};
  min-height: 50vh;
  min-width: 50vw;
  display: grid;
  gap: 1rem;
  grid-template-rows: max-content max-content max-content 1fr;
  padding: 1rem;
`;

// temp styles - at some point maybe the user will be able to upload their own images
const imageContainerStyles = css`
  display: none;
  ${dsmMediaQuery('medium device')} {
    display: flex;
    height: 50%;
    max-width: 18.75rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const mainContentContainerStyles = css`
  display: grid;
  gap: 1rem;
  ${dsmMediaQuery('medium device')} {
    grid-template-columns: minmax(18.75rem, 2fr) 3fr;
  }
`;

const infoContainserStyles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  div:last-child {
    border-bottom: unset;
  }
`;

const headerContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const footerStyles = css`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 1rem;
`;

const footerButtons = css`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
`;

const sectionStyles = css`
  padding-bottom: 0.5rem;
`;

const installationNoteStyles = css`
  grid-template-columns: max-content minmax(0, 18rem);
  text-align: right;
  gap: 1rem;
  justify-items: flex-end;
`;

const slideUp = keyframes`
from {
  transform: translate(0%, 50%);
}
to {
  transform: translate(0%,0%);
}
`;

const displayStyles = css`
  animation: ${slideUp} 0.5s ease forwards;
  transform: translate(-50%, -0%);
  background: rgba(18, 21, 22, 0.7); // (primaryBackground in rgba) Make sure this color has an opacity of less than 1
  backdrop-filter: blur(2px); // the higher px the more blurry
`;

const formContentStyles = ({ colors }: Theme): SerializedStyles => css`
  display: grid;
  padding-bottom: 0.5rem;
  background-color: ${colors.secondaryBackground};
`;

const formStyles: SerializedStyles = css`
  display: grid;
  grid-template-rows: '1fr';
  grid-template-areas:
    'name'
    'phone';
  gap: 1rem;
  input,
  select {
    padding: 0.5rem;
  }
`;

const navStyles = css`
  padding-bottom: 1rem;
`;

const navItemStyles = (theme: Theme): SerializedStyles => css`
  display: inline-block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  margin: 0.5rem 2rem 0.5rem 0.5rem;
  padding-bottom: 0.4rem;
  font-weight: 600;
  &:hover,
  &.active {
    padding-bottom: 0.2rem;
    color: ${theme.colors.secondaryText};
    border-bottom: 0.2rem solid ${theme.colors.blue.blue3.baseColor};
  }
`;

export {
  navItemStyles,
  navStyles,
  formStyles,
  formContentStyles,
  installationNoteStyles,
  containerStyles,
  sectionStyles,
  footerStyles,
  footerButtons,
  mainContentContainerStyles,
  modalStyles,
  headerContainerStyles,
  closeButtonStyles,
  imageContainerStyles,
  infoContainserStyles,
  displayStyles,
};
