import React from 'react';
import { css } from '@emotion/react';

const indent = css`
  padding-left: 2rem;
`;

const Indent: React.FC<{ children: JSX.Element }> = ({ children }) => <div css={indent}>{children}</div>;

export default Indent;
