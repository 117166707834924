import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import {
  containerStyles,
  flowControlContainer,
  remoteContainerContentStyles,
  remoteContainerStyles,
  remoteHeader,
} from '../styles';
import RemoteHeader from '../RemoteHeader';
import RemoteSection from '../RemoteSection';
import { Divider, Indent, InfoItem, Toggler } from '../../../../components';
import { SupportedGeniValueName } from '../types';
import {
  delayOptions,
  getControlModeDefault,
  getControlModeLabel,
  getLabelFromValue,
  getSlowModeValue,
  sensitivityOptions,
} from '../utils';

const DefaultContainer = ({ children }: { children: JSX.Element | JSX.Element[] }) => <div>{children}</div>;

const RemoteLayout = ({
  Header,
  OperationModeSection,
  ControlModeSection,
  FlowControlSection,
  Container,
  Message,
}: {
  Container?: ({ children }: { children: JSX.Element | JSX.Element[] }) => JSX.Element;
  Message?: JSX.Element;
  Header: JSX.Element;
  OperationModeSection: JSX.Element;
  ControlModeSection: JSX.Element;
  FlowControlSection: JSX.Element | false | null;
}) => {
  const SelectedContainer = Container || DefaultContainer;
  return (
    <SelectedContainer css={containerStyles}>
      <div css={remoteContainerStyles}>
        <div css={remoteHeader}>
          {Boolean(Message) && Message}
          {Header}
        </div>
        <div css={remoteContainerContentStyles}>
          <div>
            {OperationModeSection}
            {ControlModeSection}
          </div>
        </div>
        <div css={flowControlContainer}>{FlowControlSection}</div>
      </div>
    </SelectedContainer>
  );
};

export const ReadMode = ({
  isHidden,
  children,
  value,
  noValueLabel,
  hideDivider = false,
}: {
  isHidden: boolean;
  value: unknown;
  noValueLabel: string;
  children: JSX.Element;
  hideDivider?: boolean;
}) => {
  const { t } = useTranslation();
  if (isHidden) return null;
  if (value === undefined || value === null)
    return (
      <>
        <InfoItem noBorder label={noValueLabel} inActive value={t('WP.REMOTE.READ_MODE_UNKOWN_VALUE')} />
        {hideDivider ? null : <Divider />}
      </>
    );
  return (
    <>
      {children}
      {hideDivider ? null : <Divider />}
    </>
  );
};

const ReadModeFlowControl = ({
  children,
  value,
  label,
  showActive,
  isHidden = false,
  hideDivider = false,
}: {
  value: string;
  label: string;
  children: JSX.Element;
  showActive: boolean;
  isHidden?: boolean;
  hideDivider?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <ReadMode isHidden={isHidden} value={value} noValueLabel={label} hideDivider={hideDivider}>
      {showActive ? children : <InfoItem noBorder label={<Indent>{t(label)}</Indent>} inActive value={value} />}
    </ReadMode>
  );
};

type GqlNumber = number | null | undefined;
type GqlString = string | null | undefined;

const ReadOnlyRemote = ({
  controlMode,
  operation,
  autoDeaeration,
  pulseMemory,
  pulseDosingVolume,
  manualDosingFlowUnit,
  manualDosingVolume,
  slowMode,
  flowControl,
  delay,
  sensitivity,
  maxPressureAlarm,
  maxPressureAlamUnit,
  minPressureAsAlarm,
  autoFlowAdapt,
  pumpVariant,
  Message,
  refetch,
}: {
  controlMode: GqlNumber;
  operation: GqlNumber;
  autoDeaeration: GqlNumber;
  pulseMemory: GqlNumber;
  pulseDosingVolume: GqlNumber;
  pumpVariant: GqlString;
  manualDosingFlowUnit: GqlString;
  manualDosingVolume: GqlString;
  slowMode: GqlNumber;
  flowControl: GqlNumber;
  delay: GqlNumber;
  sensitivity: GqlNumber;
  maxPressureAlarm: GqlNumber;
  maxPressureAlamUnit: GqlString;
  minPressureAsAlarm: GqlNumber;
  autoFlowAdapt: GqlNumber;
  Message?: JSX.Element;
  refetch: () => void;
}) => {
  const { t } = useTranslation();
  const controlModeText = controlMode === 0 ? t(`WP.REMOTE.MANUAL`) : t(`WP.REMOTE.PULSE`);
  const controlModeDefault = getControlModeDefault(controlMode);
  const flowControlOn = flowControl === 1;
  const isArPump = pumpVariant === 'AR';
  const isFCPump = pumpVariant === 'FC';

  useEffect(() => {
    const handle = setInterval(refetch, 10000);
    return () => {
      clearInterval(handle);
    };
  }, [refetch]);

  return (
    <RemoteLayout
      Message={Message}
      Header={
        <RemoteHeader
          title={t('WP.REMOTE.REMOTE')}
          controlMode={
            controlMode !== null && controlMode !== undefined ? controlModeText : t(`WP.REMOTE.CONTROL_MODE_UNKNOWN`)
          }
        />
      }
      OperationModeSection={
        <RemoteSection
          css={css`
            padding-bottom: 2rem;
          `}
          title={t('WP.REMOTE.OPERATION_MODE')}
        >
          <ReadMode isHidden={false} value={operation} noValueLabel={t('WP.REMOTE.DOSING_PUMP')} hideDivider>
            <InfoItem
              noBorder
              label={t('WP.REMOTE.DOSING_PUMP')}
              inActive
              value={t(`WP.REMOTE.${operation === 1 ? SupportedGeniValueName.START : SupportedGeniValueName.STOP}`)}
            />
          </ReadMode>
        </RemoteSection>
      }
      ControlModeSection={
        <RemoteSection title={t('WP.REMOTE.CONTROL_MODE_SETTINGS')}>
          <ReadMode isHidden={false} value={controlMode} noValueLabel={t('WP.REMOTE.CONTROL_MODE')}>
            <InfoItem
              noBorder
              label={t('WP.REMOTE.CONTROL_MODE')}
              inActive
              value={t(`WP.REMOTE.${getControlModeLabel(controlMode)}`)}
            />
          </ReadMode>
          <div>
            {controlModeDefault === SupportedGeniValueName.PULSE && (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <ReadMode isHidden={false} value={pulseDosingVolume} noValueLabel={t('WP.REMOTE.PULSE_DOSING_VOL')}>
                  <InfoItem noBorder label={t('WP.REMOTE.PULSE_DOSING_VOL')} inActive value={pulseDosingVolume} />
                </ReadMode>
                <ReadMode isHidden={false} value={pulseMemory} noValueLabel={t('WP.REMOTE.PULSE_MEMORY')} hideDivider>
                  <Toggler
                    label={t('WP.REMOTE.PULSE_MEMORY')}
                    isDisabled
                    isToggled={pulseMemory === 1}
                    id="pulseMemory"
                  />
                </ReadMode>
              </div>
            )}
            {controlModeDefault === SupportedGeniValueName.MANUAL && (
              <ReadMode
                isHidden={false}
                value={manualDosingVolume}
                noValueLabel={t(
                  `${t('WP.REMOTE.MANUAL_DOSING_FLOW')} ${manualDosingFlowUnit && `(${manualDosingFlowUnit})`}`,
                )}
                hideDivider
              >
                <InfoItem
                  noBorder
                  label={`${t('WP.REMOTE.MANUAL_DOSING_FLOW')} ${manualDosingFlowUnit && `(${manualDosingFlowUnit})`}`}
                  inActive
                  value={manualDosingVolume}
                />
              </ReadMode>
            )}
          </div>
        </RemoteSection>
      }
      FlowControlSection={
        <RemoteSection title={t('WP.REMOTE.FLOW_CONTROL_TITLE')}>
          <ReadMode isHidden={false} value={autoDeaeration} noValueLabel={t('WP.REMOTE.AUTO_DEAERATION')}>
            <Toggler
              label={t('WP.REMOTE.AUTO_DEAERATION')}
              id="autoDeaeration"
              isToggled={autoDeaeration === 1}
              isDisabled
            />
          </ReadMode>
          <ReadMode isHidden={false} value={slowMode} noValueLabel={t('WP.REMOTE.SLOW_MODE')} hideDivider={isArPump}>
            <InfoItem
              noBorder
              label={t('WP.REMOTE.SLOW_MODE')}
              inActive
              value={t(`WP.REMOTE.SLOW_MODEL_OPTION_${`${getSlowModeValue(slowMode || undefined)}`}`)}
            />
          </ReadMode>
          <ReadMode isHidden={isArPump} value={flowControl} noValueLabel={t('WP.REMOTE.FLOW_CONTROL_ON_OFF')}>
            <Toggler
              label={<div>{t('WP.REMOTE.FLOW_CONTROL_ON_OFF')}</div>}
              id="flowControl"
              isDisabled
              isToggled={flowControlOn}
            />
          </ReadMode>
          <ReadModeFlowControl
            label={t('WP.REMOTE.FLOW_CONTROL_DELAY_LABEL')}
            showActive={flowControlOn}
            value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            isHidden={isArPump}
          >
            <InfoItem
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_DELAY_LABEL')}</Indent>}
              inActive
              value={t(`WP.REMOTE.FLOW_DELAY_OPTION_${getLabelFromValue(delay, delayOptions)}`)}
            />
          </ReadModeFlowControl>
          <ReadModeFlowControl
            label={t('WP.REMOTE.FLOW_CONTROL_SENSIVITY_LABEL')}
            showActive={flowControlOn}
            value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            isHidden={isArPump}
          >
            <InfoItem
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_SENSIVITY_LABEL')}</Indent>}
              inActive
              value={t(`WP.REMOTE.REMOTE_FLOW_SENS_OPTION_${getLabelFromValue(sensitivity, sensitivityOptions)}`)}
            />
          </ReadModeFlowControl>
          <ReadModeFlowControl
            label={t('WP.REMOTE.FLOW_CONTROL_AUTO_FLOW_ADAPT_LABEL')}
            showActive={flowControlOn}
            value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            isHidden={isArPump || isFCPump}
          >
            <Toggler
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_AUTO_FLOW_ADAPT_LABEL')}</Indent>}
              id="autoFlowAdapt"
              isToggled={autoFlowAdapt === 1}
              isDisabled
            />
          </ReadModeFlowControl>
          <ReadModeFlowControl
            label={t('WP.REMOTE.FLOW_CONTROL_MAX_PRESSURE_LABEL')}
            showActive={flowControlOn}
            value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            isHidden={isArPump}
          >
            <InfoItem
              noBorder
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_MAX_PRESSURE_LABEL')}</Indent>}
              inActive
              value={maxPressureAlarm}
            />
          </ReadModeFlowControl>
          <ReadModeFlowControl
            label={t('WP.REMOTE.FLOW_CONTROL_MIN_PRESSURE_LABEL')}
            showActive={flowControlOn}
            value={t('WP.REMOTE.FLOW_CONTROL_NOT_ACTIVE')}
            isHidden={isArPump}
            hideDivider
          >
            <Toggler
              label={<Indent>{t('WP.REMOTE.FLOW_CONTROL_MIN_PRESSURE_LABEL')}</Indent>}
              id="minPressureAsAlarm"
              isToggled={minPressureAsAlarm === 1}
              isDisabled
            />
          </ReadModeFlowControl>
        </RemoteSection>
      }
    />
  );
};

export default ReadOnlyRemote;
