import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';

const headerStyles = css`
  display: grid;
  grid-template-areas:
    'nav  '
    'heading';
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  align-items: end;
  ${dsmMediaQuery('medium device')} {
    grid-template-rows: 1fr;
    grid-template-columns: max-content 1fr;
    align-items: center;
  }
`;

const pageHeadingStyles = css`
  grid-area: heading;
  display: grid;
  grid-gap: 0.5rem;
  justify-content: center;
  align-items: center;

  ${dsmMediaQuery('medium device')} {
    grid-template-columns: max-content minmax(auto, max-content);
    grid-row: 1;
    grid-column: 1 / span 2;
  }
`;

const navigationStyles = css`
  grid-area: nav;
  display: grid;
  ${dsmMediaQuery('large device')} {
    grid-template-columns: max-content max-content;
    align-items: center;
    z-index: 100;
  }
`;

const pageTitleStyles = (theme: Theme): SerializedStyles => css`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${theme.colors.secondaryText};
  opacity: 0.9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;

  ${dsmMediaQuery('large device')} {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;
const pageIconStyles = css`
  height: 1.5rem;
  width: auto;
  ${dsmMediaQuery('large device')} {
    height: 1.75rem;
  }
`;

const navigationTextStyles = (theme: Theme): SerializedStyles => css`
  display: none;
  ${dsmMediaQuery('large device')} {
    display: unset;
    font-size: 18px;
    letter-spacing: 0.047rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: ${theme.colors.secondaryText};
    opacity: 0.9;
  }
`;

const navigationIconWrapperStyles = (theme: Theme): SerializedStyles => css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
  width: 2.75rem;
  border-radius: 50%;
  background-color: ${theme.colors.tertiaryBackground};
  img {
    height: 1.5rem;
    width: auto;
  }
  ${dsmMediaQuery('large device')} {
    background-color: unset;
  }
`;

export {
  headerStyles,
  pageHeadingStyles,
  navigationStyles,
  pageTitleStyles,
  pageIconStyles,
  navigationTextStyles,
  navigationIconWrapperStyles,
};
