import React from 'react';
import { useTranslation } from 'react-i18next';
import warningIcon from '../../../../../assets/images/warning-circle.svg';
import { warningMotorTextStyles, warningTileStyles } from './styles';

const TimeToServiceWarning = ({ motorHours }: { motorHours: number }) => {
  const { t } = useTranslation();

  return (
    <div css={warningTileStyles}>
      <img src={warningIcon} alt="warning icon" height="128" width="128" />
      {/* We don't display negative values */}
      <div css={warningMotorTextStyles}>{`${motorHours < 0 && 0} ${t(
        'WP.OVERVIEW.MOTOR_OPERATING_HOURS_LEFT',
      ).toUpperCase()}`}</div>
      <div>{t('WP.OVERVIEW.ACTION_NEEDED')}</div>
    </div>
  );
};

export default TimeToServiceWarning;
