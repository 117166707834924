import { css, SerializedStyles, Theme } from '@emotion/react';
import dsmMediaQuery from '../../utils/media-queries/media-queries';
import { Variant } from './types';

const baseButton = css`
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  height: 2rem;
  min-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  ${dsmMediaQuery('medium device')} {
    min-width: 6rem;
  }
`;

const primaryButtonStyles = (theme: Theme): SerializedStyles => css`
  ${baseButton};
  background: ${theme.colors.button.primary.background};
  color: ${theme.colors.white.primary};
  border: 0;
  &:hover {
    background: ${theme.colors.button.primary.hover};
  }
  &:disabled,
  &[disabled] {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const secondaryButtonStyles = (theme: Theme): SerializedStyles => css`
  ${baseButton};
  background: transparent;
  color: ${theme.colors.white.primary};
  border: 0.063rem solid ${theme.colors.white.primary};
  &:hover {
    background: ${theme.colors.white.primary};
    color: ${theme.colors.primaryBackground};
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background: transparent;
    color: ${theme.colors.white.inactive};
    border: 0.063rem solid ${theme.colors.white.inactive};
  }
`;

const textLinkStyles = (theme: Theme): SerializedStyles => css`
  ${baseButton};
  border: none;
  background: initial;
  color: ${theme.colors.blue.blue4.baseColor};
  &:hover {
    color: ${theme.colors.blue.blue4.hoverColor};
  }
  :focus {
    outline: none;
  }
`;

const getButtonStyles = (theme: Theme, variant: Variant): SerializedStyles | undefined => {
  if (variant === 'primary') return primaryButtonStyles(theme);
  if (variant === 'secondary') return secondaryButtonStyles(theme);
  if (variant === 'text link') return textLinkStyles(theme);
};

const buttonStyles = (theme: Theme, variant: Variant): SerializedStyles => css`
  ${getButtonStyles(theme, variant)}
`;

export { primaryButtonStyles, secondaryButtonStyles, textLinkStyles, buttonStyles };
