import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { toGlobalId } from 'graphql-relay';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useAuthUser from '../../components/Root/useAuthUser';
import { pageStyles } from '../../styles';
import { Button, DropDownSelect, Snackbar } from '../../components';
import regionIcon from '../../assets/images/earth_outline.svg';
import { Tile, TileBody, TileHeader } from '../../components/Tiles';
import unitSystems from './unitSystems';
import type { ProfileUpdateUserSettingsMutation } from './__generated__/ProfileUpdateUserSettingsMutation.graphql';
import type { ProfileQuery } from './__generated__/ProfileQuery.graphql';
import { profileStyles, formStyles, buttonStyles, dropDownStyles, headerStyles } from './styles';
import UserIcon from '../../components/Icons/User';
import { SnackbarProps, UnitSystem } from './types';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const userId = user?.profile.puid;
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    // we only go back if there is history
    if (window.history.state && window.history.state.idx > 0) navigate(-1);
    else navigate('/', { replace: true });
  };

  useEffect(() => {
    if (showSnackbar) {
      const timer = setTimeout(() => setShowSnackbar(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showSnackbar]);

  const SettingsSchema = Yup.object().shape({
    unitSystem: Yup.string()
      .required(t('WP.COMMON.VALIDATION.REQUIRED'))
      .matches(/^(?!SI Unit\b)/i, t('WP.PROFILE.VALIDATION.NOT_SUPPORTED')),
  });

  const data = useLazyLoadQuery<ProfileQuery>(
    graphql`
      query ProfileQuery {
        me {
          id
          firstName
          lastName
          unitSystemName
        }
      }
    `,
    { id: toGlobalId('UserProfile', userId || '') },
  );

  const { me } = data;

  const [commit, isInFlight] = useMutation<ProfileUpdateUserSettingsMutation>(graphql`
    mutation ProfileUpdateUserSettingsMutation($input: UpdateUserSettingsInput) {
      updateUserSettings(input: $input) {
        id
        user {
          unitSystemName
        }
      }
    }
  `);

  return (
    <div css={[pageStyles, profileStyles]}>
      {showSnackbar && (
        <Snackbar
          snackbarType={snackbarProps?.snackbarType}
          message={snackbarProps?.message || ''}
          onClose={() => setShowSnackbar(false)}
        />
      )}

      <Formik
        initialValues={{
          unitSystem: me?.unitSystemName,
        }}
        validationSchema={SettingsSchema}
        onSubmit={(values) => {
          const { unitSystem } = values;
          commit({
            variables: {
              input: {
                unitSystem: unitSystem === 'US Unit' ? UnitSystem.US : UnitSystem.EU,
              },
            },

            onCompleted: (res) => {
              setSnackbarProps({ snackbarType: 'SUCCESS', message: t('WP.PROFILE.UPDATE_SUCCESS') });
              setShowSnackbar(true);
            },
            onError: (err) => {
              setSnackbarProps({ snackbarType: 'WARNING', message: t('WP.PROFILE.UPDATE_ERROR') });
              setShowSnackbar(true);
              // eslint-disable-next-line no-console
              console.error(err);
            },
          });
        }}
      >
        <Form css={formStyles}>
          <div css={headerStyles}>
            <UserIcon />
            <h1>
              <span> {me?.firstName}</span>
              <span> {me?.lastName}</span>
            </h1>
          </div>
          <div
            css={css`
              grid-area: tiles;
            `}
          >
            <Tile
              key="regionalSettings"
              Header={<TileHeader text={t('WP.PROFILE.REGIONAL_SETTINGS')} icon={regionIcon} />}
              Body={
                <TileBody>
                  {/* Language selection not implemented in this PR */}
                  {/* <DropDownSelect name="Language" label="Language" css={dropDownStyles} /> */}
                  <DropDownSelect name="unitSystem" label={t('WP.PROFILE.UNIT_SYSTEM')} css={dropDownStyles}>
                    {/* SI UNIT will only show up if the customer success manager makes a mistake when creating the user */}
                    {me?.unitSystemName === 'SI Unit' && (
                      <option key="SI" disabled value="SI Unit">
                        {t('WP.PROFILE.SI_UNIT')}
                      </option>
                    )}
                    {unitSystems.map(({ val, displayText }) => (
                      <option key={val} value={val}>
                        {t(displayText)}
                      </option>
                    ))}
                  </DropDownSelect>
                </TileBody>
              }
            />
          </div>

          <div css={buttonStyles}>
            <Button type="button" variant="secondary" onClick={handleCancel}>
              {t('WP.COMMON.BACK')}
            </Button>
            <Button type="submit" variant="primary" disabled={isInFlight}>
              {t('WP.COMMON.SAVE')}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Profile;
