import { css, SerializedStyles, Theme } from '@emotion/react';
import { fadedWhite, lightWhite, blue, blueHover, white } from '../styles';
import dsmMediaQuery from '../../../utils/media-queries/media-queries';

const footerContainer = css`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 3;
  padding: 0.75rem 1.75rem;
  border-top: 0.0625rem solid ${fadedWhite};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${dsmMediaQuery('medium device')} {
    grid-row: 2;
  }
`;

const pickerLink = css`
  text-transform: uppercase;
  color: ${lightWhite};
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

const footerPickerLink = (isEnabled: boolean): SerializedStyles => css`
  width: 6rem;
  margin: 0;
  display: inline-flex;
  align-items: center;
  cursor: ${isEnabled ? 'pointer' : 'default'};
`;

const footerButton = css`
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  height: 2rem;
  width: 6rem;
  color: ${white};
  background: ${blue};
  font-size: 0.75rem;
  min-width: unset;
  border: 0;
  transition: all 0.15s ease-in-out;
  &:hover {
    cursor: pointer;
    background: ${blueHover};
  }
`;

export { footerContainer, pickerLink, footerPickerLink, footerButton };
