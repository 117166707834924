/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { css } from '@emotion/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, InfoItem } from '../../../../components';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';
import { contentStyles, note, noteLabel, noteText, summaryStyles } from './styles';
import { SummaryProps } from './types';
import { heading05Styles, paragraphBaseStyles, paragraphBrightStyles } from '../../../../styles';

export const Summary = ({ section, claimId, installationNote }: SummaryProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const selectedLanguage = i18n.language;
  const regionName = new Intl.DisplayNames([selectedLanguage], { type: 'region' });
  const showState = !!section.state;
  return (
    <div css={contentStyles}>
      <h1 css={heading05Styles}>{t('WP.ONBOARDING.CUSTOMER_DETAILS_TITLE')}</h1>
      <br />
      <div css={summaryStyles(showState)}>
        <label htmlFor="installationNote" css={note}>
          <div css={[paragraphBaseStyles, noteLabel]}>{t('WP.ONBOARDING.INSTALLATION_NOTE_LABEL')}</div>
          <div css={[paragraphBrightStyles, noteText]}>{installationNote}</div>
        </label>
        <InfoItem
          css={css`
            grid-area: claim;
            margin-bottom: 2rem;
          `}
          label={t('WP.ONBOARDING.CUSTOMER_CLAIM_CODE_LABEL')}
          noBorder
          value={claimId}
        />
        <InfoItem
          css={css`
            grid-area: name;
          `}
          label={t('WP.ONBOARDING.CUSTOMER_DETAILS_NAME_LABEL')}
          value={section.name}
        />
        <InfoItem
          css={css`
            grid-area: address;
          `}
          label={t('WP.ONBOARDING.CUSTOMER_DETAILS_ADDRESS_LABEL')}
          value={section.address}
        />
        <InfoItem
          css={css`
            grid-area: city;
          `}
          label={t('WP.ONBOARDING.CUSTOMER_DETAILS_CITY_LABEL')}
          value={section.city}
        />
        {showState && (
          <InfoItem
            css={css`
              grid-area: state;
            `}
            label={t('WP.ONBOARDING.CUSTOMER_DETAILS_STATE_LABEL')}
            value={section.state}
          />
        )}
        <InfoItem
          css={css`
            grid-area: postal;
          `}
          label={t('WP.ONBOARDING.CUSTOMER_DETAILS_POSTAL_LABEL')}
          value={section.postalCode}
        />
        <InfoItem
          css={css`
            grid-area: country;
            margin-bottom: 2rem;
          `}
          label={t('WP.ONBOARDING.CUSTOMER_DETAILS_COUNTRY_LABEL')}
          value={regionName.of(section.country || '')}
        />

        <Button
          css={css`
            grid-area: back;
            margin-bottom: 1rem;
            ${dsmMediaQuery('medium device')} {
              justify-self: left;
              margin-bottom: 0rem;
            }
          `}
          type="button"
          variant="secondary"
          onClick={() => navigate('/onboarding/create-or-select-customer', { state: { claimId } })}
        >
          {t('WP.COMMON.BACK')}
        </Button>
        <Button
          css={css`
            grid-area: next;

            ${dsmMediaQuery('medium device')} {
              justify-self: right;
              align-self: end;
            }
          `}
          type="button"
          variant="primary"
          onClick={() => navigate('/onboarding/installation', { state: { claimId } })}
        >
          {t('WP.COMMON.NEXT')}
        </Button>
      </div>
    </div>
  );
};

export default Summary;
