import { css } from '@emotion/react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  bodyFirstLineStyles,
  bodyLastLineStyles,
  bodyStyles,
  bodyTitleStyles,
  buttonStyles,
  deviceImageStyles,
  deviceImgContainerStyles,
  deviceStyles,
  eventSkeletonStyles,
  footerStyles,
  headerStyles,
  iconStyles,
  locationStyles,
  phoneStyles,
  subTitleStyles,
  textStyles,
  titleStyles,
} from './styles';
import deviceImg from '../../../assets/images/device.png';

const EventSkeletonLoader: React.FC = () => (
  <div css={eventSkeletonStyles}>
    <div css={headerStyles}>
      <div css={iconStyles}>
        <Skeleton circle height="100%" />
      </div>
      <div css={titleStyles}>
        <Skeleton />
      </div>
      <div css={deviceStyles}>
        <Skeleton />
      </div>
      <div css={locationStyles}>
        <Skeleton />
      </div>
      <div css={subTitleStyles}>
        <Skeleton />
      </div>
    </div>
    <div css={bodyStyles}>
      <div css={textStyles}>
        {Array.from(Array(3)).map(() => (
          <div
            css={css`
              padding: 0.8rem 0;
            `}
          >
            <Skeleton height="2rem" css={bodyTitleStyles} />
            <Skeleton css={bodyFirstLineStyles} />
            <Skeleton css={bodyLastLineStyles} />
          </div>
        ))}
      </div>
      <div css={deviceImgContainerStyles}>
        <img src={deviceImg} css={deviceImageStyles} alt="deviceImg" />
      </div>
    </div>
    <div css={footerStyles}>
      <div css={phoneStyles}>
        <Skeleton height="1.8rem" />
      </div>
      <div css={buttonStyles}>
        <Skeleton height="2.8rem" />
      </div>
    </div>
  </div>
);

export default EventSkeletonLoader;
