/* eslint-disable import/prefer-default-export */
export const dateTimeFormatter = (locale: Locale): Highcharts.AxisDateTimeLabelFormatsOptions => ({
  millisecond: '%H:%M:%S.%L',
  second: '%H:%M:%S',
  minute: `${locale.code === 'en-US' ? '%I:%M %p' : '%H:%M'}`,
  hour: `${locale.code === 'en-US' ? '%I:%M %p' : '%H:%M'}`,
  day: `${locale.code === 'en-US' ? '%b %e' : ' %e.%b'}`,
  week: '%e. %b',
  month: "%b '%y",
  year: '%Y',
});

export const convertToSuperScript = (value: string) => {
  if (value.includes('m3')) {
    return value.replaceAll('m3', 'm³');
  }
  return value;
};
