import React, { useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { css } from '@emotion/react';
import { TimeSeriesBarsProps } from './types';
import useDefaultOptions from './useDefaultOptions';

const DosedVolumeGraph = ({ height, data, labels, thresholds }: TimeSeriesBarsProps) => {
  const ref = useRef<HighchartsReact.RefObject>(null);

  const defaultOptions = useDefaultOptions({ labels, thresholds });

  return (
    <div
      // When using grid, we also need to make the direct parent to highcharts be a grid. Otherwise we can resizing issues
      // https://github.com/highcharts/highcharts/issues/9491
      css={css`
        display: grid;
        .highcharts-container,
        .highcharts-container svg {
          display: grid;
        }
      `}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...defaultOptions,
          chart: {
            ...defaultOptions.chart,
            height,
          },
          xAxis: {
            type: 'datetime',
            ...defaultOptions.xAxis,
          },
          series: {
            data: data.map(({ endTime, value }) => ({
              x: endTime,
              y: value,
            })),
          },
        }}
        constructorType="chart"
        ref={ref}
      />
    </div>
  );
};

export default DosedVolumeGraph;
